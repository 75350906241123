import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Button, JoinPlatform } from '../_shared';
import ExternalHighlights from '../externalHighlights/ExternalHighlights';

import { CommunityPlatform, FAQOverview, ResearchInnovationPlatform, Steps } from './_components';

import './howItWorks.scss';

const HowItWorks: FC = () => {
  const { t } = useTranslation();

  return (
    <main>
      <Helmet>
        <title>{t('SHARED.NAVIGATION.HOW_IT_WORKS')}</title>
      </Helmet>
      <div className="ecl-container how_it_works__back">
        <Button onClick={() => history.back()}>{t('SHARED.BUTTONS.BACK')}</Button>
      </div>
      <Steps />
      <CommunityPlatform />
      <ResearchInnovationPlatform />
      <FAQOverview />
      <JoinPlatform />
      <ExternalHighlights />
    </main>
  );
};
export default HowItWorks;
