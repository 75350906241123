import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Inbox } from 'talkjs/all';

import { HttpClient, TApiError } from '../../_http';

type TParams = { conversationId: string };

function deleteConversation({ conversationId }: TParams): Promise<void> {
  return HttpClient.delete<void>(`conversations/${conversationId}`);
}

export function useDeleteConversation(chatInbox: Inbox) {
  const { t } = useTranslation();
  return useMutation<void, TApiError, TParams>('deleteConversation', deleteConversation, {
    onSuccess: () => {
      // Reset selected state for inbox
      chatInbox.select(undefined);
      toast.info(t('COMMUNITY.CONVERSATIONS.TOASTS.CONVERSATION_DELETED'));
    },
  });
}
