import { MutableRefObject, useEffect, useRef, useState } from 'react';

import Talk from 'talkjs';
import { Subscription } from 'talkjs/all';

import { useChatSessionContext } from '../_context';

type TResponse = {
  chatInbox: Talk.Inbox;
  currentConversation: Talk.ConversationData;
  iframeContainerRef: MutableRefObject<HTMLDivElement>;
};

export function useChatInbox(): TResponse {
  const iframeContainerRef = useRef<HTMLDivElement>();
  const { chatSession, conversationToSelectOnMount, setConversationToSelectOnMount } = useChatSessionContext();
  const [chatInbox, setChatInbox] = useState<Talk.Inbox>();
  const [currentConversation, setCurrentConversation] = useState<Talk.ConversationData>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const conversationSelectedHandler = (data: Talk.ConversationSelectedEvent) => setCurrentConversation(data.conversation);

  function createChatInbox() {
    // Create the chat inbox
    const options: Talk.InboxOptions = {
      theme: 'ec_events',
    };
    if (conversationToSelectOnMount) options.selected = conversationToSelectOnMount;
    const inbox = chatSession.createInbox(options);

    setSubscriptions(prevSubscriptions => [
      ...prevSubscriptions,
      // Listen on conversation selection changes
      inbox.onConversationSelected(conversationSelectedHandler),
    ]);

    // Mount the chat inbox iframe
    inbox.mount(iframeContainerRef.current);

    setChatInbox(inbox);
    setConversationToSelectOnMount(null);
  }

  function destroyChatInbox() {
    subscriptions.forEach(subscription => subscription.unsubscribe());
    chatInbox?.destroy();
  }

  useEffect(() => {
    if (chatSession && !chatInbox) {
      createChatInbox();
    }
  }, [chatSession, chatInbox]);

  useEffect(() => {
    return () => destroyChatInbox();
  }, []);

  return {
    chatInbox,
    currentConversation,
    iframeContainerRef,
  };
}
