import { FC, useState, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from '../../../_hooks';
import { SearchField } from '../../../_shared';
import { useEventsContext } from '../../_context/EventsContext';
import { useGetSearchevents } from '../../_queries';
import Item from '../item/Item';
import './header.scss';

const Header: FC = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const { setQuery, query, topics } = useEventsContext();
  const { data: searchEvents } = useGetSearchevents(searchValue);
  const [areSearchOptionsVisible, setSearchOptionsVisibility] = useState(false);
  useOnClickOutside(ref, () => setSearchOptionsVisibility(false));

  return (
    <section className="events-header">
      <div className="events-header__topics">
        <h3>{t('EVENTS.FILTER_EVENTS')}</h3>
        <div className="events-header__topics__container">
          {topics.map(topic => (
            <button
              className={classNames('ecl-tag ecl-tag--display', { active: query.topics.includes(topic.title) })}
              key={topic.title}
              onClick={() =>
                setQuery({
                  topics: query.topics.includes(topic.title)
                    ? query.topics.filter(t => t != topic.title)
                    : [...query.topics, topic.title],
                })
              }
            >
              {topic.title}
            </button>
          ))}
        </div>
      </div>
      <div className="events-header__search">
        <SearchField
          handleFocus={() => setSearchOptionsVisibility(true)}
          label={t('EVENTS.SEARCH_EVENTS')}
          onSearch={setSearchValue}
        />
        {areSearchOptionsVisible && (
          <div className="events-header__search__options" ref={ref}>
            {(searchEvents || []).map(searchEvent => (
              <Item event={searchEvent} key={searchEvent?.id} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
export default Header;
