import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ActionButton, Button, ChannelRoom, Icon } from '../../../_shared';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import {
  BASE_TIME_STRING_FORMAT,
  DEFAULT_MONTH_STRING_FORMAT,
  formatISOString,
  TIMEZONE_FORMAT,
} from '../../../_utils/dateHelpers';
import AuthModal from '../../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../../auth/_context';
import { DetailModal } from '../../../sessions/_components';
import { SessionType, TLiveSession, TSession } from '../../../sessions/_models';
import { useAddToFavorites, useDeleteFromFavorites } from '../../../sessions/_queries';
import './session.scss';

type TProps = {
  session: TSession;
};

const Session: FC<TProps> = ({ session }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();
  const { mutate: addToFavorites } = useAddToFavorites();
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites();

  function handleFavorites(): void {
    if (!isLoggedIn) ModalOpener.instance.open({ render: () => <AuthModal /> });
    else if (session.isFavorite) deleteFromFavorites({ sessionId: session.id });
    else addToFavorites({ sessionId: session.id });
  }

  return (
    <div className="session" id={session.title}>
      <h4 className="session__title">{session.title}</h4>
      <div className="session__date">
        <Icon className="icon" name="calendar" />
        <span>{formatISOString(session.startTime, DEFAULT_MONTH_STRING_FORMAT)}</span>
      </div>
      <div className="session__time">
        <Icon className="icon" name="SvgClock" />
        <span>{formatISOString(session.startTime, BASE_TIME_STRING_FORMAT)}</span>
        <span className="session__time__divider">-</span>
        <span>{formatISOString(session.endTime, BASE_TIME_STRING_FORMAT)}</span>
        <span>{formatISOString(session.startTime, TIMEZONE_FORMAT)}</span>
        {session?.type === SessionType.Live && <ChannelRoom session={session as TLiveSession} />}
      </div>
      <div className="session__actions">
        <Button
          icon={session.isFavorite ? 'SvgFullHeart' : 'SvgEmptyHeart'}
          iconSize={1}
          onClick={handleFavorites}
          theme="secondary"
        >
          {t(session.isFavorite ? 'SHARED.BUTTONS.REMOVE_FROM_FAVOURITES' : 'SHARED.BUTTONS.ADD_TO_FAVOURITES')}
        </Button>
        <ActionButton session={session} />
        <Button onClick={() => ModalOpener.instance.open({ render: () => <DetailModal session={session} /> })} theme="ghost">
          {t('EVENT.PROGRAMME.SHOW_DETAILS')}
          <Icon name="corner-arrow" size={1} transformation="flip-vertical" />
        </Button>
      </div>
    </div>
  );
};
export default Session;
