import { useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../_http';

function removeToFavorite(id: string): Promise<void> {
  return HttpClient.delete(`community/posts/${id}/favorite`);
}

export function useRemoveToFavorite() {
  const queryClient = useQueryClient();

  return useMutation('removeToFavorite', removeToFavorite, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPosts']);
      queryClient.invalidateQueries(['getPost']);
    },
  });
}
