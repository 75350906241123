import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '../../../_shared';
import './numbers.scss';

type TProps = {
  commentCount: number;
  votes: number;
};

const Numbers: FC<TProps> = ({ commentCount, votes }) => {
  const { t } = useTranslation();

  return (
    <ul className="ecl-card__info-container numbers">
      <li className="ecl-card__info-item">
        <Icon name="SvgMessage" size="xs" />
        <span className="ecl-card__info-label">{t('COMMUNITY.POSTS.TOTAL_COMMENTS', { count: commentCount })}</span>
      </li>
      <li className="ecl-card__info-item">
        <Icon name="SvgThumbsUpFill" size="xs" />
        <span className="ecl-card__info-label">{t('COMMUNITY.POSTS.LIKES', { count: votes })}</span>
      </li>
    </ul>
  );
};
export default Numbers;
