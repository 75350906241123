import { FC } from 'react';

import './cardTag.scss';

type TProps = {
  tag: string;
};

const CardTag: FC<TProps> = ({ tag }) => {
  return <span className="ecl-tag ecl-tag--display ecl-description-list__tag">{tag}</span>;
};

export default CardTag;
