import { FC } from 'react';

import classNames from 'classnames';

import { useToggle } from '../../../_hooks';
import { Circle, Icon, Markdown } from '../../../_shared';
import { TFaq } from '../../_models';

import './FAQ.scss';

type TProps = {
  faq: TFaq;
};

const FAQ: FC<TProps> = ({ faq }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  if (!faq) return null;
  return (
    <div className="faq">
      <button
        aria-controls={faq.id}
        aria-expanded={isOpen}
        className={classNames('faq__trigger', isOpen && 'faq__trigger__open')}
        onClick={toggleIsOpen}
      >
        <p>{faq.title}</p>
        <Circle>
          <Icon name="corner-arrow" size={1} transformation={isOpen ? 'flip-vertical' : undefined} />
        </Circle>
      </button>
      {isOpen && faq.content.json && (
        <div aria-hidden={!isOpen} className="faq__markdown" id={faq.id}>
          <Markdown value={faq.content.json} />
        </div>
      )}
    </div>
  );
};
export default FAQ;
