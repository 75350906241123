import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

export type TAccountSettings = {
  canContact: boolean;
  isVisible: boolean;
};

function updateAccountSettings(body: TAccountSettings): Promise<void> {
  return HttpClient.patch<void>('auth/account-settings', body);
}

export function useUpdateAccountSettings() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TAccountSettings>('updateAccountSettings', updateAccountSettings, {
    onSuccess: () => queryClient.invalidateQueries('authenticate'),
  });
}
