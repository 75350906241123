import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { TDocument } from '../../_models';
import Document from '../document/Document';

import './documentation.scss';

type TProps = {
  documents: TDocument[];
};

const Documentation: FC<TProps> = ({ documents }) => {
  const { t } = useTranslation();
  return (
    <section className="hub-detail__section hub-detail__documents">
      <h3>{t('HUBS.DETAIL.DOCUMENTATION')}</h3>
      {documents && documents.length ? (
        <div>
          {documents.map(document => (
            <Document document={document} key={document.id} />
          ))}
        </div>
      ) : (
        <span className="hub-detail__section__empty">{t('HUBS.DETAIL.EMPTY_DOCUMENTATION')}</span>
      )}
    </section>
  );
};

export default Documentation;
