import { FC } from 'react';

import classnames from 'classnames';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getUserName } from '../../../_models';
import { Button, Icon } from '../../../_shared';
import { useAuthContext } from '../../../auth/_context';
import CardLabel, { CardLabelType } from '../../../ec/card/components/CardLabel';
import { NotificationType, TNotification } from '../../_models';
import { useMarkAsRead } from '../../_queries';
import './notification.scss';

type TProps = {
  notification: TNotification;
};

const Notification: FC<TProps> = ({ notification }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile } = useAuthContext();
  const { mutate: markAsRead } = useMarkAsRead();
  const isLiveNotification = [NotificationType.EventInOneDay, NotificationType.EventInOneHour].includes(notification?.type);

  const renderImage = () => {
    if (isLiveNotification) return <Icon name="SvgNotificationLive" size={5} />;
    if (notification.author) {
      if (!notification.author.image) return <Icon name="SvgUser" size={5} />;
      else return <img alt="" src={notification.author.image} />;
    }
    return <Icon name="SvgNotification" size={5} />;
  };

  function handleOnClick() {
    markAsRead({ notificationId: notification.id });

    switch (notification.type) {
      case NotificationType.EventInOneDay:
      case NotificationType.EventInOneHour:
      case NotificationType.InvitedToEvent:
      case NotificationType.ActionFromNetworkAttendingEvent:
      case NotificationType.ActionFromNetworkInterestedEvent:
      case NotificationType.NewEvent:
      case NotificationType.NewSpeaker:
        navigate(`/events/${notification?.data?.eventId}`);
        break;
      case NotificationType.NewMessage:
        navigate('/community/conversations');
        break;
      case NotificationType.SuggestedPosts:
        navigate('/community/posts', { state: { tags: profile.interests } });
        break;
      case NotificationType.AddedToNetwork:
        navigate('/community/network', { state: { allFollowers: true } });
        break;
      case NotificationType.SuggestedUsers:
        navigate('/community/network', { state: { interests: profile.interests } });
        break;
      case NotificationType.CommunityVote:
      case NotificationType.CommunityLike:
        navigate(`/community/posts/${notification?.data?.postId}`);
        break;
    }
  }

  return (
    <div className={classnames('notification-item', { 'is-seen': notification?.seen })}>
      <Button className="notification-item__wrapper" onClick={handleOnClick} theme="wrapper">
        {renderImage()}
        <div className="notification-item__wrapper__content">
          {isLiveNotification && <CardLabel type={CardLabelType.Live} />}
          <p>{t(`NOTIFICATIONS.${notification?.type}`, { connection: getUserName(notification?.author) })}</p>
          <p>{t('NOTIFICATIONS.TIME', { time: formatDistanceToNow(new Date(notification.createdAt)) })}</p>
        </div>
      </Button>

      <div className="notification-item__wrapper__actions">
        {!notification?.seen && <div className="notification-item__indicator" />}
      </div>
    </div>
  );
};

export default Notification;
