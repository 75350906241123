import { FC } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { EventContextProvider } from './_context/EventContext';
import { EventsContextProvider } from './_context/EventsContext';
import Detail from './detail/Detail';
import Overview from './overview/Overview';

const EventsRoutes: FC = () => {
  return (
    <Routes>
      <Route
        element={
          <EventsContextProvider>
            <Overview />
          </EventsContextProvider>
        }
        path="*"
      />
      <Route
        element={
          <EventContextProvider>
            <Detail />
          </EventContextProvider>
        }
        path=":id/*"
      />
      <Route element={<Navigate to="5rBGp9lUhJt2lmxYEk45Qf" />} path="*" />
    </Routes>
  );
};

export default EventsRoutes;
