import { FC, useEffect, useState } from 'react';

import { parse } from 'query-string';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button, Checkbox, FilterBar, SearchField, Spinner, Title } from '../../_shared';
import { useEventContext } from '../../events/_context/EventContext';
import { Project, TopicCarousel } from '../_components';
import { TProjectQuery } from '../_models';
import { useGetProjects } from '../_queries';

import './overview.scss';

const LIMIT = 8;

const Overview: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { topic } = parse(search);

  const [query, setQuery] = useState<TProjectQuery>({
    favoritesOnly: false,
    search: '',
    skip: 0,
    take: LIMIT,
    topics: [topic as string],
  });

  const { data: projects, isLoading } = useGetProjects(query);
  const { topics, isLoading: topicsLoading } = useEventContext();
  const currentTopic = topics.find(eventTopic => eventTopic.title === topic);

  function updateQuery(overrides: Partial<TProjectQuery>) {
    setQuery({ ...query, ...overrides });
  }

  function handleTopicsChange(checked: boolean, name: string) {
    updateQuery({ topics: checked ? [...query.topics, name] : query.topics.filter(topic => topic !== name) });
  }

  useEffect(() => {
    updateQuery({ topics: [topic as string] });
  }, [topic]);

  return (
    <main className="projects">
      <Helmet>
        <title>{`${t('EVENT.NAVIGATION.PROJECTS')} ${currentTopic?.title ? '- ' + currentTopic.title : ''}`}</title>
      </Helmet>

      <section className="ecl-container">
        <Title>{t('EVENT.NAVIGATION.PROJECTS')}</Title>
      </section>

      <TopicCarousel activeTopic={currentTopic} href=".." isLoading={topicsLoading} topics={topics} />

      <div className="ecl-container projects__content">
        <FilterBar clearFilters={() => updateQuery({ favoritesOnly: false, search: '', topics: [] })}>
          <SearchField label={t('EVENT.PROGRAMME.SEARCH_SESSION')} onSearch={search => updateQuery({ search })} />
          {!topic && (
            <>
              <h4>{t('SHARED.TOPICS')}</h4>
              {topics.map(topic => (
                <Checkbox
                  checked={query?.topics.includes(topic.title)}
                  key={topic.title}
                  name={topic.title}
                  onChange={handleTopicsChange}
                >
                  {topic.title}
                </Checkbox>
              ))}
            </>
          )}
          <h4>{t('SHARED.FAVOURITES')}</h4>
          <Checkbox
            checked={query.favoritesOnly}
            name="favoritesOnly"
            onChange={favoritesOnly => updateQuery({ favoritesOnly })}
            type="toggle"
          >
            {t('EVENT.PROGRAMME.ONLY_SHOW_FAVOURITES')}
          </Checkbox>
        </FilterBar>
        <section className="projects__content__items-wrapper">
          {isLoading ? (
            <Spinner />
          ) : projects?.data?.length ? (
            <>
              <div className="projects__content__items__total-count">
                <span className="projects__content__item__metadata">
                  {t('PROJECTS.OVERVIEW.RESULTS', { count: projects?.meta?.count })}
                </span>
              </div>
              <div className="projects__content__items">
                {(projects.data || []).map(project => (
                  <Project key={project.id} project={project} />
                ))}
              </div>
              <div className="projects__content__items__load-more">
                <span className="projects__content__item__metadata">
                  {t('PROJECTS.OVERVIEW.COUNT_OF_TOTAL', {
                    count: projects?.meta?.count,
                    totalCount: projects?.meta?.totalCount,
                  })}
                </span>
                {
                  <Button
                    disabled={projects.meta.count === projects.meta.totalCount}
                    icon="corner-arrow"
                    iconSize="xs"
                    iconTransformation="rotate-180"
                    onClick={() => updateQuery({ take: projects.meta.count + LIMIT })}
                    theme="ghost"
                  >
                    {t('PROJECTS.OVERVIEW.LOAD_MORE')}
                  </Button>
                }
              </div>
            </>
          ) : (
            <span className="projects__content__empty">{t('PROJECTS.OVERVIEW.EMPTY')}</span>
          )}
        </section>
      </div>
    </main>
  );
};

export default Overview;
