import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { ConversationData, Inbox } from 'talkjs/all';

import { Button } from '../../_shared';
import { useAuthContext } from '../../auth/_context';
import { useInviteUsersToVideoMeeting } from '../_queries';

import ConversationActionsDropdown from './ConversationActionsDropdown';

import './conversationActions.scss';

type TProps = {
  chatInbox: Inbox;
  conversation: ConversationData & {
    custom: {
      leftUserIds?: string; // JSON stringified
    };
  };
};

const ConversationActions: FC<TProps> = ({ conversation, chatInbox }) => {
  const { profile } = useAuthContext();
  const { mutate: inviteUsersToVideoMeeting } = useInviteUsersToVideoMeeting();
  const { t } = useTranslation();
  const hasLeft = conversation?.custom?.leftUserIds?.includes(profile.chatId);

  // The conversation will be null on mobile if the user is viewing the conversations list
  // This component should not be displayed in that case
  if (!conversation) return null;

  return (
    <>
      <header className="conversation-actions">
        <p className="conversation-actions__text">
          {hasLeft ? t('COMMUNITY.CONVERSATIONS.ACTIONS.EMPTY') : conversation?.subject}
        </p>
        {!hasLeft && (
          <div className="conversation-actions__buttons">
            <Button
              className="conversation-actions__buttons__video-call"
              icon="video"
              iconSize="xs"
              onClick={() => inviteUsersToVideoMeeting({ conversationId: conversation.id })}
            >
              {t('COMMUNITY.CONVERSATIONS.ACTIONS.START_VIDEOCALL.BUTTON')}
            </Button>
            <ConversationActionsDropdown chatInbox={chatInbox} conversationId={conversation.id} />
          </div>
        )}
      </header>
      <div className="conversation-actions-border" />
    </>
  );
};

export default ConversationActions;
