import { FC } from 'react';

import { useId } from '@mantine/hooks';
import classnames from 'classnames';

import { useInputError } from '../../../_hooks';
import InputWrapper, { TInputWrapperProps } from '../InputWrapper';

import './checkbox.scss';

export type InputCheckboxProps = TInputWrapperProps & {
  checked?: boolean;
  onChange: (checked: boolean, name: string, value?: string) => void;
  type?: 'checkbox' | 'radio' | 'toggle';
  value?: string;
};

const Checkbox: FC<InputCheckboxProps> = ({
  children,
  checked,
  onChange,
  type = 'checkbox',
  className,
  value,
  ...wrapperProps
}) => {
  const { disabled, validation, name } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);
  const uuid = useId(wrapperProps.id);

  return (
    <InputWrapper
      className={classnames('checkbox-wrapper', className, { 'checkbox-toggle': type === 'toggle' })}
      id={uuid}
      {...wrapperProps}
      showError={showError}
    >
      <label className={classnames({ 'is-error': showError })}>
        <input
          checked={checked}
          disabled={disabled}
          id={uuid}
          name={wrapperProps.name}
          onChange={event => {
            onChange(event.currentTarget.checked, name, value);
            setDirty();
          }}
          type={type === 'toggle' ? 'checkbox' : type}
        />
        <span>{children}</span>
      </label>
    </InputWrapper>
  );
};

export default Checkbox;
