import { TUser } from '../_models';
import { TIconType } from '../_shared/icon/Icon';

export const getSocials = (user: TUser): { icon: TIconType; name: string; url: string }[] => {
  const socials = [];
  if (user?.twitter) socials.push({ icon: 'SvgSocialTwitter', name: 'Twitter', url: user.twitter });
  if (user?.facebook) socials.push({ icon: 'SvgSocialFacebook', name: 'Facebook', url: user.facebook });
  if (user?.linkedin) socials.push({ icon: 'SvgSocialLinkedin', name: 'LinkedIn', url: user.linkedin });
  return socials;
};
