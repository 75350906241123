import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { getUserName, TUser } from '../../../_models';
import { Button } from '../../../_shared';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import AuthModal from '../../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../../auth/_context';
import CreateConversationModal from '../createConversationModal/CreateConversationModal';

import './createGroupchat.scss';

type TProps = {
  selectedUsers: TUser[];
  setSelectedUsers: (users: TUser[]) => void;
};

const CreateGroupchat: FC<TProps> = ({ selectedUsers, setSelectedUsers }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();

  return (
    <div className="create-groupchat">
      <div className="create-groupchat__selected-users">
        <div>
          <p>{t('COMMUNITY.NETWORK.SELECTED_PARTICIPANTS')}</p>
          {selectedUsers.map(selectedUser => (
            <Button
              icon="SvgUserClose"
              iconSize={1}
              key={selectedUser.id}
              onClick={() => setSelectedUsers(selectedUsers.filter(user => user !== selectedUser))}
            >
              {getUserName(selectedUser)}
            </Button>
          ))}
        </div>

        <Button
          className="send-message"
          disabled={selectedUsers.length === 0}
          icon="SvgMessage"
          iconSize={1}
          onClick={() =>
            ModalOpener.instance.open({
              render: () =>
                isLoggedIn ? <CreateConversationModal setUsers={setSelectedUsers} users={selectedUsers} /> : <AuthModal />,
            })
          }
        >
          {t('COMMUNITY.NETWORK.SEND_MESSAGE')}
        </Button>
      </div>
    </div>
  );
};

export default CreateGroupchat;
