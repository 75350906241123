const HTTP = 'http';
const HASH = '#';
const MAILTO = 'mailto:';
const TEL = 'tel://';
const DOCUMENT = '/static';

export const shouldOpenInNewTab = (link: string) => {
  return link.startsWith(HTTP) || link.startsWith(DOCUMENT);
};

export const isPlatformLink = (link: string) => {
  return link.startsWith(HASH) || link.startsWith(MAILTO) || link.startsWith(TEL);
};
