import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Spinner } from '../../../_shared';
import { useGetRelatedProjects } from '../../_queries';
import Project from '../project/Project';

import './relatedProjects.scss';

type TProps = {
  pathPrefix?: string;
  similarProjects?: boolean;
  topics: string[];
};

const RelatedProjects: FC<TProps> = ({ topics = [], similarProjects, pathPrefix = '' }) => {
  const { t } = useTranslation();
  const { data: projects, isLoading } = useGetRelatedProjects(topics);
  const path = `${pathPrefix}../projects/`;

  return (
    <section className="related-projects">
      <h4>{similarProjects ? t('PROJECTS.SIMILAR.TITLE') : t('PROJECTS.RELATED.TITLE')}</h4>
      {isLoading ? (
        <Spinner />
      ) : projects && projects.length ? (
        projects.slice(0, 2).map(project => <Project key={project?.id} pathPrefix={path} project={project} />)
      ) : (
        <span className="related-projects__empty">
          {similarProjects ? t('PROJECTS.SIMILAR.EMPTY') : t('PROJECTS.RELATED.EMPTY')}
        </span>
      )}
      {projects && projects.length > 2 && (
        <Button className="related-projects__button" href={path} theme="ghost">
          {similarProjects ? t('PROJECTS.SIMILAR.GO_TO_SIMULAR') : t('PROJECTS.RELATED.GO_TO_RELATED')}
        </Button>
      )}
    </section>
  );
};

export default RelatedProjects;
