import { FC } from 'react';

import { Document } from '@contentful/rich-text-types';
import { useTranslation } from 'react-i18next';

import CardTag from '../../ec/card/components/CardTag';
import Markdown from '../markdown/Markdown';

import './about.scss';

type TProps = {
  summary: {
    json: Document;
  };
  title: string;
  topics: string[];
};

const About: FC<TProps> = ({ title, topics, summary }) => {
  const { t } = useTranslation();

  return (
    <section className="about">
      <h3>{title}</h3>
      <Markdown value={summary?.json} />
      <h5>{t('SHARED.TOPICS')}</h5>
      <div className="about__topics">
        {(topics || []).map(topic => (
          <CardTag key={topic} tag={topic} />
        ))}
      </div>
    </section>
  );
};
export default About;
