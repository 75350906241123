import { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Spinner } from '../../../_shared';

import './list.scss';

type TProps = {
  data: unknown[];
  loading: boolean;
  onShowAll?: () => void;
  renderContent: (item: unknown) => ReactNode;
  title: string;
};

const List: FC<TProps> = ({ loading, title, data = [], renderContent, onShowAll }) => {
  const { t } = useTranslation();

  return (
    <section className="profile-list">
      <div className="profile-list__header">
        <h1 className="profile-list__header__title">{title}</h1>
        {onShowAll && (
          <Button
            className="profile-list__header__button"
            icon="SvgChevron"
            iconSize="s"
            iconTransformation="rotate-90"
            onClick={onShowAll}
            theme="ghost"
          >
            {t('PROFILE.SHOW_ALL')}
          </Button>
        )}
      </div>
      <div className="profile-list__content">
        {loading ? (
          <Spinner />
        ) : data?.length > 0 ? (
          data.map(renderContent)
        ) : (
          <span className="profile-list__content__empty">{t('PROFILE.EMPTY_LIST')}</span>
        )}
      </div>
    </section>
  );
};

export default List;
