import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { projectId: string };

function deleteFromFavorites({ projectId }: TParams): Promise<void> {
  return HttpClient.delete<void>(`projects/favorites/${projectId}`);
}

export function useDeleteFromFavorites() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TParams>('deleteFromFavorites', deleteFromFavorites, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('projects');
      queryClient.invalidateQueries([`project`, variables.projectId]);
    },
  });
}
