import { FC } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Documentation, ExternalResources, Markdown, Picture, Speaker, Spinner, Title } from '../../_shared';
import { ModalOpener } from '../../_shared/modal/ModalOpener';
import { formatISOString } from '../../_utils/dateHelpers';
import AuthModal from '../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../auth/_context';
import CardTag from '../../ec/card/components/CardTag';
import Gallery from '../../ec/gallery/Gallery';
import { RelatedHubs } from '../../hubs/_components';
import { RelatedProjects } from '../_components';
import { useAddToFavorites, useDeleteFromFavorites, useGetProject } from '../_queries';
import './detail.scss';

const Detail: FC = () => {
  const { t } = useTranslation();
  const { data: project, isLoading } = useGetProject();
  const { isLoggedIn } = useAuthContext();
  const { mutate: addToFavorites } = useAddToFavorites();
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites();

  function handleFavorites() {
    if (!isLoggedIn) ModalOpener.instance.open({ render: () => <AuthModal /> });
    else if (project.isFavorite) deleteFromFavorites({ projectId: project.id });
    else addToFavorites({ projectId: project.id });
  }

  if (isLoading) return <Spinner />;

  return (
    <div className="project-detail ecl-container">
      <Button
        className="back-navigation"
        href=".."
        icon="corner-arrow"
        iconPosition="before"
        iconSize="xs"
        iconTransformation="rotate-270"
        theme="ghost"
      >
        {t('SHARED.BUTTONS.BACK')}
      </Button>

      <div className="project-detail__title">
        <Title>{project.title}</Title>
        <Button
          className="project-detail__title__button"
          icon={project.isFavorite ? 'SvgFullHeart' : 'SvgEmptyHeart'}
          onClick={handleFavorites}
          theme="secondary"
        >
          {t(project.isFavorite ? 'SHARED.BUTTONS.REMOVE_FROM_FAVOURITES' : 'SHARED.BUTTONS.ADD_TO_FAVOURITES')}
        </Button>
      </div>

      <section className="project-detail__about">
        <Markdown value={project.summary.json} />
        <Picture label={project.title} url={project.thumbnail} />
      </section>

      <div className="detail-content project-detail__content">
        <main>
          {project?.gallery.length > 0 && (
            <section className="project-detail__content__gallery">
              <h3>{t('PROJECTS.DETAIL.PROJECT_GALLERY')}</h3>
              <Gallery gallery={project?.gallery} />
            </section>
          )}

          {project?.collaborators?.length > 0 && (
            <section
              className={classnames('project-detail__content__collaborators', {
                'show-top-border': project.gallery.length === 0,
              })}
            >
              <h3>{t('PROJECTS.DETAIL.PROJECT_TEAM')}</h3>
              <div className="project-detail__content__collaborators__wrapper">
                {project.collaborators.map(collaborator => (
                  <Speaker key={collaborator.id} speaker={collaborator} />
                ))}
              </div>
            </section>
          )}

          <section className="project-detail__content__details">
            <h3>{t('PROJECTS.DETAIL.NAVIGATION_TITLE')}</h3>
            <div className="project-detail__content__details__wrapper">
              <dl>
                <dt>{t('PROJECTS.DETAIL.ACRONYM')}</dt>
                <dd>{project.details.acronym}</dd>
              </dl>
              <dl>
                <dt>{t('PROJECTS.DETAIL.COORDINATOR')}</dt>
                <dd>{project.details.coordinator}</dd>
              </dl>
              <dl>
                <dt>{t('PROJECTS.DETAIL.TOTAL_COST')}</dt>
                <dd>&euro; {project.details.cost}</dd>
              </dl>
              <dl>
                <dt>{t('PROJECTS.DETAIL.DURATION')}</dt>
                <dd>
                  {`${formatISOString(project.details.startDate, 'LLLL yyyy')} - ${formatISOString(
                    project.details.endDate,
                    'LLLL yyyy',
                  )}`}
                </dd>
              </dl>
              <dl>
                <dt>{t('PROJECTS.DETAIL.NUMBER')}</dt>
                <dd>{project.details.number}</dd>
              </dl>
              <dl>
                <dt>{t('PROJECTS.DETAIL.PARTICIPANTS')}</dt>
                <dd>{project.details.participants}</dd>
              </dl>
              <dl>
                <dt>{t('PROJECTS.DETAIL.EU_CONTRIBUTION')}</dt>
                <dd>&euro; {project.details.euContribution}</dd>
              </dl>
            </div>
            <h4>{t('SHARED.TOPICS')}</h4>
            <div className="project-detail__content__details__topics">
              {(project.topics || []).map(topic => (
                <CardTag key={topic} tag={topic} />
              ))}
            </div>
          </section>

          <Documentation documents={project.documents} />
          <ExternalResources links={project.externalLinks} />
        </main>
        <aside>
          <RelatedHubs pathPrefix="../" topics={project.topics} />
          <RelatedProjects pathPrefix="../" similarProjects topics={project.topics} />
        </aside>
      </div>
    </div>
  );
};

export default Detail;
