import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TEvent, TEventsQuery, TEventsResponse } from '../_models';

function getEvents(query: TEventsQuery): Promise<TEventsResponse> {
  return HttpClient.get<TEventsResponse>(`events?${stringify(query)}`);
}

export function useGetEvents(query: TEventsQuery) {
  return useQuery<TEventsResponse, TApiError>(['events', query], () => getEvents(query));
}

export function useGetSearchevents(search: string) {
  return useQuery<TEventsResponse, TApiError, TEvent[]>(
    ['searchEvents', search],
    () => getEvents({ search, topics: undefined }),
    {
      select: data => [...data.currentEvents, ...data.pastEvents, ...data.upcomingEvents],
    },
  );
}
