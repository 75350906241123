import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Sort, TUser } from '../_models';
import { Checkbox, FilterBar, Person, SearchField, Spinner, Title, UserSwiper } from '../_shared';
import { useAuthContext } from '../auth/_context';
import { CreateGroupchat } from '../conversations/_components';
import { useEventContext } from '../events/_context/EventContext';

import { TParticipantsQuery } from './_models';
import { useGetParticipants } from './_queries';

import './participants.scss';

const initialQuery: TParticipantsQuery = {
  allFollowers: false,
  allIFollow: false,
  search: '',
  sort: Sort.Alphabetical,
  topics: [],
};

const Participants: FC = () => {
  const { t } = useTranslation();
  const { topics } = useEventContext();
  const { isLoggedIn } = useAuthContext();
  const { state } = useLocation();

  const [query, setQuery] = useState<TParticipantsQuery>({ ...initialQuery, allIFollow: state?.allIFollow ?? false });
  const { data: participants, isLoading } = useGetParticipants(query);
  const [selectedPeople, setSelectedPeople] = useState<TUser[]>([]);

  function selectPerson(person: TUser): void {
    if (selectedPeople.includes(person)) setSelectedPeople(selectedPeople.filter(p => p !== person));
    else setSelectedPeople([...selectedPeople, person]);
  }

  const updateQuery = (overrides: Partial<TParticipantsQuery>) => {
    setQuery({ ...query, ...overrides });
  };

  const handleTopicsChange = (checked: boolean, name: string) => {
    updateQuery({ topics: checked ? [...query.topics, name] : query.topics.filter(topic => topic !== name) });
  };

  return (
    <main className="participants ecl-container">
      <Title>{t('EVENT.NAVIGATION.PARTICIPANTS')}</Title>

      <UserSwiper
        emptyLabel={t('PARTICIPANTS.NO_PARTICIPANTS')}
        isLoading={isLoading}
        renderSlides={(participants || []).map(participant => (
          <Person key={participant.id} user={participant} />
        ))}
        title={t('EVENT.OVERVIEW.PARTICIPANTS_PICK')}
      />

      <section className="participants__content">
        <FilterBar clearFilters={() => updateQuery(initialQuery)}>
          <SearchField label={t('PARTICIPANTS.SEARCH_PARTICIPANT')} onSearch={search => updateQuery({ search })} />

          <h4>{t('SHARED.TOPICS')}</h4>
          {topics.map(topic => (
            <Checkbox
              checked={query.topics.includes(topic.title)}
              key={topic.title}
              name={topic.title}
              onChange={handleTopicsChange}
            >
              {topic.title}
            </Checkbox>
          ))}

          <h4>{t('SHARED.FAVOURITES')}</h4>
          <Checkbox
            checked={query.allFollowers}
            disabled={!isLoggedIn}
            name="allFollowers"
            onChange={allFollowers => updateQuery({ allFollowers, allIFollow: false })}
            type="toggle"
          >
            {t('PARTICIPANTS.ALL_FOLLOWERS')}
          </Checkbox>
          <Checkbox
            checked={query.allIFollow}
            disabled={!isLoggedIn}
            name="allIFollow"
            onChange={allIFollow => updateQuery({ allFollowers: false, allIFollow })}
            type="toggle"
          >
            {t('PARTICIPANTS.ALL_I_FOLLOW')}
          </Checkbox>
        </FilterBar>

        <div className="speakers__content__container">
          <CreateGroupchat selectedUsers={selectedPeople} setSelectedUsers={setSelectedPeople} />
          <div className="participants__content__container__grid">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {!participants?.length && <p>{t('PARTICIPANTS.NO_PARTICIPANTS')}</p>}
                {(participants || []).map(user => (
                  <Person
                    isSelected={selectedPeople.includes(user)}
                    key={user.id}
                    onSelectChange={() => selectPerson(user)}
                    user={user}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
export default Participants;
