import { FC, ReactNode } from 'react';

import './circle.scss';

type TProps = {
  children: string | ReactNode;
};

const Circle: FC<TProps> = ({ children }) => <span className="circle">{children}</span>;
export default Circle;
