import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import hubThumbnail from '../../../_assets/images/hub-detail-thumbnail.png';
import { useInterval, useToggle } from '../../../_hooks';
import { TImage } from '../../../_models';
import { Button } from '../../../_shared';
import { TAnnouncement } from '../../_models';
import './banner.scss';

type TProps = {
  announcements: TAnnouncement[];
  image: TImage;
  title: string;
};

const Banner: FC<TProps> = ({ title, image, announcements }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, toggleAutoPlay] = useToggle((announcements?.length || 0) > 1);

  // Autoplay
  const { start, stop } = useInterval(() => {
    setCurrentIndex(value => (value + 1 === announcements.length ? 0 : value + 1));
  }, 10000);

  useEffect(() => {
    if (isAutoPlaying) {
      start();
    } else {
      stop();
    }
  }, [isAutoPlaying]);

  return (
    <div className="hub__banner">
      <div className="ecl-container">
        <h2>{title}</h2>
        {!!announcements?.length && (
          <div className="hub__banner__announcement">
            <div className="hub__banner__announcement__content">
              <p>{announcements?.[currentIndex]?.description}</p>
              {announcements?.[currentIndex]?.link?.url && (
                <Button href={announcements[currentIndex].link.url} icon="external" iconSize="xs" theme="ghost">
                  {t('SHARED.BUTTONS.GO_TO_WEBSITE')}
                </Button>
              )}
            </div>
            <div className="hub__banner__announcement__controls">
              <span>{t('SHARED.COUNT_OF_TOTAL', { count: currentIndex + 1, totalCount: announcements?.length })}</span>
              <Button
                hideLabel
                icon="corner-arrow"
                iconSize="s"
                iconTransformation="rotate-270"
                onClick={() => {
                  toggleAutoPlay(false);
                  setCurrentIndex(value => (value === 0 ? announcements.length - 1 : value - 1));
                }}
              >
                {t('SHARED.CAROUSEL.PREVIOUS')}
              </Button>
              <Button hideLabel icon={isAutoPlaying ? 'pause' : 'play'} iconSize="s" onClick={toggleAutoPlay}>
                {t('SHARED.CAROUSEL.AUTO_PLAY')}
              </Button>
              <Button
                hideLabel
                icon="corner-arrow"
                iconSize="s"
                iconTransformation="rotate-90"
                onClick={() => {
                  toggleAutoPlay(false);
                  setCurrentIndex(value => (value + 1 === announcements.length ? 0 : value + 1));
                }}
              >
                {t('SHARED.CAROUSEL.NEXT')}
              </Button>
            </div>
          </div>
        )}
      </div>
      <img alt={image?.name || ''} src={image?.url || hubThumbnail} />
    </div>
  );
};

export default Banner;
