import { useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../_http';

function addToFavorite(id: string): Promise<void> {
  return HttpClient.post(`community/posts/${id}/favorite`);
}

export function useAddToFavorite() {
  const queryClient = useQueryClient();

  return useMutation('addToFavorite', addToFavorite, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPosts']);
      queryClient.invalidateQueries(['getPost']);
    },
  });
}
