import { useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../_http';

function addComment(id: string, content: string): Promise<void> {
  return HttpClient.post(`community/posts/${id}/comments`, { content });
}

export function useAddComment(id: string) {
  const queryClient = useQueryClient();

  return useMutation('addComment', (content: string) => addComment(id, content), {
    onSuccess: () => queryClient.invalidateQueries(['getPost']),
  });
}
