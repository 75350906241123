import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import { Button } from './_shared';

import 'react-toastify/dist/ReactToastify.css';

import './toastify.scss';

const CloseButton: FC<{ closeToast?: () => void }> = ({ closeToast }) => {
  const { t } = useTranslation();
  return (
    <Button
      hideLabel
      icon="close"
      onClick={e => {
        e.stopPropagation();
        closeToast?.();
      }}
      theme="ghost"
    >
      {t('SHARED.BUTTONS.CLOSE')}
    </Button>
  );
};

const Toastify: FC = () => {
  return (
    <ToastContainer
      bodyClassName="toast-body"
      className="toast-container"
      closeButton={<CloseButton />}
      closeOnClick={false}
      position="top-right"
      progressClassName="toast-progress"
      toastClassName="toast"
    />
  );
};
export default Toastify;
