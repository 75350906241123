/**
 * Implements *base64url-encode* (RFC 4648 § 5) without padding, which is NOT
 * the same as regular base64 encoding.
 */
const base64urlEncode = (value: string): string => {
  let base64 = btoa(value);
  base64 = base64.replace(/\+/g, '-');
  base64 = base64.replace(/\//g, '_');
  base64 = base64.replace(/=/g, '');
  return base64;
};

export const generatePKCECodes = async (): Promise<Record<string, string>> => {
  const PKCE_CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  const output = new Uint32Array(96);
  crypto.getRandomValues(output);
  const codeVerifier = base64urlEncode(
    Array.from(output)
      .map((num: number) => PKCE_CHARSET[num % PKCE_CHARSET.length])
      .join(''),
  );

  return await crypto.subtle
    .digest('SHA-256', new TextEncoder().encode(codeVerifier))
    .then((buffer: ArrayBuffer) => {
      const hash = new Uint8Array(buffer);
      let binary = '';
      const hashLength = hash.byteLength;
      for (let i = 0; i < hashLength; i++) {
        binary += String.fromCharCode(hash[i]);
      }
      return binary;
    })
    .then(base64urlEncode)
    .then((codeChallenge: string) => ({ codeChallenge, codeVerifier }));
};
