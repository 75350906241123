import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import ResearchInnovationPlatformImage from '../../../_assets/images/research-innovation-platform.png';
import { Title } from '../../../_shared';

import './researchInnovationPlatform.scss';

const ResearchInnovationPlatform: FC = () => {
  const { t } = useTranslation();

  return (
    <section className="ecl-container research-innovation-platform">
      <Title>{t('HOW_IT_WORKS.ABOUT_THE_RESEARCH_AND_INNOVATION_PLATFORM.TITLE')}</Title>
      <div className="research-innovation-platform__content">
        <img alt="" src={ResearchInnovationPlatformImage} />
        <div>
          <p>{t('HOW_IT_WORKS.ABOUT_THE_RESEARCH_AND_INNOVATION_PLATFORM.DESCRIPTION')}</p>
        </div>
      </div>
    </section>
  );
};
export default ResearchInnovationPlatform;
