import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TExtraPageDetail } from '../_models';

function getExtraPage(eventId: string, extraPageId: string): Promise<TExtraPageDetail> {
  return HttpClient.get<TExtraPageDetail>(`extra-page/${extraPageId}`, {}, { 'X-Event-Id': eventId });
}

export function useGetExtraPage() {
  const { event } = useEventContext();
  return useQuery<TExtraPageDetail, TApiError>(['extraPage', event.id], () => getExtraPage(event.id, event.extraPage.id));
}
