import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { notificationId: string };

function markAsRead({ notificationId }: TParams): Promise<void> {
  return HttpClient.post<void>(`notifications/${notificationId}/mark-as-read`);
}

export function useMarkAsRead() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TParams>('markAsRead', markAsRead, {
    onSuccess: () => queryClient.invalidateQueries('notifications'),
  });
}
