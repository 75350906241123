import { useEffect, useState } from 'react';

export default function useScrollHeight(): number {
  const [scrollHeight, setScrollHeight] = useState(window.scrollY);

  function updateScrollHeight() {
    setScrollHeight(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener('scroll', updateScrollHeight);
    return () => {
      window.removeEventListener('scroll', updateScrollHeight);
    };
  }, [scrollHeight]);

  return scrollHeight;
}
