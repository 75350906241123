import { FC } from 'react';

import { getUserName } from '../_models';
import { Button } from '../_shared';
import { ModalOpener } from '../_shared/modal/ModalOpener';
import AuthModal from '../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../auth/_context';

import './chatroom.scss';

type TProps = {
  chatroomId: string;
  title?: string;
};

/**
 * Example usage: <Chatroom chatroomId="r0CRjf4uS" title="test" />
 */
const Chatroom: FC<TProps> = ({ chatroomId, title }) => {
  const { profile } = useAuthContext();
  const username = getUserName(profile) || '-';

  if (!chatroomId) return null;
  return (
    <Button className="chat-wrapper" onClick={() => ModalOpener.instance.open({ render: () => <AuthModal /> })} theme="wrapper">
      {title && (
        <div className="chat-header">
          <span>{title}</span>
        </div>
      )}

      <iframe
        allowFullScreen
        className={`chat${profile ? '' : '--unauthenticated'}`}
        frameBorder="0"
        scrolling="auto"
        src={`https://deadsimplechat.com/${chatroomId}?username=${username}`}
        title="Video player chat"
      />
    </Button>
  );
};

export default Chatroom;
