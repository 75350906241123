import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../../_hooks';
import { ContentType, TGalleryItem } from '../../_models';
import { Icon } from '../../_shared';
import './gallery.scss';

/*
 * EC Component: Gallery
 * html: https://ec.europa.eu/component-library/playground/ec/?path=/story/components-gallery--default
 * js: https://github.com/ec-europa/europa-component-library/blob/v3-dev/src/implementations/vanilla/components/gallery/gallery.js
 */

type TProps = {
  gallery: TGalleryItem[];
};

const Gallery: FC<TProps> = ({ gallery }) => {
  const { t } = useTranslation();
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex(prevState => {
      const next = prevState + 1;
      if (next > gallery.length - 1) return 0;
      else return next;
    });
  };

  const handlePrevious = () => {
    setActiveIndex(prevState => {
      const previous = prevState - 1;
      if (previous < 0) return gallery.length - 1;
      else return previous;
    });
  };

  const handleOnClick = (index: number) => {
    setActiveIndex(index);
    toggleIsOpen();
  };

  const showImage = ({ thumbnail, title, media, contentType, url }: TGalleryItem) => {
    if (
      media?.contentType.includes(ContentType.Video) ||
      contentType?.includes(ContentType.Youtube) ||
      contentType?.includes(ContentType.Video)
    ) {
      return thumbnail ? (
        <img alt={thumbnail?.name} className="ecl-gallery__image" src={thumbnail.url} />
      ) : (
        <div className="ecl-gallery__image video-placeholder">
          <Icon name="video" />
          <p>{title}</p>
        </div>
      );
    }
    return <img alt={media?.name ?? title} className="ecl-gallery__image" src={media?.url ?? url} />;
  };

  const showContent = ({ media, contentType, url, title }: TGalleryItem) => {
    if (media?.contentType.includes(ContentType.Video) || contentType?.includes(ContentType.Video)) {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video controls>
          <source src={media?.url ?? url} type={media?.contentType ?? contentType} />
          Your browser does not support the video tag.
        </video>
      );
    }
    if (contentType?.includes(ContentType.Youtube) || contentType?.includes(ContentType.Link))
      return <iframe height="100%" src={url} title={title} width="100%"></iframe>;
    return <img alt={media?.name ?? title} className="ecl-gallery__slider-image" src={media?.url ?? url} />;
  };

  if (!gallery?.length) return null;

  return (
    <section className="ecl-gallery">
      <ul className="ecl-gallery__list">
        {gallery.map((item, index) => (
          <li className="ecl-gallery__item" key={item.id}>
            <button className="ecl-gallery__item-link" onClick={() => handleOnClick(index)}>
              <figure className="ecl-gallery__image-container">
                {showImage(item)}
                <figcaption className="ecl-gallery__description">
                  {item.title}
                  <span className="ecl-gallery__meta">{t('GALLERY.META', { index })}</span>
                </figcaption>
              </figure>
            </button>
          </li>
        ))}
      </ul>

      <div className="ecl-gallery__actions">
        <button className="ecl-button ecl-button--ghost ecl-gallery__view-all" onClick={toggleIsOpen}>
          {t('GALLERY.VIEW_ALL')}
        </button>
        <div className="ecl-gallery__info">{t('GALLERY.TOTAL', { count: gallery.length })}</div>
      </div>

      <dialog className="ecl-gallery__overlay" open={isOpen}>
        <header className="ecl-gallery__close">
          <button className="ecl-button ecl-button--ghost ecl-gallery__close-button" onClick={toggleIsOpen}>
            <span className="ecl-button__container">
              <span className="ecl-button__label">{t('SHARED.BUTTONS.CLOSE')}</span>
              <Icon className="ecl-icon ecl-icon--s ecl-button__icon ecl-button__icon--after" name="close" />
            </span>
          </button>
        </header>
        <section className="ecl-gallery__slider">
          <div className="ecl-gallery__slider-media-container">{showContent(gallery[activeIndex])}</div>
          <button className="ecl-button ecl-button--ghost ecl-gallery__slider-previous" onClick={handlePrevious}>
            <span className="ecl-button__container">
              <Icon
                className="ecl-icon ecl-icon--s ecl-button__icon ecl-button__icon--after"
                name="corner-arrow"
                transformation="rotate-270"
              />
              <span className="ecl-button__label">{t('GALLERY.PREVIOUS')}</span>
            </span>
          </button>
          <button className="ecl-button ecl-button--ghost ecl-gallery__slider-next" onClick={handleNext}>
            <span className="ecl-button__container">
              <span className="ecl-button__label">{t('GALLERY.NEXT')}</span>
              <Icon
                className="ecl-icon ecl-icon--s ecl-button__icon ecl-button__icon--after"
                name="corner-arrow"
                transformation="rotate-90"
              />
            </span>
          </button>
        </section>
        <footer className="ecl-gallery__detail">
          <div className="ecl-gallery__detail-counter">
            {t('GALLERY.CURRENT', { count: activeIndex + 1, total: gallery.length })}
          </div>
          <div className="ecl-gallery__detail-description">{gallery[activeIndex]?.description}</div>
          <div className="ecl-gallery__detail-meta">{t('GALLERY.COPYRIGHT', { title: gallery[activeIndex].title })}</div>
        </footer>
      </dialog>
    </section>
  );
};

export default Gallery;
