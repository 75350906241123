import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError, THttpPagedResponse } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TWorkshopSession } from '../../sessions/_models';
import { TWorkshopQuery } from '../_models';

function getWorkshops(eventId: string, query: TWorkshopQuery): Promise<THttpPagedResponse<TWorkshopSession[]>> {
  return HttpClient.get<THttpPagedResponse<TWorkshopSession[]>>(`workshops?${stringify(query)}`, {}, { 'X-Event-Id': eventId });
}

export function useGetWorkshops(query: TWorkshopQuery) {
  let eventId = ' ';
  if (!query.allFavorites) {
    const { event } = useEventContext();
    eventId = event.id;
  }
  return useQuery<THttpPagedResponse<TWorkshopSession[]>, TApiError>(['workshops', eventId, query], () =>
    getWorkshops(eventId, query),
  );
}
