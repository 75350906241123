import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { sessionId: string };

function addToFavorites({ sessionId }: TParams): Promise<void> {
  return HttpClient.post<void>(`workshops/favorites/${sessionId}`);
}

export function useAddToFavorites() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TParams>('workshops_addToFavorites', addToFavorites, {
    onSuccess: () => queryClient.invalidateQueries('workshops'),
  });
}
