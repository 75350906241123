import { FC, useRef } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Inbox } from 'talkjs/all';

import { useOnClickOutside, useToggle } from '../../_hooks';
import { Button } from '../../_shared';
import { useDeleteConversation, useLeaveConversation } from '../_queries';

import './conversationActionsDropdown.scss';

type TProps = {
  chatInbox: Inbox;
  conversationId: string;
};

const ConversationActionsDropdown: FC<TProps> = ({ conversationId, chatInbox }) => {
  const ref = useRef(null);
  const [isVisible, toggleVisibility] = useToggle(false);
  const { mutate: deleteConversation } = useDeleteConversation(chatInbox);
  const { mutate: leaveConversation } = useLeaveConversation(chatInbox);
  const { t } = useTranslation();

  useOnClickOutside(ref, () => toggleVisibility(false));

  return (
    <div className="conversation-actions-dropdown">
      <Button
        className={classnames('conversation-actions-dropdown__toggle', { 'is-open': isVisible })}
        hideLabel
        icon="SvgThreeDots"
        iconSize="xs"
        onClick={() => toggleVisibility()}
      >
        {t('SHARED.BUTTONS.MORE_OPTIONS')}
      </Button>
      {isVisible && (
        <div className="conversation-actions-dropdown__options" ref={ref}>
          <Button
            icon="SvgLogout"
            iconPosition="before"
            iconSize="s"
            onClick={() => {
              leaveConversation({ conversationId });
              toggleVisibility(false);
            }}
            theme="ghost"
          >
            {t('COMMUNITY.CONVERSATIONS.ACTIONS.LEAVE_CONVERSATION')}
          </Button>
          <Button
            icon="SvgCloseModal"
            iconPosition="before"
            iconSize="s"
            onClick={() => {
              deleteConversation({ conversationId });
              toggleVisibility(false);
            }}
            theme="ghost"
          >
            {t('COMMUNITY.CONVERSATIONS.ACTIONS.DELETE_CONVERSATION')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ConversationActionsDropdown;
