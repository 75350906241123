import { FC, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../../_hooks';
import { TUser, getUserName } from '../../_models';
import { useGetPostsByAuthor, useGetUserEvents, useGoToBookAMeeting } from '../../_queries';
import { getLabelType } from '../../_utils/eventHelpers';
import { getSocials } from '../../_utils/userHelpers';
import AuthModal from '../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../auth/_context';
import { Config } from '../../config';
import { CreateConversationModal } from '../../conversations/_components';
import Card from '../../ec/card/Card';
import CardTag from '../../ec/card/components/CardTag';
import { useAddToNetwork, useRemoveFromNetwork } from '../../network/_queries';
import { Featured } from '../../posts/_components';
import BookAMeetingButton from '../bookAMeetingButton/BookAMeetingButton';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import { ModalOpener } from '../modal/ModalOpener';
import ShowMore from '../showMore/ShowMore';
import Spinner from '../spinner/Spinner';

import './userModal.scss';

type TProps = {
  user: TUser;
};

const UserModal: FC<TProps> = ({ user }) => {
  const { t } = useTranslation();
  const socials = getSocials(user);
  const { isLoggedIn } = useAuthContext();
  const { data: events, isLoading: eventsLoading } = useGetUserEvents(user.id);
  const [isConnection, toggleConnection] = useToggle(user?.isConnection);
  const { mutate: addToNetwork } = useAddToNetwork();
  const { mutate: removeFromNetwork } = useRemoveFromNetwork();
  const { mutate: goToBookAMeeting } = useGoToBookAMeeting(`bookmeeting/${user.bookAMeetingId}`);
  const { data: posts, isLoading: postsLoading } = useGetPostsByAuthor(user.id);
  const [showMoreEvents, toggleShowMoreEvents] = useToggle(false);
  const [showMorePosts, toggleShowMorePosts] = useToggle(false);
  const MAX_INITIAL = 2;

  function openSendMessageModal() {
    ModalOpener.instance.close();
    ModalOpener.instance.open({
      render: () => (isLoggedIn ? <CreateConversationModal users={[user]} /> : <AuthModal />),
    });
  }

  function handleToggleNetwork() {
    if (!isLoggedIn) return ModalOpener.instance.open({ render: () => <AuthModal /> });
    else if (isConnection) removeFromNetwork({ connectionId: user.id }, { onSuccess: () => toggleConnection() });
    else addToNetwork({ connectionId: user.id }, { onSuccess: () => toggleConnection() });
  }

  const userIcon = () => {
    if (isConnection) return 'SvgUserAdded';
    else return 'SvgUserAdd';
  };

  const handleKeyDown = useCallback(event => {
    if (event.key === 'Escape') ModalOpener.instance.close();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className="user-detail user-modal">
      <Button hideLabel icon="close" onClick={() => ModalOpener.instance.close()} theme="ghost">
        {t('SHARED.BUTTONS.CLOSE')}
      </Button>
      <section className="user-detail__information">
        <div className="user-detail__information__content">
          {user?.image ? <img alt="speaker-image" src={user.image} /> : <Icon name="SvgUser" size={8} />}

          <div className="user-detail__information__content__text">
            <h3>{getUserName(user)}</h3>
            <div className="user-info-line">
              <Icon name="SvgWork" size={1} />

              <div>
                <span>{`${user?.organisationType || ''}${user?.organisation ? ' - ' : user?.organisationType ? '' : '-'}${
                  user?.organisation || ''
                }`}</span>

                <span>{t('PROFILE.POSITION', { position: user?.position || '-' })}</span>
              </div>
            </div>
            <div className="user-info-line">
              <Icon name="SvgLocation" size={1} />

              <span>{t('PROFILE.LOCATION', { location: user?.nationality || '-' })}</span>
            </div>

            <div className="user-info-line--buttons">
              {user?.canContact && (
                <Button onClick={openSendMessageModal} theme="secondary">
                  {t('COMMUNITY.NETWORK.SEND_MESSAGE')}
                </Button>
              )}
              {user?.canContact && user?.calendlyUrl && <BookAMeetingButton calendlyUrl={user?.calendlyUrl} />}
              <Button icon={userIcon()} iconSize="s" onClick={handleToggleNetwork} theme="primary">
                {t(`COMMUNITY.NETWORK.${isConnection ? 'REMOVE' : 'ADD'}`)}
              </Button>
              {Config.showCommunity && (
                <Button onClick={() => goToBookAMeeting()}>{t('HUBS.DETAIL.BOOK_A_MEETING.TITLE')}</Button>
              )}
            </div>
          </div>
        </div>
      </section>

      {user?.biography && (
        <section className="user-detail__bio">
          <h2>{t('PROFILE.BIO')}</h2>
          <p>{user?.biography}</p>
        </section>
      )}

      {!!user?.interests?.length && (
        <section className="user-detail__topics">
          <h2>{t('PROFILE.TOPICS')}</h2>

          <div>
            {user?.interests.sort().map(interest => (
              <CardTag key={interest} tag={interest} />
            ))}
          </div>
        </section>
      )}

      {!!socials?.length && (
        <section className="user-detail__socials">
          <h2>{t('PROFILE.SOCIALS')}</h2>

          {socials.map(social => (
            <Button href={social.url} icon={social.icon} iconPosition="before" key={social.url} theme="ghost">
              {social.name}
            </Button>
          ))}
        </section>
      )}

      {((user?.email && user?.canContact) || user?.website) && (
        <section className="user-detail__contact">
          <h2>{t('PROFILE.CONTACT')}</h2>

          {user?.email && user?.canContact && (
            <Button href={`mailto:${user?.email}`} icon="SvgMailOutline" iconPosition="before" theme="plain-link">
              {user?.email}
            </Button>
          )}

          {user?.website && (
            <Button href={user?.website} icon="SvgLink" iconPosition="before" theme="plain-link">
              {user?.website}
            </Button>
          )}
        </section>
      )}

      {(eventsLoading || events?.length > 0) && (
        <section className="user-detail__events">
          <h2>{t('EVENTS.MEMBER_INTERESTED')}</h2>
          {eventsLoading ? (
            <Spinner />
          ) : (
            <div className="user-detail__events__grid">
              {events.slice(0, showMoreEvents ? events.length : MAX_INITIAL).map(event => (
                <Card
                  date={event?.startDate}
                  image={event?.image?.url}
                  imageAlt={event?.image?.name}
                  key={event?.id}
                  labelType={getLabelType(event?.startDate, event?.endDate)}
                  link={`${event?.id}/overview`}
                  tags={event?.topics}
                  title={event?.title}
                />
              ))}
              {events?.length > MAX_INITIAL && (
                <span className="show-more">
                  <span className="show-more__text">{`${showMoreEvents ? events?.length : MAX_INITIAL} results of ${
                    events?.length
                  }`}</span>
                  <ShowMore onClick={toggleShowMoreEvents} open={showMoreEvents}>
                    {t(showMoreEvents ? 'SHARED.BUTTONS.SHOW_LESS' : 'SHARED.BUTTONS.SHOW_MORE')}
                  </ShowMore>
                </span>
              )}
            </div>
          )}
        </section>
      )}

      <section>
        <h2>{t('COMMUNITY.POSTS.POSTS_BY_MEMBER')}</h2>
        <div className="user-detail__posts">
          {postsLoading && <Spinner />}
          {!!posts?.length &&
            posts.slice(0, showMorePosts ? posts.length : MAX_INITIAL).map(post => (
              <Featured
                key={post.id}
                post={{
                  commentCount: post.commentCount,
                  content: post.content,
                  id: post.id,
                  isFavorite: false,
                  isFeatured: post.isFeatured,
                  publishDate: post.publishDate,
                  thumbnail: post.thumbnail,
                  title: post.title,
                  topics: post.topics,
                  user: {
                    firstName: user.firstName,
                    id: user.id,
                    image: user?.image,
                    lastName: user.lastName,
                  },
                  votes: post.votes,
                }}
              />
            ))}
        </div>
        {posts?.length > MAX_INITIAL && (
          <ShowMore onClick={toggleShowMorePosts} open={showMorePosts}>
            {t(showMoreEvents ? 'SHARED.BUTTONS.SHOW_LESS' : 'SHARED.BUTTONS.SHOW_MORE')}
          </ShowMore>
        )}
      </section>
    </div>
  );
};

export default UserModal;
