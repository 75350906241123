import { Document } from '@contentful/rich-text-types';

import { TDocument, TLink, TUser } from '../../_models';

export enum SessionType {
  Live = 'LIVE',
  Workshop = 'WORKSHOP',
}

export type TSession = {
  documentation?: TDocument[];
  endTime: string;
  id: string;
  isFavorite?: boolean;
  links?: TLink[];
  recordingUrl?: string;
  speakers?: TUser[];
  startTime: string;
  summary?: { json: Document };
  title?: string;
  topics: string[];
  type: SessionType;
};

export type TLiveSession = TSession & {
  channelId?: string;
  chatroomId?: string;
  slidoUrl?: string;
  streamingUrl?: string;
  type: SessionType.Live;
};

export type TWorkshopSession = TSession & {
  type: SessionType.Workshop;
  webexId?: string;
};
