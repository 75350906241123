import { FC } from 'react';

import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '../../../_shared';
import { useAuthContext } from '../../../auth/_context';
import { TComment } from '../../_models';
import { useLikeComment, useUnLikeComment } from '../../_queries';
import './comment.scss';

type TProps = {
  comment: TComment;
  onReply: () => void;
  postId: string;
};

const Comment: FC<TProps> = ({ comment, postId, onReply }) => {
  const { t } = useTranslation();
  const { mutate: likeComment, isLoading: likeLoading } = useLikeComment(postId);
  const { mutate: unLikeComment, isLoading: unLikeLoading } = useUnLikeComment(postId);
  const { profile } = useAuthContext();

  function handleLike() {
    if (comment.upvoted) unLikeComment(comment.id);
    else likeComment(comment.id);
  }

  return (
    <div className="comment">
      <div className="comment__header">
        {comment.user?.image ? (
          <img
            alt={t('COMMUNITY.POSTS.PROFILE_IMAGE_ALT', { user: `${comment.user.firstName} ${comment.user.lastName}` })}
            src={comment.user.image}
          />
        ) : (
          <Icon name="SvgUser" />
        )}
        <div>
          <span className="comment__header__user">{`${comment.user.firstName} ${comment.user.lastName}`}</span>
          <span className="comment__header__time">{formatDistanceToNow(new Date(comment.postDate))}</span>
        </div>
      </div>

      <p dangerouslySetInnerHTML={{ __html: comment.comment }}></p>
      <div className="comment__actions">
        <Button
          disabled={comment.user.id === profile.id}
          icon="SvgMessage"
          iconPosition="before"
          iconSize="xs"
          onClick={onReply}
          theme="ghost"
        >
          {t('COMMUNITY.POSTS.REPLY')}
        </Button>
        <Button
          disabled={comment.user.id === profile.id}
          icon="SvgThumbsUpFill"
          iconPosition="before"
          iconSize="xs"
          loading={likeLoading || unLikeLoading}
          onClick={handleLike}
          theme="ghost"
        >
          {t(comment.upvoted ? 'COMMUNITY.POSTS.LIKED' : 'COMMUNITY.POSTS.LIKE')}
        </Button>
        <span>{t('COMMUNITY.POSTS.LIKES', { count: comment.votes })}</span>
      </div>
    </div>
  );
};
export default Comment;
