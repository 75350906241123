import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TLiveSession, TWorkshopSession } from '../_models';

function getRelatedSessions(id: string, topics: string[]): Promise<Array<TLiveSession | TWorkshopSession>> {
  return HttpClient.get<Array<TLiveSession | TWorkshopSession>>(
    `programme/related?${stringify({ topics })}`,
    {},
    { 'X-Event-Id': id },
  );
}

export function useGetRelatedSessions(topics: string[]) {
  const { event } = useEventContext();
  return useQuery<Array<TLiveSession | TWorkshopSession>, TApiError>(['relatedSessions', event.id, topics], () =>
    getRelatedSessions(event.id, topics),
  );
}
