import { FC, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useResponsive } from '../../../_hooks';
import { Icon, Spinner, Title } from '../../../_shared';
import { getLabelType } from '../../../_utils/eventHelpers';
import Card from '../../../ec/card/Card';
import SwiperCarousel from '../../../ec/carousel/SwiperCarousel';
import { useGetFeaturedEvents } from '../../_queries';
import './featuredEvents.scss';

const FeaturedEvents: FC = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { data, isLoading } = useGetFeaturedEvents();
  const { Responsive } = useResponsive();

  function getSlidesPerView(): number {
    if (Responsive.isBiggerThan('monitor')) return 3;
    if (Responsive.isBiggerThan('tablet')) return 2;
    return 1;
  }

  return (
    <section className="featured-events ecl-container" ref={ref}>
      <Title>{t('HOME.WHATS_ON')}</Title>
      <div className="featured-events__title">
        <h3>{t('EVENTS.FEATURED_EVENTS')}</h3>
        <Link to="/events">
          {t('HOME.ALL_EVENTS')}
          <Icon name="corner-arrow" size={1} transformation="rotate-90" />
        </Link>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="featured-events__carousel">
          <SwiperCarousel
            emptyLabel={t('EVENTS.FEATURED_EVENTS_EMPTY')}
            slides={(data || []).map(event => (
              <Card
                date={event?.startDate}
                image={event?.image?.url}
                imageAlt={event?.image?.name}
                key={event?.id}
                labelType={getLabelType(event?.startDate, event?.endDate)}
                link={`/events/${event?.id}/overview`}
                tags={event?.topics}
                title={event?.title}
              />
            ))}
            slidesPerView={getSlidesPerView()}
            spaceBetween={32}
          />
        </div>
      )}
    </section>
  );
};

export default FeaturedEvents;
