import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import Detail from './detail/Detail';
import Overview from './overview/Overview';

const HubsRoutes: FC = () => (
  <Routes>
    <Route element={<Overview />} path="/" />
    <Route element={<Detail />} path=":hubId" />
  </Routes>
);

export default HubsRoutes;
