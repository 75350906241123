import { FC, ReactNode, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../button/Button';

import { ModalOpener } from './ModalOpener';
import './modal.scss';

type TProps = {
  children: ReactNode;
  className?: string;
  title: string;
};

const Modal: FC<TProps> = ({ children, className, title }) => {
  const { t } = useTranslation();

  const handleKeyDown = useCallback(event => {
    if (event.key === 'Escape') ModalOpener.instance.close();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className={`modal ${className}`}>
      <div className="modal__header">
        <Button hideLabel icon="SvgClose" iconSize={1.1} onClick={() => ModalOpener.instance.close()} theme="ghost">
          {t('SHARED.BUTTONS.CLOSE')}
        </Button>

        <h2>{title}</h2>
      </div>

      <div className="modal__content">{children}</div>
    </div>
  );
};

export default Modal;
