import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Spinner } from '../../../_shared';
import { useGetRelatedSessions } from '../../_queries';
import Item from '../item/Item';

import './relatedSessions.scss';

type TProps = {
  topics: string[];
};

const RelatedSessions: FC<TProps> = ({ topics = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: sessions, isLoading } = useGetRelatedSessions(topics);

  return (
    <section className="related-sessions">
      <h4>{t('SESSIONS.RELATED.TITLE')}</h4>
      {isLoading ? (
        <Spinner />
      ) : sessions && sessions.length ? (
        sessions.slice(0, 2).map(session => <Item key={session?.id} session={session} />)
      ) : (
        <span className="related-sessions__empty">{t('SESSIONS.RELATED.EMPTY')}</span>
      )}
      {sessions && sessions.length > 2 && (
        <Button
          className="related-sessions__button"
          onClick={() => navigate('../../programme', { state: { topics } })}
          theme="ghost"
        >
          {t('SESSIONS.RELATED.GO_TO_RELATED')}
        </Button>
      )}
    </section>
  );
};

export default RelatedSessions;
