import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { TLink } from '../../_models';
import ExternalResource from '../externalResource/ExternalResource';
import './externalResources.scss';

type TProps = {
  links: TLink[];
};

const ExternalResources: FC<TProps> = ({ links }) => {
  const { t } = useTranslation();

  return (
    <section className="hub-detail__section hub-detail__external-resources">
      <h3>{t('HUBS.DETAIL.RELATED_LINKS')}</h3>
      {links && links.length ? (
        <div>
          {links.map(link => (
            <ExternalResource key={link.title} link={link} />
          ))}
        </div>
      ) : (
        <span className="hub-detail__section__empty">{t('HUBS.DETAIL.EMPTY_RELATED_LINKS')}</span>
      )}
    </section>
  );
};

export default ExternalResources;
