import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TEvent } from '../_models';

function getFeaturedEvents(): Promise<TEvent[]> {
  return HttpClient.get<TEvent[]>('events/featured');
}

export function useGetFeaturedEvents() {
  return useQuery<TEvent[], TApiError>('featuredEvents', () => getFeaturedEvents());
}
