import { FC, ReactNode } from 'react';

import Icon from '../icon/Icon';

import './showMore.scss';

type TProps = {
  children: string | ReactNode;
  onClick: () => void;
  open: boolean;
};

const ShowMore: FC<TProps> = ({ open, children, onClick }) => (
  <button className="show-more" onClick={onClick}>
    {children}
    <Icon name="corner-arrow" size={1} transformation={open ? undefined : 'flip-vertical'} />
  </button>
);
export default ShowMore;
