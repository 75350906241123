import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TProject } from '../_models';

function getProject(projectId: string, eventId: string): Promise<TProject> {
  return HttpClient.get<TProject>(`projects/${projectId}`, {}, { 'X-Event-Id': eventId });
}

export function useGetProject() {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const { event } = useEventContext();

  return useQuery<TProject, TApiError>(['project', projectId], () => getProject(projectId, event.id), {
    enabled: !!projectId,
    onError: () => navigate('/projects'),
  });
}
