import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, THttpPagedResponse } from '../../_http';
import { TPost, TPostResponseMetadata, TPostsQuery } from '../_models';

function getPosts(query: TPostsQuery): Promise<THttpPagedResponse<TPost[], TPostResponseMetadata>> {
  return HttpClient.get<THttpPagedResponse<TPost[], TPostResponseMetadata>>(`community/posts?${stringify(query)}`);
}

export function useGetPosts(query: TPostsQuery) {
  return useQuery(['getPosts', query], () => getPosts(query));
}
