/* eslint-disable */
type TEuropaWindow = Window & {
  $wt: any;
};

export class EuropaAnalytics {
  private static europaWindow: TEuropaWindow = window as unknown as TEuropaWindow;
  private static isFirstLoad: boolean = true;

  static trackPageView(): void {
    if (EuropaAnalytics?.europaWindow?.$wt?.analytics.isTrackable()) {
      EuropaAnalytics?.europaWindow?.$wt?.trackPageView();
    }
  }

  static getIsFirstLoad() {
    return EuropaAnalytics.isFirstLoad;
  }

  static setIsFirstLoad(value: boolean) {
    EuropaAnalytics.isFirstLoad = value;
  }
}
