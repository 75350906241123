import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../../../_hooks';
import { ShowMore } from '../../../_shared';
import CardTag from '../../../ec/card/components/CardTag';
import './topics.scss';

type TProps = {
  topics: string[];
};

const Topics: FC<TProps> = ({ topics }) => {
  const { t } = useTranslation();
  const [areAllTopicsVisible, toggleTopicsVisibility] = useToggle(false);

  return (
    <ul className="topics">
      {topics.slice(0, areAllTopicsVisible ? topics.length : 3).map(tag => (
        <li key={tag}>
          <CardTag tag={tag} />
        </li>
      ))}
      {topics.length > 3 && (
        <li>
          <ShowMore onClick={toggleTopicsVisibility} open={areAllTopicsVisible}>
            {areAllTopicsVisible ? t('SHARED.BUTTONS.SHOW_LESS') : t('SHARED.BUTTONS.SHOW_MORE')}
          </ShowMore>
        </li>
      )}
    </ul>
  );
};
export default Topics;
