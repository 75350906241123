import { useEffect } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '../_shared';
import { useAuthContext } from '../auth/_context';

import { Notification } from './_components';
import { useNotificationsContext } from './_context/NotificationsContext';

import './notifications.scss';

const Notifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthContext();
  const { notifications, countUnSeenNotifications, showAll } = useNotificationsContext();
  const areAllShown = notifications.length > 10;

  useEffect(() => {
    if (!isLoggedIn) navigate('/home');
  }, [isLoggedIn]);

  return (
    <main className="notifications">
      <div className="ecl-container notifications__back">
        <Button onClick={() => history.back()}>{t('SHARED.BUTTONS.BACK')}</Button>
      </div>

      <div className="notifications-container ecl-container">
        <h4>
          {t('SHARED.NAVIGATION.NOTIFICATIONS')}
          {countUnSeenNotifications > 0 && <span>{countUnSeenNotifications}</span>}
        </h4>

        {notifications?.length === 0 ? (
          <p className="no-notifications">{t('NOTIFICATIONS.NO_NOTIFICATIONS')}</p>
        ) : (
          (notifications || []).map(notification => <Notification key={notification.id} notification={notification} />)
        )}
      </div>

      {!areAllShown && (
        <div className="ecl-container load-more-container">
          <Button
            className={classnames('notifications__load-more', { 'is-closed': areAllShown })}
            icon="SvgChevron"
            iconSize={1}
            onClick={showAll}
            theme="ghost"
          >
            {t(areAllShown ? 'NOTIFICATIONS.HIDE' : 'NOTIFICATIONS.LOAD_MORE')}
          </Button>
        </div>
      )}
    </main>
  );
};

export default Notifications;
