import { useMutation } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { conversationId: string };

function inviteUsersToVideoMeeting({ conversationId }: TParams): Promise<void> {
  return HttpClient.post<void>('video-meeting/invite-users', { conversationId });
}

export function useInviteUsersToVideoMeeting() {
  return useMutation<void, TApiError, TParams>('inviteUsersToVideoMeeting', inviteUsersToVideoMeeting);
}
