import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../_hooks';
import { ActionButton, Button, Icon, Markdown, ShowMore, Spinner, Title } from '../_shared';
import { ModalOpener } from '../_shared/modal/ModalOpener';
import {
  BASE_TIME_STRING_FORMAT,
  DEFAULT_MONTH_STRING_FORMAT,
  formatISOString,
  isAfterDate,
  TIMEZONE_FORMAT,
} from '../_utils/dateHelpers';
import AuthModal from '../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../auth/_context';
import { Config } from '../config';

import { TWorkshopQuery } from './_models';
import { useAddToFavorites, useDeleteFromFavorites, useGetWorkshops } from './_queries';
import './workshops.scss';

const Workshops: FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();

  const [query, setQuery] = useState<TWorkshopQuery>({
    skip: 0,
    take: 6,
  });
  const [loadedMore, toggleLoadMore] = useToggle(false);

  const { data: workshops, isLoading } = useGetWorkshops(query);
  const { mutate: addToFavorites } = useAddToFavorites();
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites();

  function updateQuery(overrides: Partial<TWorkshopQuery>) {
    setQuery({ ...query, ...overrides });
  }

  function handleFavorites(id: string, isFavorite: boolean) {
    if (!isLoggedIn) return ModalOpener.instance.open({ render: () => <AuthModal /> });
    if (isFavorite) deleteFromFavorites({ sessionId: id });
    else addToFavorites({ sessionId: id });
  }

  function handleLoadMore() {
    updateQuery({ take: loadedMore ? 2 : workshops.meta.totalCount });
    toggleLoadMore();
  }

  function displayTitle(startTime: string, endTime: string) {
    if (isAfterDate(new Date(), new Date(endTime))) return t('EVENTS.REWATCH');
    else return t(isAfterDate(new Date(), new Date(startTime)) ? 'EVENT.WORKSHOPS.LIVE_NOW' : 'EVENT.WORKSHOPS.COMING_NEXT');
  }

  return (
    <main className="workshops">
      <section className="workshops__header ecl-container">
        <Title>{t('EVENT.WORKSHOPS.TITLE')}</Title>
        <p>{t('EVENT.WORKSHOPS.INFO')}</p>
      </section>

      <div className="workshops__content">
        <section className="ecl-container">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {workshops?.data?.map(workshop => (
                <div className="workshops__content__workshop" key={workshop.id}>
                  <h3>{displayTitle(workshop.startTime, workshop.endTime)}</h3>
                  <h3>{workshop.title}</h3>
                  <Markdown value={workshop?.summary?.json} />

                  <div>
                    <div>
                      <div className="workshops__content__workshop__date">
                        <Icon className="icon" name="calendar" />
                        <span>{formatISOString(workshop.startTime, DEFAULT_MONTH_STRING_FORMAT)}</span>
                      </div>
                      <div className="workshops__content__workshop__time">
                        <Icon className="icon" name="SvgClock" />
                        <span>{`${formatISOString(workshop.startTime, BASE_TIME_STRING_FORMAT)} - ${formatISOString(
                          workshop.endTime,
                          BASE_TIME_STRING_FORMAT,
                        )}`}</span>
                        <span>{formatISOString(workshop.startTime, TIMEZONE_FORMAT)}</span>
                      </div>
                    </div>

                    <div>
                      <Button
                        icon={workshop.isFavorite ? 'SvgFullHeart' : 'SvgEmptyHeart'}
                        onClick={() => handleFavorites(workshop.id, workshop.isFavorite)}
                        theme="secondary"
                      >
                        {t(workshop.isFavorite ? 'SHARED.BUTTONS.REMOVE_FROM_FAVOURITES' : 'SHARED.BUTTONS.ADD_TO_FAVOURITES')}
                      </Button>
                      <ActionButton session={workshop} />
                    </div>
                  </div>
                </div>
              ))}

              {(loadedMore || workshops?.meta.totalCount > workshops?.data?.length) && (
                <ShowMore onClick={handleLoadMore} open={loadedMore}>
                  {t(loadedMore ? 'EVENT.WORKSHOPS.SHOW_LESS' : 'EVENT.WORKSHOPS.LOAD_MORE')}
                </ShowMore>
              )}
            </>
          )}
        </section>
      </div>

      <div className="workshops__key-points  ecl-container">
        <div className="workshops__key-points__item">
          <h4>{t('EVENT.WORKSHOPS.MISSED_SOMETHING')}</h4>
          <p>
            {t('EVENT.WORKSHOPS.REWATCH_YOUTUBE')}
            <Button href={Config.youtubeLink} icon="SvgExternal" iconSize={0.8} theme="ghost">
              {t('EVENT.WORKSHOPS.YOUTUBE')}
            </Button>
          </p>
        </div>
      </div>
    </main>
  );
};

export default Workshops;
