import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError, THttpPagedResponse } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TProject, TProjectQuery } from '../_models';

function getProjects(id: string, query: TProjectQuery): Promise<THttpPagedResponse<TProject[]>> {
  return HttpClient.get<THttpPagedResponse<TProject[]>>(`projects?${stringify(query)}`, {}, { 'X-Event-Id': id });
}

export function useGetProjects(query: TProjectQuery) {
  let eventId = '';
  if (!query.allFavorites) {
    // ignore event id when fetching all favorites
    const { event } = useEventContext();
    eventId = event.id;
  }
  return useQuery<THttpPagedResponse<TProject[]>, TApiError>(['projects', eventId, query], () => getProjects(eventId, query));
}
