import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import EUFlag from '../../_assets/images/eu-flag.jpg';
import { useResponsive, useToggle } from '../../_hooks';
import { Button, Picture, Socials, Spinner, TextArea, Title } from '../../_shared';
import { sortCommentsMostRecently } from '../../_utils/dateHelpers';
import { useAuthContext } from '../../auth/_context';
import SwiperCarousel from '../../ec/carousel/SwiperCarousel';
import { Comment, Created, Featured, Numbers, Topics } from '../_components';
import {
  useAddComment,
  useAddToFavorite,
  useGetPost,
  useGetPosts,
  useLikePost,
  useRemoveToFavorite,
  useUnLikePost,
} from '../_queries';

import './detail.scss';

const Detail: FC = () => {
  const { t } = useTranslation();
  const { Responsive } = useResponsive();
  const { data: post, isLoading } = useGetPost();
  const { data: posts, isLoading: isPostsLoading } = useGetPosts({
    tags: post?.topics ?? [],
  });
  const { mutate: addToFavorite } = useAddToFavorite();
  const { mutate: removeToFavorite } = useRemoveToFavorite();
  const { mutate: likePost, isLoading: likeLoading } = useLikePost(post?.id);
  const { mutate: unLikePost, isLoading: unLikeLoading } = useUnLikePost(post?.id);
  const { mutate: addComment } = useAddComment(post?.id);
  const { profile } = useAuthContext();

  const [isVisible, toggleIsVisible] = useToggle(false);
  const [comment, setComment] = useState<string>();

  function handleFavorites() {
    if (post.isFavorite) removeToFavorite(post.id);
    else addToFavorite(post.id);
  }

  function handleLike() {
    if (post.upvoted) unLikePost();
    else likePost();
  }

  function getSlidesPerView(): number {
    if (Responsive.isBiggerThan('monitor')) return 3;
    if (Responsive.isBiggerThan('tablet')) return 2;
    return 1;
  }

  function handleComment() {
    if (isVisible && comment?.length) addComment(comment);
    toggleIsVisible();
  }

  function onReply() {
    toggleIsVisible();
    const comments = document.getElementById('comments');
    comments.scrollIntoView({ behavior: 'smooth' });
  }

  if (isLoading || isPostsLoading) return <Spinner />;
  return (
    <main className="post-detail">
      <div className="ecl-container post-detail__content">
        <aside>
          <Button
            href="/community/posts"
            icon="corner-arrow"
            iconPosition="before"
            iconSize="xs"
            iconTransformation="rotate-270"
            theme="ghost"
          >
            {t('SHARED.BUTTONS.BACK')}
          </Button>
          <p>{t('COMMUNITY.POSTS.PAGE_CONTENT')}</p>
          <ul>
            <li>
              <a href="#top">{t('COMMUNITY.POSTS.TOP')}</a>
            </li>
            <li>
              <a href="#comments">{t('COMMUNITY.POSTS.COMMENTS')}</a>
            </li>
            <li>
              <a href="#related-posts">{t('COMMUNITY.POSTS.RELATED_POSTS')}</a>
            </li>
          </ul>
          <p>{t('COMMUNITY.POSTS.INTERACTIONS')}</p>
          <ul>
            <li>
              <Button
                disabled={post?.user?.id === profile.id}
                icon={post.upvoted ? 'SvgThumbsUpFill' : 'SvgThumbsUp'}
                loading={likeLoading || unLikeLoading}
                onClick={handleLike}
                theme="secondary"
              >
                {t(post.upvoted ? 'COMMUNITY.POSTS.LIKED' : 'COMMUNITY.POSTS.LIKE')}
              </Button>
            </li>
            <li>
              <Button href="#comments" icon="SvgMessage" theme="secondary">
                {t('COMMUNITY.POSTS.WRITE_COMMENT')}
              </Button>
            </li>
            <li>
              <Button icon={post.isFavorite ? 'SvgFullHeart' : 'SvgEmptyHeart'} onClick={handleFavorites} theme="secondary">
                {t(post.isFavorite ? 'SHARED.BUTTONS.REMOVE_FROM_FAVOURITES' : 'SHARED.BUTTONS.ADD_TO_FAVOURITES')}
              </Button>
            </li>
          </ul>
          <p>{t('COMMUNITY.POSTS.SHARE_ACTICLE')}</p>
          <div className="post-detail__content__socials">
            <Socials />
          </div>
        </aside>
        <div id="top">
          <h1>{post.title}</h1>
          <Numbers commentCount={post.comments.length} votes={post.votes} />
          <Created author={post.user} date={post.publishDate} />
          <div className="post-detail__content__topics">
            <span>{t('SHARED.TOPICS')}</span>
            <Topics topics={post.topics} />
          </div>
          <Picture label={post.title} url={post?.thumbnail || EUFlag} />
          <ReactQuill readOnly={true} theme="bubble" value={post.content} />
        </div>
      </div>
      <div className="post-detail__comments" id="comments">
        <section className="ecl-container">
          <div className="post-detail__comments__header">
            <h2>{t('COMMUNITY.POSTS.COMMENTS')}</h2>
            <Button disabled={isVisible && !comment?.length} icon="SvgMessage" onClick={handleComment} theme="secondary">
              {t(isVisible ? 'COMMUNITY.POSTS.SEND_COMMENT' : 'COMMUNITY.POSTS.WRITE_COMMENT')}
            </Button>
          </div>
          {isVisible && <TextArea labelIcon="SvgAlert" name="comment" onChange={setComment} rows={3} />}
          {sortCommentsMostRecently(post.comments).map(comment => (
            <Comment comment={comment} key={comment.id} onReply={onReply} postId={post.id} />
          ))}
        </section>
      </div>
      <section className="ecl-container post-detail__related" id="related-posts">
        <Title>{t('COMMUNITY.POSTS.RELATED_POSTS')}</Title>
        {isPostsLoading && <Spinner />}
        <SwiperCarousel
          emptyLabel={t('COMMUNITY.POSTS.EMPTY_FEATURED_POSTS')}
          slides={posts.data?.map(post => (
            <Featured key={post.id} post={post} />
          ))}
          slidesPerView={getSlidesPerView()}
          spaceBetween={32}
        />
      </section>
    </main>
  );
};
export default Detail;
