import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { THub } from '../_models';

function getRelatedHubs(id: string, topics: string[]): Promise<THub[]> {
  return HttpClient.get<THub[]>(`hubs/related?${stringify({ topics })}`, {}, { 'X-Event-Id': id });
}

export function useGetRelatedHubs(topics: string[]) {
  const { event } = useEventContext();
  return useQuery<THub[], TApiError>(['relatedHubs', event.id, topics], () => getRelatedHubs(event.id, topics), {
    enabled: !!topics?.length,
  });
}
