import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Spinner, Title } from '../../_shared';
import { Item } from '../_components';
import { useGetHubs } from '../_queries';

import './overview.scss';

const Overview: FC = () => {
  const { t } = useTranslation();
  const { data: hubs, isLoading } = useGetHubs();

  return (
    <main className="hubs">
      <Helmet>
        <title>{t('EVENT.NAVIGATION.HUBS')}</title>
      </Helmet>
      <section className="ecl-container">
        <Title>{t('EVENT.NAVIGATION.HUBS')}</Title>
        <p>{t('HUBS.OVERVIEW.DESCRIPTION_1')}</p>
        <p>{t('HUBS.OVERVIEW.DESCRIPTION_2')}</p>
      </section>
      <section className="hubs__overview">
        <div className="ecl-container">
          <h3>{t('HUBS.OVERVIEW.VISIT_THE_HUBS')}</h3>
          {isLoading && <Spinner />}
          <div className="hubs__overview__items">
            {(hubs || []).map(hub => (
              <Item hub={hub} key={hub.id} />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Overview;
