import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

import { TProfile } from '../../_models';
import { Spinner } from '../../_shared';
import { Config } from '../../config';
import { useAuthenticate } from '../_queries';

type TAuthContext = {
  clearProfile: () => void;
  isLoggedIn: boolean;
  loginRedirectUrl: string;
  profile?: TProfile;
};

export const AuthContext = createContext<TAuthContext>({
  clearProfile: () => {},
  isLoggedIn: false,
  loginRedirectUrl: null,
});

export const useAuthContext = () => useContext(AuthContext);

type TProps = {
  children: ReactNode;
};

export const AuthContextProvider: FC<TProps> = ({ children }) => {
  const [profile, setProfile] = useState<TProfile>(null);
  const [localLoginRedirectUrl, setLocalLoginRedirectUrl] = useState<string>();
  const { isLoading } = useAuthenticate({
    onError: () => setProfile(null),
    onSuccess: data => setProfile(data),
  });

  const init = async () => {
    setLocalLoginRedirectUrl(await Config.getLoginRedirectUrl());
  };

  useEffect(() => {
    init();
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <AuthContext.Provider
      value={{
        clearProfile: () => setProfile(null),
        isLoggedIn: !!profile,
        loginRedirectUrl: localLoginRedirectUrl,
        profile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
