import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { getUserName, TUser } from '../../../_models';
import { Icon } from '../../../_shared';

import './item.scss';

type TProps = {
  user: TUser;
};

const Item: FC<TProps> = ({ user }) => {
  const fullName = getUserName(user);
  const { t } = useTranslation();

  return (
    <div className="network-item-link">
      {user?.image ? (
        <img alt={t('COMMUNITY.POSTS.PROFILE_IMAGE_ALT', { user: `${user.firstName} ${user.lastName}` })} src={user.image} />
      ) : (
        <Icon name="SvgUser" size={3} />
      )}
      <div className="network-item-link__card">
        <h3>{fullName}</h3>
        <p>{user.organisation}</p>
      </div>
    </div>
  );
};

export default Item;
