import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useToggle } from '../../_hooks';
import { ShowMore, Title } from '../../_shared';
import { Header, ListedEvents, MyEvents } from '../_components';

import './overview.scss';

const Overview: FC = () => {
  const { t } = useTranslation();
  const [isHidden, toggleIsHidden] = useToggle(false);

  return (
    <main className="events">
      <Helmet>
        <title>{t('SHARED.NAVIGATION.EVENTS')}</title>
      </Helmet>
      <div className="ecl-container">
        <Title>{t('SHARED.NAVIGATION.EVENTS')}</Title>
        <ShowMore onClick={toggleIsHidden} open={!isHidden}>
          {t(isHidden ? 'EVENTS.SHOW_FILTERS_SEARCH' : 'EVENTS.HIDE_FILTERS_SEARCH')}
        </ShowMore>
        {!isHidden && <Header />}
      </div>
      <MyEvents />
      <div className="ecl-container">
        <ListedEvents />
      </div>
    </main>
  );
};
export default Overview;
