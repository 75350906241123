import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { EuropaAnalytics } from '../_utils/EuropaAnalytics';

export default function useTrackAnalytics(): void {
  const location = useLocation();

  useEffect(() => {
    if (EuropaAnalytics.getIsFirstLoad()) {
      const observer = new MutationObserver(() => {
        if (document.contains(document.querySelector('div.analytics.wt.wt-analytics'))) {
          observer.disconnect();
          EuropaAnalytics.setIsFirstLoad(false);
          setTimeout(() => {
            EuropaAnalytics.trackPageView();
          }, 100);
        }
      });
      observer.observe(document, { attributes: false, characterData: false, childList: true, subtree: true });
    } else {
      setTimeout(() => {
        EuropaAnalytics.trackPageView();
      }, 100);
    }
  }, [location]);
}
