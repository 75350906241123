import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { HttpClient } from '../../_http';
import { TPostDetail } from '../_models';

function getPost(id: string): Promise<TPostDetail> {
  return HttpClient.get<TPostDetail>(`community/posts/${id}`);
}

export function useGetPost(postID?: string) {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  return useQuery(['getPost', id, postID], () => getPost(id || postID), {
    enabled: !!(id || postID),
    onError: () => navigate('/community/posts'),
  });
}
