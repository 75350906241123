import { FC } from 'react';

import { TLink } from '../../_models';
import Button from '../button/Button';

import './externalResource.scss';

type TProps = {
  link: TLink;
};

const ExternalResource: FC<TProps> = ({ link }) => {
  return (
    <Button className="external-resource" href={link.url} icon="external" iconPosition="after" iconSize="xs" theme="ghost">
      {link.title}
    </Button>
  );
};

export default ExternalResource;
