import { FC, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TUser } from '../_models';
import { useGetTopics } from '../_queries';
import { Button, Checkbox, FilterBar, Person, SearchField, Spinner, Title, UserSwiper } from '../_shared';
import { useAuthContext } from '../auth/_context';
import { CreateGroupchat } from '../conversations/_components';

import { TUsersQuery } from './_models';
import { useGetUsers } from './_queries';
import './network.scss';

const PAGE_COUNT = 15;

const Network: FC = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { data: topics } = useGetTopics();
  const { isLoggedIn, profile } = useAuthContext();
  const location = useLocation();

  const [query, setQuery] = useState<TUsersQuery>({
    allFollowers: location?.state?.allFollowers ?? false,
    allIFollow: location?.state?.allIFollow ?? false,
    interests: location?.state?.interests ?? [],
    nationalities: [],
    organisationTypes: [],
    residences: [],
    search: '',
    skip: 0,
    take: 15,
    types: [],
  });
  const [selectedUsers, setSelectedUsers] = useState<TUser[]>([]);
  const { data: users, isLoading: isUsersLoading } = useGetUsers(query);
  const { data: usersPicked, isLoading: isUsersPickedLoading } = useGetUsers({ interests: profile?.interests });

  const updateQuery = (overrides: Partial<TUsersQuery>) => {
    setQuery({ ...query, ...overrides });
  };

  const handleTopicsChange = (checked: boolean, name: string) => {
    updateQuery({ interests: checked ? [...query.interests, name] : query.interests.filter(topic => topic !== name) });
  };

  function selectUser(user: TUser): void {
    if (selectedUsers.includes(user)) setSelectedUsers(selectedUsers.filter(u => u !== user));
    else setSelectedUsers([...selectedUsers, user]);
  }

  return (
    <main className="network">
      <div className="ecl-container">
        <Title heading="h1">{t('COMMUNITY.NETWORK.TITLE')}</Title>
      </div>

      <UserSwiper
        emptyLabel={t('COMMUNITY.NETWORK.EMPTY')}
        isLoading={isUsersPickedLoading}
        renderSlides={(usersPicked?.data || []).map(user => (
          <Person key={user.id} user={user} />
        ))}
        title={t('COMMUNITY.NETWORK.FEATURED_MEMBERS')}
      />

      <section className="ecl-container network__all-members" ref={ref}>
        <FilterBar
          clearFilters={() =>
            setQuery({
              interests: [],
              skip: 0,
              take: 15,
            })
          }
        >
          <SearchField label={t('COMMUNITY.NETWORK.SEARCH')} onSearch={search => updateQuery({ search })} />

          <h4>{t('SHARED.TOPICS')}</h4>
          {(topics || []).map(topic => (
            <Checkbox
              checked={query.interests.includes(topic.title)}
              key={topic.title}
              name={topic.title}
              onChange={handleTopicsChange}
            >
              {topic.title}
            </Checkbox>
          ))}

          <h4>{t('SHARED.FAVOURITES')}</h4>
          <Checkbox
            checked={query.allFollowers}
            disabled={!isLoggedIn}
            name="allFollowers"
            onChange={allFollowers => updateQuery({ allFollowers, allIFollow: false })}
            type="toggle"
          >
            {t('COMMUNITY.NETWORK.ALL_FOLLOWERS')}
          </Checkbox>
          <Checkbox
            checked={query.allIFollow}
            disabled={!isLoggedIn}
            name="allIFollow"
            onChange={allIFollow => updateQuery({ allFollowers: false, allIFollow })}
            type="toggle"
          >
            {t('PARTICIPANTS.ALL_I_FOLLOW')}
          </Checkbox>
        </FilterBar>
        <div className="network__all-members__content">
          {isUsersLoading ? (
            <Spinner />
          ) : (
            <>
              <div className="network__all-members__content__create-groupchat">
                <CreateGroupchat selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
              </div>

              <span className="network__all-members__meta-count">
                {t('COMMUNITY.NETWORK.RESULTS_AND_SORT', { count: users?.meta?.count || 0 })}
              </span>
              {!users?.data?.length && <p>{t('COMMUNITY.NETWORK.EMPTY')}</p>}
              {(users?.data || []).map(user => (
                <Person
                  isSelected={selectedUsers.includes(user)}
                  key={user.id}
                  onSelectChange={() => selectUser(user)}
                  user={user}
                />
              ))}

              <div className="network__all-members__load-more">
                <span className="network__all-members__meta-count">
                  {t('COMMUNITY.NETWORK.RESULTS', { count: users?.meta?.count || 0, totalCount: users?.meta?.totalCount || 0 })}
                </span>
                <Button
                  disabled={users?.meta?.count >= users?.meta?.totalCount}
                  icon="SvgChevron"
                  iconSize="xs"
                  iconTransformation="rotate-180"
                  onClick={() => updateQuery({ take: query.take + PAGE_COUNT })}
                  theme="ghost"
                >
                  {t('COMMUNITY.NETWORK.LOAD_MORE')}
                </Button>
              </div>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default Network;
