import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TSession } from '../../sessions/_models';
import { TProgrammeQuery } from '../_models';

export function getProgramme<T extends TSession>(query: TProgrammeQuery, id: string): Promise<T[]> {
  return HttpClient.get<T[]>(`programme?${stringify(query)}`, {}, { 'X-Event-Id': id });
}

export function useGetProgramme<T extends TSession>(query: TProgrammeQuery) {
  const { event } = useEventContext();
  return useQuery<T[], TApiError>(['programme', query, event.id], () => getProgramme<T>(query, event.id));
}
