import { useQuery, UseQueryOptions } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TUser } from '../../_models';

async function authenticate(): Promise<TUser> {
  const profile = await HttpClient.get<TUser>('auth/me');
  if (!profile) throw new Error();
  return profile;
}

export function useAuthenticate(options: UseQueryOptions<TUser, TApiError>) {
  return useQuery<TUser, TApiError>('authenticate', () => authenticate(), {
    onError: error => options.onError(error),
    onSuccess: profile => options.onSuccess(profile),
  });
}
