import { FC } from 'react';

import { LatLngLiteral } from 'leaflet';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import { Button } from '../../../_shared';
import { TLocation } from '../../_models';

import './map.scss';

type TProps = {
  location: TLocation;
};

const Map: FC<TProps> = ({ location }) => {
  const { t } = useTranslation();
  const position: LatLngLiteral = { lat: location?.lat, lng: location?.lon };

  if (!position.lat || !position.lng) return null;
  return (
    <div className="event-map">
      <MapContainer center={position} scrollWheelZoom={false} zoom={13}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position}></Marker>
      </MapContainer>
      <div className="event-map__actions">
        <Button
          href={`https://www.openstreetmap.org/note/new#map=19/${position.lat}/${position.lng}&layers=N`}
          icon="external"
          iconPosition="after"
          iconSize="xs"
          theme="ghost"
        >
          {t('EVENT.OVERVIEW.OPEN_MAP')}
        </Button>
      </div>
    </div>
  );
};

export default Map;
