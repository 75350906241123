import { FC } from 'react';

import { TLiveSession } from '../../sessions/_models';
import { useGetChannels } from '../../sessions/_queries';

import './channelRoom.scss';

type TProps = {
  session: TLiveSession;
};

const ChannelRoom: FC<TProps> = ({ session }) => {
  const { data: channels } = useGetChannels();

  if (!session?.channelId) return null;
  return (
    <div className="channel-room">
      <p>{channels.find(({ id }) => id === session?.channelId)?.title}</p>
    </div>
  );
};
export default ChannelRoom;
