import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useResponsive, useToggle } from '../../_hooks';
import { useGetTopics } from '../../_queries';
import { Button, Checkbox, Datepicker, FilterBar, SearchField, Spinner, Title } from '../../_shared';
import { ModalOpener } from '../../_shared/modal/ModalOpener';
import SwiperCarousel from '../../ec/carousel/SwiperCarousel';
import { TopicCarousel } from '../../projects/_components';
import { Featured, Card, CreatePostModal } from '../_components';
import { getSortByLabel, TPostsQuery } from '../_models';
import { useGetPosts } from '../_queries';
import './overview.scss';

const Overview: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { search } = useLocation();
  const { Responsive } = useResponsive();
  const { topic } = parse(search);

  const [query, setQuery] = useState<TPostsQuery>({
    isFavorite: location?.state?.isFavorite ?? false,
    page: 1,
    sortBy: 'timestamp',
    tags: topic ?? location?.state?.tags ?? [],
  });
  const [isSortByVisible, toggleIsSortByVisisble] = useToggle(false);

  const { data: posts, isLoading: postsLoading } = useGetPosts(query);
  const { data: topics, isLoading: topicsLoading } = useGetTopics();
  const activeTopic = topics?.find(eventTopic => eventTopic.title === topic);

  function getSlidesPerView(): number {
    if (Responsive.isBiggerThan('monitor')) return 3;
    if (Responsive.isBiggerThan('tablet')) return 2;
    return 1;
  }

  function updateQuery(overrides: Partial<TPostsQuery>): void {
    setQuery(prevState => ({ ...prevState, ...overrides }));
  }

  function handleTopicsChange(checked: boolean, name: string): void {
    updateQuery({ tags: checked ? [...query.tags, name] : query.tags.filter(topic => topic !== name) });
  }

  useEffect(() => {
    updateQuery({ tags: topic ?? location?.state?.tags ?? [] });
  }, [topic]);

  return (
    <main className="community-posts">
      <section className="ecl-container">
        <Title>{t('COMMUNITY.POSTS.TITLE')}</Title>
      </section>

      <TopicCarousel activeTopic={activeTopic} href="/community/posts" isLoading={topicsLoading} topics={topics} />

      {!topic && (
        <section className="community-posts__featured">
          <div className="ecl-container">
            <Title>{t('COMMUNITY.POSTS.FEATURED_POSTS')}</Title>
            <SwiperCarousel
              emptyLabel={t('COMMUNITY.POSTS.EMPTY_FEATURED_POSTS')}
              slides={(posts?.data ?? [])
                ?.filter(({ isFeatured }) => isFeatured)
                .map(post => (
                  <Featured key={post.id} post={post} />
                ))}
              slidesPerView={getSlidesPerView()}
              spaceBetween={32}
            />
          </div>
        </section>
      )}

      <section className="ecl-container">
        <Title>{t('COMMUNITY.POSTS.ALL_POSTS')}</Title>

        <div className="community-posts__content">
          <FilterBar
            clearFilters={() =>
              updateQuery({
                isFavorite: false,
                search: undefined,
                tags: [topic as string],
                timestamp: undefined,
                timestampFilter: undefined,
              })
            }
          >
            <SearchField label={t('COMMUNITY.POSTS.SEARCH')} onSearch={search => updateQuery({ search })} />

            <h4>{t('SHARED.DATE')}</h4>
            <Datepicker
              onChange={(date: string) => updateQuery({ timestamp: date, timestampFilter: 'older' })}
              value={query?.timestamp}
            />
            <Checkbox
              checked={query?.timestampFilter === 'older'}
              disabled={!query.timestamp}
              name="before"
              onChange={() => updateQuery({ timestampFilter: 'older' })}
            >
              {t('SHARED.BEFORE')}
            </Checkbox>
            <Checkbox
              checked={query?.timestampFilter === 'newer'}
              disabled={!query.timestamp}
              name="after"
              onChange={() => updateQuery({ timestampFilter: 'newer' })}
            >
              {t('SHARED.AFTER')}
            </Checkbox>

            {!topic && (
              <>
                <h4>{t('SHARED.TOPICS')}</h4>
                {topics?.map(topic => (
                  <Checkbox
                    checked={query.tags.includes(topic.title)}
                    key={topic.title}
                    name={topic.title}
                    onChange={handleTopicsChange}
                  >
                    {topic.title}
                  </Checkbox>
                ))}
              </>
            )}

            <h4>{t('SHARED.FAVOURITES')}</h4>
            <Checkbox
              checked={query.isFavorite}
              name="favoritesOnly"
              onChange={isFavorite => updateQuery({ isFavorite })}
              type="toggle"
            >
              {t('COMMUNITY.POSTS.ONLY_SHOW_FAVOURITES')}
            </Checkbox>
          </FilterBar>
          <div className="community-posts__content__posts">
            <div className="community-posts__content__posts__header">
              <div>
                <span className="community-posts__content__posts__header__label">
                  {t('COMMUNITY.POSTS.RESULTS', { count: posts?.meta.totalCount ?? 0 })}
                </span>
                <Button
                  icon="SvgChevron"
                  iconTransformation={isSortByVisible ? 'flip-vertical' : undefined}
                  onClick={toggleIsSortByVisisble}
                  theme="ghost"
                >
                  {getSortByLabel(query.sortBy)}
                </Button>
                <div
                  className={classNames('community-posts__content__posts__header__sort', {
                    'community-posts__content__posts__header__sort-open': isSortByVisible,
                  })}
                >
                  <Button
                    onClick={() => {
                      updateQuery({ sortBy: 'topic.votes' });
                      toggleIsSortByVisisble();
                    }}
                    theme="ghost"
                  >
                    {t('COMMUNITY.POSTS.POPULARITY')}
                  </Button>
                  <Button
                    onClick={() => {
                      updateQuery({ sortBy: 'topic.title' });
                      toggleIsSortByVisisble();
                    }}
                    theme="ghost"
                  >
                    {t('COMMUNITY.POSTS.ALPHABETICAL_ORDER')}
                  </Button>
                  <Button
                    onClick={() => {
                      updateQuery({ sortBy: 'timestamp' });
                      toggleIsSortByVisisble();
                    }}
                    theme="ghost"
                  >
                    {t('COMMUNITY.POSTS.CHRONOLOGICAL_ORDER')}
                  </Button>
                </div>
              </div>
              <Button
                icon="plus"
                onClick={() =>
                  ModalOpener.instance.open({
                    render: () => <CreatePostModal />,
                  })
                }
                theme="secondary"
              >
                {t('COMMUNITY.POSTS.WRITE_POST')}
              </Button>
            </div>
            {!postsLoading && !posts?.data?.length && (
              <p className="community-posts__content__posts__empty">{t('COMMUNITY.POSTS.EMPTY')}</p>
            )}
            {postsLoading ? <Spinner /> : posts?.data?.map(post => <Card key={post.id} post={post} />)}
            {!postsLoading && posts?.meta?.totalPages > 1 && (
              <div className="community-posts__content__posts__pagination">
                <Button
                  disabled={query.page <= 1}
                  icon="SvgChevron"
                  iconPosition="before"
                  iconTransformation="rotate-270"
                  onClick={() => updateQuery({ page: query.page - 1 })}
                  theme="ghost"
                >
                  {t('GALLERY.PREVIOUS')}
                </Button>
                <div>
                  {Array.from({ length: posts.meta.totalPages }).map((_, i) => (
                    <Button
                      className={classNames({
                        'community-posts__content__posts__pagination__active': query.page === i + 1,
                      })}
                      key={i + 1}
                      onClick={() => updateQuery({ page: i + 1 })}
                      theme="ghost"
                    >
                      {i + 1}
                    </Button>
                  ))}
                </div>
                <Button
                  disabled={query.page >= posts.meta.totalPages}
                  icon="SvgChevron"
                  iconTransformation="rotate-90"
                  onClick={() => updateQuery({ page: query.page + 1 })}
                  theme="ghost"
                >
                  {t('GALLERY.NEXT')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
export default Overview;
