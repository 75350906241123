import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { useGetTopics } from '../../../_queries';
import { Button, Checkbox, FileInput, InputField, Modal, Spinner } from '../../../_shared';
import { FileInputType } from '../../../_shared/input/file/FileInput';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import { useCreatePost } from '../../_queries';
import 'react-quill/dist/quill.snow.css';

import './createPostModal.scss';

const CreatePostModal: FC = () => {
  const { t } = useTranslation();
  const { mutate: createPost, isLoading } = useCreatePost({ onSuccess: () => ModalOpener.instance.close() });
  const { data: topics } = useGetTopics();

  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<string>();
  const [tags, setTags] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  function handleTopicChange(checked: boolean, title: string): void {
    if (checked) setTags(prevState => [...prevState, title]);
    else setTags(prevState => prevState.filter(topic => topic !== title));
  }

  function stripHtmlTags(html: string) {
    // Create a temporary element to hold the HTML content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    // Extract the text content and return it
    return tempElement.textContent || tempElement.innerText || '';
  }

  return (
    <Modal className="create-post-modal" title={t('COMMUNITY.POSTS.WRITE_POST')}>
      <InputField
        autoFocus
        label={t('COMMUNITY.POSTS.CREATE.TITLE_MESSAGE')}
        minLength={3}
        name="title"
        onChange={(title: string) => setTitle(title)}
        placeholder={t('COMMUNITY.POSTS.CREATE.ADD_TITLE')}
        value={title}
      />
      <p className="create-post-modal__label">{t('COMMUNITY.POSTS.CREATE.TITLE_CONTENT')}</p>
      <ReactQuill
        onChange={setContent}
        placeholder={t('COMMUNITY.POSTS.CREATE.EDITOR_PLACEHOLDER')}
        theme="snow"
        value={content}
      />

      <span className="create-post-modal__label">{t('SHARED.TOPICS')}</span>
      {topics?.map(({ title }) => (
        <Checkbox checked={tags.includes(title)} key={title} name={title} onChange={checked => handleTopicChange(checked, title)}>
          {title}
        </Checkbox>
      ))}
      <span className="create-post-modal__label">{t('COMMUNITY.POSTS.CREATE.IMAGE')}</span>
      <FileInput maxAmountOfFiles={1} name="image" onChange={files => setFiles(files)} type={FileInputType.Image} value={files} />
      <div className="create-post-modal__actions">
        {isLoading ? (
          <Spinner />
        ) : (
          <Button
            disabled={title?.length < 3 || stripHtmlTags(content)?.length < 8 || !topics?.length}
            onClick={() => createPost({ content, file: files[0], tags, title })}
          >
            {t('COMMUNITY.NETWORK.CREATE_CONVERSATION_MODAL.SEND')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default CreatePostModal;
