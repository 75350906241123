import MD5 from 'crypto-js/md5';
import { useNavigate } from 'react-router-dom';
import Talk from 'talkjs';

import { getUserName, TUser } from '../../_models';
import { useChatSessionContext } from '../_context';

/**
 * IMPORTANT!!! BE SURE THE CONVERSATION ID IS ALWAYS THE SAME FOR A GIVEN SET OF USERS
 * https://talkjs.com/docs/Reference/Concepts/Conversations.html
 */
export function generateConversationId(userIds: string[]): string {
  const longId = userIds.sort().reduce((acc, id) => `${acc}.${id}`, '');
  return MD5(longId).toString();
}

function createConversation({
  chatSession,
  title,
  users,
}: {
  chatSession: Talk.Session;
  title: string;
  users: TUser[];
}): Talk.ConversationBuilder {
  if (chatSession) {
    // "Register" the chat users
    const chatUsers = users.map(
      user =>
        new Talk.User({
          email: user.email,
          id: user.chatId,
          name: getUserName(user),
          role: 'attendee',
        }),
    );

    // Create the conversation
    const conversationId = generateConversationId([chatSession.me.id, ...chatUsers.map(user => user.id)]);
    const conversation = chatSession.getOrCreateConversation(conversationId);

    // Add participants
    conversation.subject = title;
    conversation.setParticipant(chatSession.me);
    chatUsers.forEach(user => conversation.setParticipant(user));

    return conversation;
  }
}

export function useCreateConversation() {
  const { chatSession, setConversationToSelectOnMount } = useChatSessionContext();
  const navigate = useNavigate();
  return {
    createConversation: ({ title, users }: { title: string; users: TUser[] }) => {
      const conversation = createConversation({ chatSession, title, users });
      setConversationToSelectOnMount(conversation);
      navigate('/community/conversations');
    },
  };
}
