import { FC } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../../_shared';
import { DATE_MONTH_FORMAT, formatISOString } from '../../../_utils/dateHelpers';
import { getLabelType } from '../../../_utils/eventHelpers';
import CardLabel from '../../../ec/card/components/CardLabel';
import { TEvent } from '../../_models';

import './item.scss';

type TProps = {
  event: TEvent;
};

const Item: FC<TProps> = ({ event }) => {
  const labelType = getLabelType(event?.startDate, event?.endDate);

  return (
    <Link className="event-item-link" to={`/events/${event?.id}`}>
      <img alt={event?.image?.name} className="event-item-link__image" src={event?.image?.url} />
      <div className="event-item-link__card">
        <CardLabel type={labelType} />
        <h3>{event?.title}</h3>
        <div className="event-item-link__card__date">
          <Icon name="calendar" size="xs" />
          <span>{formatISOString(event?.startDate, DATE_MONTH_FORMAT)}</span>
        </div>
      </div>
    </Link>
  );
};

export default Item;
