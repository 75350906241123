import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TUser } from '../../_models';
import { useEventContext } from '../../events/_context/EventContext';
import { TSpeakersQuery } from '../_models';

function getSpeakers(query: TSpeakersQuery, id: string): Promise<TUser[]> {
  Object.keys(query).forEach(key => {
    if (typeof query[key] === undefined || query[key] === '') {
      delete query[key];
    }
  });
  return HttpClient.get<TUser[]>(`speakers?${stringify(query)}`, {}, { 'X-Event-Id': id });
}

export function useGetSpeakers(query: TSpeakersQuery) {
  const { event } = useEventContext();
  return useQuery<TUser[], TApiError>(['speakers', query, event.id], () => getSpeakers(query, event.id));
}
