import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { THub } from '../_models';

function getHub(hubId: string, eventId: string): Promise<THub> {
  return HttpClient.get<THub>(`hubs/${hubId}`, {}, { 'X-Event-Id': eventId });
}

export function useGetHub() {
  const { hubId } = useParams<{ hubId: string }>();
  const navigate = useNavigate();
  const { event } = useEventContext();

  return useQuery<THub, TApiError>(['hub', hubId], () => getHub(hubId, event.id), {
    enabled: !!hubId,
    onError: () => navigate('/hubs'),
  });
}
