import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { sessionId: string };

function deleteFromFavorites({ sessionId }: TParams): Promise<void> {
  return HttpClient.delete<void>(`programme/favorites/${sessionId}`);
}

export function useDeleteFromFavorites() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TParams>('deleteFromFavorites', deleteFromFavorites, {
    onSuccess: () => {
      queryClient.invalidateQueries('programme');
      queryClient.invalidateQueries('live-sessions');
    },
  });
}
