import { useMutation } from 'react-query';

import { HttpClient, TApiError } from '../_http';
import { getUserName } from '../_models';
import { useAuthContext } from '../auth/_context';

type TCreateMeetingUrlResponse = {
  url: string;
};

function createMeetingUrl(): Promise<TCreateMeetingUrlResponse> {
  return HttpClient.post<TCreateMeetingUrlResponse>('video-meeting');
}

export default function useCreateMeetingUrl(calendlyUrl: string) {
  const { profile } = useAuthContext();

  return useMutation<TCreateMeetingUrlResponse, TApiError>('createMeetingUrl', createMeetingUrl, {
    onSuccess: videoUrl => {
      window.open(`${calendlyUrl}?email=${profile.email}&name=${getUserName(profile)}&a1=${videoUrl.url}`, '_blank');
    },
  });
}
