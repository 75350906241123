import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from '../../../_shared';
import { formatISOString } from '../../../_utils/dateHelpers';
import { TConversation } from '../../_models';

import './item.scss';

type TProps = {
  conversation: TConversation;
};

const Item: FC<TProps> = ({ conversation }) => {
  const { t } = useTranslation();

  function getImage() {
    if (conversation.participants.length > 1) return <Icon name="SvgUser" size={3} />;
    if (conversation.participants[0]?.image)
      return (
        <img
          alt={t('COMMUNITY.POSTS.PROFILE_IMAGE_ALT', {
            user: `${conversation.participants[0].firstName} ${conversation.participants[0].lastName}`,
          })}
          src={conversation.participants[0].image}
        />
      );
    return <Icon name="SvgUser" size={3} />;
  }

  return (
    <Link className="conversation-item-link" to="/community/conversations">
      {getImage()}
      <div className="conversation-item-link__card">
        <h3>
          {conversation.subject} {t('PROFILE.FROM')}
        </h3>
        {conversation.participants.map(({ firstName, lastName }) => (
          <h3 key={`${firstName}_${lastName}`}>
            {firstName} {lastName}
          </h3>
        ))}
        <div className="conversation-item-link__card__date">
          <Icon name="calendar" size="xs" />
          <span>{formatISOString(conversation.createdAt)}</span>
        </div>
      </div>
    </Link>
  );
};

export default Item;
