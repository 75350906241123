import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { About, Button, Documentation, ExternalResources, Icon, Modal, Speaker } from '../../../_shared';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import { formatISOString, DEFAULT_MONTH_STRING_FORMAT, BASE_TIME_STRING_FORMAT } from '../../../_utils/dateHelpers';
import AuthModal from '../../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../../auth/_context';
import { TSession } from '../../_models';
import { useAddToFavorites, useDeleteFromFavorites } from '../../_queries';

import './detailModal.scss';

type TProps = {
  session: TSession;
};

const DetailModal: FC<TProps> = ({ session }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();
  const { mutate: addToFavorites } = useAddToFavorites();
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites();

  function handleFavorites() {
    if (!isLoggedIn) ModalOpener.instance.open({ render: () => <AuthModal /> });
    else if (session.isFavorite) deleteFromFavorites({ sessionId: session.id });
    else addToFavorites({ sessionId: session.id });
    ModalOpener.instance.close();
  }

  return (
    <Modal title={t('EVENT.PROGRAMME.SESSION_DETAILS')}>
      <div className="detail-modal__content">
        <h3>{session.title}</h3>

        <div>
          <div>
            <Icon className="icon" name="calendar" />
            <span>{formatISOString(session.startTime, DEFAULT_MONTH_STRING_FORMAT)}</span>
          </div>
          <div>
            <Icon className="icon" name="SvgClock" />
            <span>{formatISOString(session.startTime, BASE_TIME_STRING_FORMAT)}</span>
            <span className="session__time__divider">-</span>
            <span>{formatISOString(session.endTime, BASE_TIME_STRING_FORMAT)}</span>
          </div>
        </div>

        <Button
          icon={session.isFavorite ? 'SvgFullHeart' : 'SvgEmptyHeart'}
          iconSize={1}
          onClick={handleFavorites}
          theme="secondary"
        >
          {t(session.isFavorite ? 'SHARED.BUTTONS.REMOVE_FROM_FAVOURITES' : 'SHARED.BUTTONS.ADD_TO_FAVOURITES')}
        </Button>

        <hr />

        <section>
          <h3>{t('EVENT.NAVIGATION.SPEAKERS')}</h3>
          {session.speakers?.map(speaker => (
            <Speaker key={speaker.id} speaker={speaker} />
          ))}
        </section>

        <About summary={session.summary} title={t('EVENT.LIVE_SESSIONS.ABOUT')} topics={session.topics} />
        {session.type !== 'WORKSHOP' && <Documentation documents={session?.documentation} />}
        {session.type !== 'WORKSHOP' && <ExternalResources links={session?.links} />}
      </div>
    </Modal>
  );
};

export default DetailModal;
