import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../../../_hooks';
import { Button, Spinner } from '../../../_shared';
import { useGetRelatedHubs } from '../../_queries';
import Item from '../item/Item';

import './relatedHubs.scss';

type TProps = {
  pathPrefix?: string;
  topics: string[];
};

const LIMIT = 2;

const RelatedHubs: FC<TProps> = ({ topics = [], pathPrefix = '' }) => {
  const { t } = useTranslation();
  const { data: hubs, isLoading } = useGetRelatedHubs(topics);
  const path = `${pathPrefix}../hubs/`;
  const [areAllVisible, toggleVisibility] = useToggle(false);

  return (
    <section className="related-hubs">
      <h4>{t('HUBS.RELATED.TITLE')}</h4>
      {isLoading ? (
        <Spinner />
      ) : hubs && hubs.length ? (
        <div>
          {hubs.slice(0, areAllVisible ? hubs.length : LIMIT).map(hub => (
            <Item hub={hub} key={hub?.id} pathPrefix={path} />
          ))}
        </div>
      ) : (
        <span className="related-hubs__empty">{t('HUBS.RELATED.EMPTY')}</span>
      )}
      {hubs?.length >= LIMIT && (
        <Button
          className="related-hubs__button"
          icon="corner-arrow"
          iconSize="xs"
          iconTransformation={areAllVisible ? undefined : 'rotate-180'}
          onClick={toggleVisibility}
          theme="ghost"
        >
          {areAllVisible ? t('SHARED.BUTTONS.SHOW_LESS') : t('SHARED.BUTTONS.SHOW_MORE')}
        </Button>
      )}
    </section>
  );
};

export default RelatedHubs;
