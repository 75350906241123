import { useQuery } from 'react-query';

import { HttpClient } from '../../_http';
import { TConversation } from '../_models';

function getConversations(): Promise<TConversation[]> {
  return HttpClient.get<TConversation[]>('conversations');
}

export function useGetConversations() {
  return useQuery('getConversations', getConversations);
}
