import { useEffect } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useScrollDirection, useScrollHeight, useToggle } from '../../_hooks';
import Button from '../button/Button';

import './backToTop.scss';

const BackToTop = () => {
  const [showButton, setShowButton] = useToggle(true);
  const [inFooter, setInFooter] = useToggle(true);

  const { t } = useTranslation();
  const scrollHeight = useScrollHeight();
  const scrollDirection = useScrollDirection();
  const OFFSET = 300;

  useEffect(() => {
    if (scrollHeight > OFFSET) {
      setShowButton(scrollDirection === 'up');
    } else {
      if (scrollHeight > 450) setShowButton(true);
      if (scrollHeight < 450) setShowButton(false);
    }

    const footer = document.getElementById('footer');
    setInFooter(window.scrollY > footer?.offsetTop - footer?.clientHeight);
  }, [scrollHeight]);

  if (!showButton) return null;
  return (
    <Button
      className={classNames('back-to-top', { 'in-footer': inFooter })}
      icon="SvgChevron"
      iconSize={1}
      onClick={() => document.getElementById('nav-menu').scrollIntoView({ behavior: 'smooth' })}
      theme="ghost"
    >
      {t('SHARED.BACK_TO_TOP.BACK')}
    </Button>
  );
};

export default BackToTop;
