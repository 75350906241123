import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getUserName, TUser } from '../../../_models';
import { Button, InputField, Modal } from '../../../_shared';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import { useCreateConversation } from '../../_hooks';

import './createConverationModal.scss';

type TProps = {
  setUsers?: (user: TUser[]) => void;
  users: TUser[];
};

const CreateConversationModal: FC<TProps> = ({ users, setUsers }) => {
  const { t } = useTranslation();
  const { createConversation } = useCreateConversation();
  const [title, setTitle] = useState<string>(t('COMMUNITY.NETWORK.CREATE_CONVERSATION_MODAL.ADD_TITLE'));
  const [selectedUsers, setSelectedUsers] = useState<TUser[]>(users);

  return (
    <Modal className="create-conversation-modal" title={t('COMMUNITY.NETWORK.SEND_MESSAGE')}>
      <h4>{t('COMMUNITY.NETWORK.SELECTED_PARTICIPANTS')}</h4>
      <div className="create-conversation-modal__selected-users">
        {selectedUsers.map(selectedUser => (
          <Button
            icon="SvgUserClose"
            iconSize={1}
            key={selectedUser.id}
            onClick={() => {
              const updatedUsers = selectedUsers.filter(user => user !== selectedUser);
              setSelectedUsers(updatedUsers);
              setUsers(updatedUsers);
            }}
          >
            {getUserName(selectedUser)}
          </Button>
        ))}
      </div>

      <InputField
        autoFocus
        label={t('COMMUNITY.NETWORK.CREATE_CONVERSATION_MODAL.TITLE_MESSAGE')}
        name="title"
        onChange={(title: string) => setTitle(title)}
        value={title}
      />

      <p className="create-conversation-modal__info">{t('COMMUNITY.NETWORK.CREATE_CONVERSATION_MODAL.INFO')}</p>

      <div className="create-conversation-modal__actions">
        <Button onClick={() => ModalOpener.instance.close()} theme="secondary">
          {t('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button onClick={() => createConversation({ title, users: selectedUsers })}>
          {t('COMMUNITY.NETWORK.CREATE_CONVERSATION_MODAL.SEND')}
        </Button>
      </div>
    </Modal>
  );
};

export default CreateConversationModal;
