import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Modal } from '../../../_shared';
import { Config } from '../../../config';
import { useAuthContext } from '../../_context';
import './authModal.scss';

const AuthModal: FC = () => {
  const { t } = useTranslation();
  const { loginRedirectUrl } = useAuthContext();

  return (
    <Modal title={t('AUTH.AUTH_MODAL.TITLE')}>
      <p>{t('AUTH.AUTH_MODAL.DESCRIPTION')}</p>

      <div className="auth-modal__actions">
        <Button href={loginRedirectUrl} theme="secondary">
          {t('SHARED.LOG_IN')}
        </Button>
        <Button href={Config.registerUrl} icon="SvgChevron" iconSize={1} theme="cta">
          {t('SHARED.REGISTER')}
        </Button>
      </div>
    </Modal>
  );
};

export default AuthModal;
