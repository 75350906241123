import { FC, createContext, useContext, ReactNode, useState, useEffect } from 'react';

import HmacSHA256 from 'crypto-js/hmac-sha256';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Talk from 'talkjs';
import { Subscription } from 'talkjs/all';

import { getUserName } from '../../_models';
import { useAuthContext } from '../../auth/_context';
import { Config } from '../../config';

type TChatSessionContext = {
  chatSession: Talk.Session;
  conversationToSelectOnMount?: Talk.ConversationBuilder;
  setConversationToSelectOnMount: (conversation: Talk.ConversationBuilder) => void;
  unreadCount: number;
};

const ChatSessionContext = createContext<TChatSessionContext>({
  chatSession: null,
  setConversationToSelectOnMount: () => {},
  unreadCount: 0,
});

export const useChatSessionContext = () => useContext(ChatSessionContext);

type TProps = {
  children: ReactNode;
};

export const ChatSessionContextProvider: FC<TProps> = ({ children }) => {
  const [chatSession, setChatSession] = useState<Talk.Session>();
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [conversationToSelectOnMount, setConversationToSelectOnMount] = useState<Talk.ConversationBuilder>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const { isLoggedIn, profile } = useAuthContext();
  const { t } = useTranslation();

  const unreadChangeHandler = messages => setUnreadCount(messages.length);

  const newMessageHandler = (message: Talk.Message) => {
    // Show a toast message when receiving a videocall invitation
    if (message.custom.type === 'VIDEOCALL_INVITATION' && message.custom.authorId !== profile.chatId) {
      toast.info(t('COMMUNITY.CONVERSATIONS.TOASTS.VIDEOCALL_INVITATION', { name: message.custom.authorName }), {
        autoClose: false,
        bodyStyle: { cursor: 'pointer' },
        closeOnClick: true,
        onClick: () => window.open(message.custom.url, '_blank'),
      });
    }
  };

  async function initializeChatSession() {
    await Talk.ready;

    // Create chat session
    const session = new Talk.Session({
      appId: Config.talkJs.appId,
      me: new Talk.User({
        email: profile.email,
        id: profile.chatId,
        name: getUserName(profile),
        role: 'attendee',
      }),
      signature: HmacSHA256(profile.chatId, Config.talkJs.secret).toString(),
    });

    setSubscriptions(prevSubscriptions => [
      ...prevSubscriptions,
      // Listen on unread count changes
      session.unreads.onChange(unreadChangeHandler),
      // Listen on new (system) messages
      session.onMessage(newMessageHandler),
    ]);

    setChatSession(session);
  }

  function destroyChatSession() {
    subscriptions.forEach(subscription => subscription.unsubscribe());
    chatSession?.destroy();
  }

  useEffect(() => {
    if (isLoggedIn) initializeChatSession();
    else destroyChatSession();
    return () => destroyChatSession();
  }, [isLoggedIn]);

  return (
    <ChatSessionContext.Provider
      value={{
        chatSession,
        conversationToSelectOnMount,
        setConversationToSelectOnMount,
        unreadCount,
      }}
    >
      {children}
    </ChatSessionContext.Provider>
  );
};
