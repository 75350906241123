import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Sort } from '../../../_models';
import { Markdown, Picture, UserSwiper, Title, Person } from '../../../_shared';
import { useAuthContext } from '../../../auth/_context';
import CardTag from '../../../ec/card/components/CardTag';
import { useGetParticipants } from '../../../participants/_queries';
import { useGetSpeakers } from '../../../speakers/_queries';
import { useEventContext } from '../../_context/EventContext';
import { Tab } from '../../_models';
import AttendInfo from '../attendInfo/AttendInfo';
import OrganiserInfo from '../organiserInfo/OrganiserInfo';

import './detailOverview.scss';

type TLink = {
  description: string;
  tab: Tab;
  title: string;
  to: string;
};

const DetailOverview: FC = () => {
  const { t } = useTranslation();
  const { event } = useEventContext();
  const { profile } = useAuthContext();
  const { data: speakers, isLoading: speakersLoading } = useGetSpeakers({
    channelId: '',
    date: '',
    sort: Sort.Alphabetical,
  });
  const { data: participants, isLoading: participantsLoading } = useGetParticipants({
    sort: Sort.Alphabetical,
    ...(profile?.interests && { topics: profile.interests }),
  });

  const getLinks = (): TLink[] => {
    const links: TLink[] = [
      {
        description: t('EVENT.OVERVIEW.PROGRAMME_DESCRIPTION'),
        tab: 'PROGRAMME',
        title: t('EVENT.OVERVIEW.PROGRAMME_TITLE'),
        to: '../programme',
      },
      {
        description: t('EVENT.OVERVIEW.LIVE_SESSIONS_DESCRIPTION'),
        tab: 'LIVESESSION',
        title: t('EVENT.OVERVIEW.LIVE_SESSIONS_TITLE'),
        to: '../live-sessions',
      },
      {
        description: t('EVENT.OVERVIEW.HUBS_DESCRIPTION'),
        tab: 'HUBS',
        title: t('HUBS.OVERVIEW.VISIT_THE_HUBS'),
        to: '../hubs',
      },
      {
        description: t('EVENT.OVERVIEW.PROJECTS_DESCRIPTION'),
        tab: 'PROJECTS',
        title: t('EVENT.OVERVIEW.PROJECTS_TITLE'),
        to: '../projects',
      },
    ];
    return links.filter(link => !event.hiddenTabs?.includes(link.tab));
  };

  return (
    <main className="event-detail-overview">
      <section className="ecl-container">
        <Title>{t('EVENT.NAVIGATION.OVERVIEW')}</Title>

        <div className="event-detail-overview__about">
          <div>
            <h2>{t('EVENT.OVERVIEW.ABOUT_EVENT')}</h2>
            <Markdown value={event.description?.json} />
            <h3>{t('SHARED.TOPICS')}</h3>
            <div className="event-detail-overview__about__topics">
              {event.topics.map(topic => (
                <CardTag key={topic} tag={topic} />
              ))}
            </div>
          </div>

          <Picture alt={event.image.name} label={event.title} url={event.image.url} />
        </div>
      </section>

      <UserSwiper
        emptyLabel={t('SPEAKERS.NO_SPEAKERS')}
        isLoading={speakersLoading}
        renderSlides={(speakers || []).map(speaker => (
          <Person key={speaker.id} user={speaker} />
        ))}
        title={t('EVENT.OVERVIEW.MEET_THE_SPEAKERS')}
      />

      <section className="event-detail-overview__links ecl-container">
        {getLinks().map(link => (
          <Link key={link.to} to={link.to}>
            <h4>{link.title}</h4>
            <p>{link.description}</p>
          </Link>
        ))}
      </section>

      <UserSwiper
        emptyLabel={t('PARTICIPANTS.NO_PARTICIPANTS')}
        isLoading={participantsLoading}
        renderSlides={(participants || []).map(participant => (
          <Person key={participant.id} user={participant} />
        ))}
        title={t('EVENT.OVERVIEW.PARTICIPANTS_PICK')}
      />

      <AttendInfo />
      <OrganiserInfo organiser={event?.organiser} />
    </main>
  );
};

export default DetailOverview;
