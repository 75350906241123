import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useCreateMeetingUrl } from '../../_hooks';
import AuthModal from '../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../auth/_context';
import Button from '../button/Button';
import { ModalOpener } from '../modal/ModalOpener';

import './bookAMeetingButton.scss';

type TProps = {
  calendlyUrl: string;
};

const BookAMeetingButton: FC<TProps> = ({ calendlyUrl }) => {
  const { isLoggedIn } = useAuthContext();
  const { mutate: createMeetingUrl } = useCreateMeetingUrl(calendlyUrl);
  const { t } = useTranslation();

  function handleBookAMeeting() {
    if (!isLoggedIn) {
      ModalOpener.instance.close();
      ModalOpener.instance.open({ render: () => <AuthModal /> });
      return;
    }
    createMeetingUrl();
  }

  return (
    <Button onClick={handleBookAMeeting} theme="secondary">
      {t('COMMUNITY.NETWORK.BOOK_A_MEETING')}
    </Button>
  );
};

export default BookAMeetingButton;
