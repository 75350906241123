import { FC } from 'react';

import { getUserName, TUser } from '../../_models';
import AuthModal from '../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../auth/_context';
import { useAddToNetwork, useRemoveFromNetwork } from '../../network/_queries';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import { Checkbox } from '../input';
import { ModalOpener } from '../modal/ModalOpener';
import UserModal from '../userModal/UserModal';

import './person.scss';

type TProps = {
  isSelected?: boolean;
  onSelectChange?: () => void;
  user: TUser;
};

const Person: FC<TProps> = ({ user, onSelectChange, isSelected }) => {
  const { isLoggedIn } = useAuthContext();
  const { mutate: addToNetwork } = useAddToNetwork();
  const { mutate: removeFromNetwork } = useRemoveFromNetwork();

  const userIcon = () => {
    if (user.isConnection) return 'SvgUserAdded';
    else return 'SvgUserAdd';
  };

  function handleToggleNetwork() {
    if (!isLoggedIn) return ModalOpener.instance.open({ render: () => <AuthModal /> });
    else if (user.isConnection) removeFromNetwork({ connectionId: user.id });
    else addToNetwork({ connectionId: user.id });
  }

  const getUserOrganisation = () => {
    if (user?.organisation?.length > 40) {
      return `${user.organisation.slice(0, 40)}...`;
    } else return user.organisation;
  };

  return (
    <div className="person">
      {onSelectChange && user?.canContact && <Checkbox checked={isSelected} name="person" onChange={onSelectChange} />}
      <Button className="person__network-icon" icon={userIcon()} iconSize="xl" onClick={handleToggleNetwork} theme="ghost" />
      <Button
        className="person__container"
        onClick={() => {
          ModalOpener.instance.open({
            render: () => <UserModal user={user} />,
          });
        }}
        theme="wrapper"
      >
        {user?.image ? <img alt="speaker-image" src={user.image} /> : <Icon name="SvgUser" size={5} />}
        <p className="person__name">{getUserName(user)}</p>
        <p className="person__organisation">{getUserOrganisation() || ' '}</p>
      </Button>
    </div>
  );
};
export default Person;
