export type TNotification = {
  author?: TAuthor;
  createdAt: string;
  data: {
    eventId?: number;
    postId?: number;
  };
  id: string;
  read: boolean;
  seen: boolean;
  type: NotificationType;
};

export type TAuthor = {
  firstName: string;
  id: string;
  image?: string;
  lastName: string;
  organisation: string;
};

export enum NotificationType {
  ActionFromNetworkAttendingEvent = 'ACTION_FROM_NETWORK_ATTENDING_EVENT',
  ActionFromNetworkInterestedEvent = 'ACTION_FROM_NETWORK_INTERESTED_IN_EVENT',
  AddedToNetwork = 'ADDED_TO_NETWORK',
  CommunityLike = 'ACTION_FROM_NETWORK_COMMUNITY_COMMENT',
  CommunityVote = 'ACTION_FROM_NETWORK_COMMUNITY_VOTE',
  EventInOneDay = 'EVENT_IN_ONE_DAY',
  EventInOneHour = 'EVENT_IN_ONE_HOUR',
  InvitedToEvent = 'INVITED_TO_EVENT',
  NewEvent = 'NEW_EVENT',
  NewMessage = 'NEW_MESSAGE',
  NewSpeaker = 'NEW_SPEAKER',
  SuggestedPosts = 'SUGGESTED_POSTS',
  SuggestedUsers = 'SUGGESTED_USERS',
}
