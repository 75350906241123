import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { connectionId: string };

function removeFromNetwork({ connectionId }: TParams): Promise<void> {
  return HttpClient.delete<void>(`network/${connectionId}/remove`);
}

export function useRemoveFromNetwork() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TParams>('removeFromNetwork', removeFromNetwork, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('speakers');
      queryClient.invalidateQueries('participants');
    },
  });
}
