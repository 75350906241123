import axios from 'axios';
import { useMutation } from 'react-query';

import { useAuthContext } from '../auth/_context/AuthContext';
import { Config } from '../config';

type TProps = {
  bookAMeetingId: string;
  email: string;
  firstName: string;
  lastName: string;
};

function goToBookAMeeting(profile: TProps): Promise<{ data: { token: string } }> {
  return axios.post('https://api.meeting.icapps-projects.com/api/connection', profile, {
    headers: {
      appapikey: Config.bookAMeetingConfig.appapikey,
      appid: Config.bookAMeetingConfig.appid,
    },
  });
}

export function useGoToBookAMeeting(path: string) {
  const { profile } = useAuthContext();

  return useMutation(
    'toBookAMeeting',
    () =>
      goToBookAMeeting({
        bookAMeetingId: profile.bookAMeetingId,
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
      }),
    {
      onSuccess: ({ data }) => {
        window.open(`${Config.bookAMeetingUrl}${path}?token=${data.token}`, '_blank');
      },
    },
  );
}
