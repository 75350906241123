import { FC, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Route, Routes, Navigate } from 'react-router-dom';

import { useToggle } from '../_hooks';
import { useAuthContext } from '../auth/_context';
import { Config } from '../config';
import Conversations from '../conversations/Conversations';
import { Menu } from '../ec/menu/Menu';
import Network from '../network/Network';
import Detail from '../posts/detail/Detail';
import Overview from '../posts/overview/Overview';

import About from './about/About';
import './communityRoutes.scss';

const CommunityRoutes: FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const getRoutes = useCallback(() => {
    const routes = [
      { label: t('COMMUNITY.NAVIGATION.ABOUT'), link: 'about', order: 1 },
      { label: t('COMMUNITY.NAVIGATION.NETWORK'), link: 'network', order: 3 },
      { label: t('COMMUNITY.NAVIGATION.CONVERSATIONS'), link: 'conversations', order: 4 },
    ];
    if (isLoggedIn && Config.showCommunity) routes.push({ label: t('COMMUNITY.NAVIGATION.POSTS'), link: 'posts', order: 2 });
    return routes;
  }, [isLoggedIn]);

  return (
    <>
      <Menu
        className="community-navigation"
        isMenuOpen={isMenuOpen}
        routes={getRoutes().sort((a, b) => a.order - b.order)}
        toggleMenu={toggleMenu}
      />
      <Routes>
        <Route element={<About />} path="about" />
        {Config.showCommunity && isLoggedIn && <Route element={<Overview />} path="posts" />}
        {Config.showCommunity && isLoggedIn && <Route element={<Detail />} path="posts/:id" />}
        <Route element={<Network />} path="network" />
        <Route element={<Conversations />} path="conversations" />
        <Route element={<Navigate to="about" />} path="*" />
      </Routes>
    </>
  );
};

export default CommunityRoutes;
