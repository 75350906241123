import { FC, ReactNode } from 'react';

import { useResponsive } from '../../_hooks';
import SwiperCarousel from '../../ec/carousel/SwiperCarousel';
import Spinner from '../spinner/Spinner';

import './userSwiper.scss';

type TProps = {
  emptyLabel: string;
  isLoading?: boolean;
  renderSlides: ReactNode[];
  title: string;
};

const UserSwiper: FC<TProps> = ({ emptyLabel, renderSlides, title, isLoading = false }) => {
  const { Responsive } = useResponsive();

  function getSlidesPerView(): number {
    if (Responsive.isBiggerThan('monitor')) return 4;
    if (Responsive.isBiggerThan('desktop')) return 3;
    if (Responsive.isBiggerThan('tablet')) return 2;
    return 1;
  }

  if (isLoading) return <Spinner />;
  return (
    <section className="user-swiper">
      <div className="ecl-container">
        {title && <h2>{title}</h2>}
        <SwiperCarousel emptyLabel={emptyLabel} slides={renderSlides} slidesPerView={getSlidesPerView()} spaceBetween={16} />
      </div>
    </section>
  );
};

export default UserSwiper;
