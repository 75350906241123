import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { connectionId: string };

function addToNetwork({ connectionId }: TParams): Promise<void> {
  return HttpClient.post<void>(`network/${connectionId}/add`);
}

export function useAddToNetwork() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TParams>('addToNetwork', addToNetwork, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('speakers');
      queryClient.invalidateQueries('participants');
    },
  });
}
