import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { HttpClient, TApiError } from '../../_http';
import { TEvent } from '../_models';

function getEvent(id: string): Promise<TEvent> {
  return HttpClient.get<TEvent>(`events/${id}`);
}

export function useGetEvent(eventID?: string) {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  return useQuery<TEvent, TApiError>(['event', id, eventID], () => getEvent(id ?? eventID), {
    enabled: !!(id || eventID),
    onError: () => navigate('/events'),
  });
}
