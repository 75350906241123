import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Circle, Title } from '../../../_shared';

import './steps.scss';

const Steps: FC = () => {
  const { t } = useTranslation();
  const steps = [
    {
      description: t('HOW_IT_WORKS.BECOME_MEMBER.DESCRIPTION'),
      title: t('HOW_IT_WORKS.BECOME_MEMBER.TITLE'),
    },
    {
      description: t('HOW_IT_WORKS.EXPLORE_AND_CONTRIBUTE.DESCRIPTION'),
      title: t('HOW_IT_WORKS.EXPLORE_AND_CONTRIBUTE.TITLE'),
    },
    {
      description: t('HOW_IT_WORKS.ATTEND_EVENTS.DESCRIPTION'),
      title: t('HOW_IT_WORKS.ATTEND_EVENTS.TITLE'),
    },
    {
      description: t('HOW_IT_WORKS.NETWORK_WITH_THE_COMMUNITY.DESCRIPTION'),
      title: t('HOW_IT_WORKS.NETWORK_WITH_THE_COMMUNITY.TITLE'),
    },
  ];

  return (
    <section className="steps">
      <div className="ecl-container">
        <Title>{t('SHARED.NAVIGATION.HOW_IT_WORKS')}</Title>
        {steps.map(({ title, description }, index) => (
          <div className="steps__single" key={title}>
            <Circle>{index + 1}</Circle>
            <div className="steps__single__content">
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Steps;
