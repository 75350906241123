import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import './cardLabel.scss';

export enum CardLabelType {
  ComingSoon = 'comingsoon',
  Featured = 'featured',
  Live = 'live',
  Rewatch = 'rewatch',
}

type TProps = {
  theme?: 'light' | 'dark';
  type: CardLabelType;
};

const CardLabel: FC<TProps> = ({ type, theme = 'light' }) => {
  const { t } = useTranslation();
  const label = useMemo(() => {
    switch (type) {
      case CardLabelType.ComingSoon:
        return t('EVENTS.COMING_SOON');
      case CardLabelType.Live:
        return t('EVENTS.LIVE');
      case CardLabelType.Rewatch:
        return t('EVENTS.REWATCH');
      case CardLabelType.Featured:
        return t('EVENTS.FEATURED');
    }
  }, [type]);

  return <span className={`ecl-label ecl-card__label ecl-card__label__${type} ecl-card__label__${theme}`}>{label}</span>;
};

export default CardLabel;
