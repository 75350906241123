import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Inbox } from 'talkjs/all';

import { HttpClient, TApiError } from '../../_http';

type TParams = { conversationId: string };

function leaveConversation({ conversationId }: TParams): Promise<void> {
  return HttpClient.post<void>(`conversations/${conversationId}/leave`);
}

export function useLeaveConversation(chatInbox: Inbox) {
  const { t } = useTranslation();
  return useMutation<void, TApiError, TParams>('leaveConversation', leaveConversation, {
    onSuccess: () => {
      // Reset selected state for inbox
      chatInbox.select(null);
      toast.info(t('COMMUNITY.CONVERSATIONS.TOASTS.CONVERSATION_LEFT'));
    },
  });
}
