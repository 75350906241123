import { useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../_http';

function unLikePost(id: string): Promise<void> {
  return HttpClient.delete(`community/posts/${id}/vote`);
}

export function useUnLikePost(id: string) {
  const queryClient = useQueryClient();

  return useMutation('unLikePosts', () => unLikePost(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPosts']);
      queryClient.invalidateQueries(['getPost']);
    },
  });
}
