import { FC } from 'react';

import { Link } from 'react-router-dom';

import EUFlag from '../../../_assets/images/eu-flag.jpg';
import { Icon } from '../../../_shared';
import { formatISOString } from '../../../_utils/dateHelpers';
import { TPost } from '../../_models';
import './item.scss';

type TProps = {
  post: TPost;
};

const Item: FC<TProps> = ({ post }) => (
  <Link className="post-item-link" to={`/community/posts/${post.id}`}>
    <img alt="" crossOrigin="" src={post?.thumbnail || EUFlag} />
    <div className="post-item-link__card">
      <h3>{post.title}</h3>
      <div className="post-item-link__card__date">
        <Icon name="calendar" size="xs" />
        <span>{formatISOString(post.publishDate)}</span>
      </div>
    </div>
  </Link>
);
export default Item;
