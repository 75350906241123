import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import EngagingInsightsImage from '../../../_assets/images/engaging-insights.jpg';
import InspiringEventsImage from '../../../_assets/images/inspiring-events.jpg';
import NewConnectionsImage from '../../../_assets/images/new-connections.jpg';
import { Title } from '../../../_shared';

import './communityPlatform.scss';

const CommunityPlatform: FC = () => {
  const { t } = useTranslation();
  const blocks = [
    {
      description: t('HOW_IT_WORKS.WHAT_YOU_FIND_ON_COMMUNITY_PLATFORM.INSPIRING_EVENTS.DESCRIPTION'),
      picture: InspiringEventsImage,
      title: t('HOW_IT_WORKS.WHAT_YOU_FIND_ON_COMMUNITY_PLATFORM.INSPIRING_EVENTS.TITLE'),
    },
    {
      description: t('HOW_IT_WORKS.WHAT_YOU_FIND_ON_COMMUNITY_PLATFORM.ENGAGING_INSIGHTS.DESCRIPTION'),
      picture: EngagingInsightsImage,
      title: t('HOW_IT_WORKS.WHAT_YOU_FIND_ON_COMMUNITY_PLATFORM.ENGAGING_INSIGHTS.TITLE'),
    },
    {
      description: t('HOW_IT_WORKS.WHAT_YOU_FIND_ON_COMMUNITY_PLATFORM.NEW_CONNECTIONS.DESCRIPTION'),
      picture: NewConnectionsImage,
      title: t('HOW_IT_WORKS.WHAT_YOU_FIND_ON_COMMUNITY_PLATFORM.NEW_CONNECTIONS.TITLE'),
    },
  ];

  return (
    <section className="community-platform ecl-container">
      <Title>{t('HOW_IT_WORKS.WHAT_YOU_FIND_ON_COMMUNITY_PLATFORM.TITLE')}</Title>
      <div className="community-platform__blocks">
        {blocks.map(({ description, picture, title }) => (
          <div className="community-platform__block" key={title}>
            <img alt="" src={picture} />
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default CommunityPlatform;
