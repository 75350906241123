import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useResponsive } from '../../_hooks';
import Button from '../button/Button';
import { ModalOpener } from '../modal/ModalOpener';
import './filterBar.scss';

type TProps = {
  children: ReactNode;
  className?: string;
  clearFilters: () => void;
};

const Aside: FC<TProps> = ({ children, clearFilters, className }) => {
  const { t } = useTranslation();

  return (
    <aside className={classNames('filter-bar', className)}>
      <div className="filter-bar__title">
        <h3>{t('SHARED.FILTERS')} -</h3>
        <button onClick={clearFilters}>{t('SHARED.BUTTONS.CLEAR_ALL')}</button>
      </div>
      {children}
    </aside>
  );
};

const FilterBar: FC<TProps> = ({ children, clearFilters }) => {
  const { t } = useTranslation();
  const { Responsive } = useResponsive();

  if (Responsive.isSmallerThan('desktop'))
    return (
      <Button
        onClick={() =>
          ModalOpener.instance.open({
            render: () => (
              <Aside className="filter-bar__modal" clearFilters={clearFilters}>
                <Button hideLabel icon="close" onClick={() => ModalOpener.instance.close()} theme="ghost">
                  {t('SHARED.BUTTONS.CLOSE')}
                </Button>
                {children}
              </Aside>
            ),
          })
        }
      >
        {t('SHARED.BUTTONS.FILTER')}
      </Button>
    );
  return <Aside clearFilters={clearFilters}>{children}</Aside>;
};

export default FilterBar;
