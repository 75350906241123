import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Spinner, Title } from '../../../_shared';
import { useGetFAQ } from '../../_queries';
import FAQ from '../FAQ/FAQ';

import './FAQOverview.scss';

const FAQOverview: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetFAQ();

  if (!data && !isLoading) return null;
  return (
    <section className="ecl-container faq-overview">
      <Title>{t('HOW_IT_WORKS.FAQ')}</Title>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {data?.map(faq => (
            <FAQ faq={faq} key={faq.id} />
          ))}
        </>
      )}
    </section>
  );
};
export default FAQOverview;
