import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { THighlight } from '../_models';

function getHighlights(): Promise<THighlight[]> {
  return HttpClient.get<THighlight[]>('highlights');
}

export function useGetHighlights() {
  return useQuery<THighlight[], TApiError>('highlights', () => getHighlights());
}
