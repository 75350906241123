import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import './player.scss';

type TProps = {
  link: string;
};

const Player: FC<TProps> = ({ link }) => {
  const { t } = useTranslation();

  return (
    <div className="player">
      {link ? (
        <iframe
          allowFullScreen
          src={`https://europa.eu/webtools/crs/iframe/?oriurl=${link}`}
          title={t('EVENT.LIVE_SESSIONS.PLAYER')}
        />
      ) : (
        <span>{t('EVENT.LIVE_SESSIONS.NO_SESSION')}</span>
      )}
    </div>
  );
};
export default Player;
