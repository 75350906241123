import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../button/Button';
import './favoriteButton.scss';

type TProps = {
  isFavorite: boolean;
  onClick: () => void;
};

const FavoritesButton: FC<TProps> = ({ isFavorite, onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      className="favorite-button"
      icon={isFavorite ? 'SvgFullHeart' : 'SvgEmptyHeart'}
      iconSize={0.8}
      onClick={onClick}
      stopPropagation
      theme="ghost"
    >
      {t(isFavorite ? 'SHARED.BUTTONS.REMOVE_FROM_FAVOURITES' : 'SHARED.BUTTONS.ADD_TO_FAVOURITES')}
    </Button>
  );
};
export default FavoritesButton;
