import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useToggle } from '../../../_hooks';
import { Button, Spinner } from '../../../_shared';
import { useGetHubs } from '../../_queries';
import './navigation.scss';

const LIMIT = 5;

const Detail: FC = () => {
  const { t } = useTranslation();
  const { hubId } = useParams<{ hubId: string }>();
  const { data: hubs, isLoading: isGetHubsLoading } = useGetHubs();
  const [allHubsVisible, toggleVisibility] = useToggle(false);

  return (
    <nav className="hub-detail__navigation">
      <span>{t('HUBS.DETAIL.QUICKLY_JUMP_TO')}</span>
      <ul>
        {isGetHubsLoading ? (
          <Spinner />
        ) : (
          (hubs || [])
            .filter((hub, i) => hub.id !== hubId && (allHubsVisible || i <= LIMIT))
            .map(hubLink => (
              <li key={hubLink.id}>
                <Button href={`../${hubLink.id}`} theme="ghost">
                  {hubLink.title}
                </Button>
              </li>
            ))
        )}
      </ul>
      {(hubs || []).length > LIMIT && (
        <Button
          icon="corner-arrow"
          iconSize="xs"
          iconTransformation={allHubsVisible ? undefined : 'flip-vertical'}
          onClick={toggleVisibility}
          theme="ghost"
        >
          {allHubsVisible ? t('SHARED.BUTTONS.SHOW_LESS') : t('SHARED.BUTTONS.SHOW_MORE')}
        </Button>
      )}
    </nav>
  );
};

export default Detail;
