import { FC, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useLogin } from '../../../_queries';
import { Spinner } from '../../../_shared';

const Callback: FC = () => {
  const { mutate: login } = useLogin();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    login({ code: searchParams.get('code'), verifier: searchParams.get('state').split(',')[0] });
  }, []);

  return <Spinner />;
};

export default Callback;
