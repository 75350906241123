import { FC } from 'react';

import { Icon } from '..';
import { TUser } from '../../_models';
import { ModalOpener } from '../modal/ModalOpener';
import UserModal from '../userModal/UserModal';
import './speaker.scss';

type TProps = {
  speaker: TUser;
};

const Speaker: FC<TProps> = ({ speaker }) => (
  <div className="speaker" key={speaker.firstName}>
    <div className="image-container">{speaker.image ? <img alt="" src={speaker.image} /> : <Icon name="SvgUser" size={3} />}</div>
    <div className="speaker__content">
      <button
        onClick={() =>
          ModalOpener.instance.open({
            render: () => <UserModal user={speaker} />,
          })
        }
      >{`${speaker.firstName} ${speaker.lastName}`}</button>
      {speaker.position && speaker.organisation && <span>{`${speaker.position} | ${speaker.organisation}`}</span>}
    </div>
  </div>
);
export default Speaker;
