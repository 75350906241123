import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { HttpClient, TApiError } from '../../_http';

type TParams = { webinarId: string };
type TResponse = { url: string };

function enterWebex({ webinarId }: TParams): Promise<TResponse> {
  return HttpClient.get<TResponse>(`webinars/${webinarId}/enter`);
}

export function useEnterWebex() {
  const { t } = useTranslation();

  return useMutation<TResponse, TApiError, TParams>(['enterWebex'], enterWebex, {
    onError: error => {
      if (error.message === 'WEBINAR_NOT_IN_PROGRESS') {
        toast.error(error.error);
      } else {
        toast.error(t('EVENT.PROGRAMME.NO_SESSIONS'));
      }
    },
    onSuccess: response => {
      if (response?.url) window.open(response?.url, '_blank');
    },
  });
}
