import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { JoinPlatform, Person, UserSwiper } from '../_shared';
import { useAuthContext } from '../auth/_context';
import { IntroCommunity } from '../community/_components';
import { FeaturedEvents } from '../events/_components';
import ExternalHighlights from '../externalHighlights/ExternalHighlights';
import { CommunityPlatform } from '../howItWorks/_components';
import { useGetUsers } from '../network/_queries';

import { Highlights } from './_components';

const Home: FC = () => {
  const { t } = useTranslation();
  const { profile } = useAuthContext();
  const { data: users, isLoading } = useGetUsers({ interests: profile?.interests });

  return (
    <main className="home">
      <Helmet>
        <title>{t('SHARED.NAVIGATION.HOME')}</title>
      </Helmet>
      <Highlights />
      <FeaturedEvents />
      <IntroCommunity />
      {profile && (
        <UserSwiper
          emptyLabel={t('COMMUNITY.NETWORK.EMPTY')}
          isLoading={isLoading}
          renderSlides={(users?.data || []).map(user => (
            <Person key={user.id} user={user} />
          ))}
          title={t('COMMUNITY.NETWORK.FEATURED_MEMBERS')}
        />
      )}
      <JoinPlatform />
      <CommunityPlatform />
      <ExternalHighlights />
    </main>
  );
};

export default Home;
