import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { getLabelType } from '../../_utils/eventHelpers';
import AuthModal from '../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../auth/_context';
import { CardLabelType } from '../../ec/card/components/CardLabel';
import { SessionType, TLiveSession, TSession, TWorkshopSession } from '../../sessions/_models';
import { useEnterWebex } from '../../workshops/_queries';
import Button from '../button/Button';
import { ModalOpener } from '../modal/ModalOpener';

type TProps = {
  session: TSession;
};

const ActionButton: FC<TProps> = ({ session }) => {
  const { t } = useTranslation();
  const { mutate: webex } = useEnterWebex();
  const { isLoggedIn } = useAuthContext();

  function workshopButton(): JSX.Element {
    const workshop = session as TWorkshopSession;

    switch (getLabelType(workshop.startTime, workshop.endTime)) {
      case CardLabelType.Live:
        return (
          <Button
            disabled={!workshop?.webexId}
            onClick={() =>
              isLoggedIn ? webex({ webinarId: workshop.webexId }) : ModalOpener.instance.open({ render: () => <AuthModal /> })
            }
            theme="cta"
          >
            {t('EVENT.OVERVIEW.JOIN_NOW')}
          </Button>
        );
      case CardLabelType.Rewatch:
        return (
          workshop?.recordingUrl && (
            <Button onClick={() => window.open(workshop.recordingUrl, '_blank')} theme="cta">
              {t('EVENTS.REWATCH')}
            </Button>
          )
        );
      default:
        return null;
    }
  }

  function liveSessionButton(): JSX.Element {
    const livesession = session as TLiveSession;

    switch (getLabelType(livesession.startTime, livesession.endTime)) {
      case CardLabelType.Live:
        return (
          livesession.channelId && (
            <Button href={`../live-sessions?channelId=${livesession?.channelId}`} icon="SvgLive" iconSize={1.1} theme="cta">
              {t('EVENT.PROGRAMME.WATCH_LIVE')}
            </Button>
          )
        );
      case CardLabelType.Rewatch:
        return (
          livesession?.recordingUrl && (
            <Button href={livesession.recordingUrl} icon="SvgLive" iconSize={1.1} theme="primary">
              {t('EVENT.PROGRAMME.WATCH_REPLAY')}
            </Button>
          )
        );
      default:
        return null;
    }
  }

  if (session.type === SessionType.Live) return liveSessionButton();
  if (session.type === SessionType.Workshop) return workshopButton();
  return null;
};
export default ActionButton;
