import { useQuery } from 'react-query';

import { TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TProgrammeQuery } from '../../programme/_models';
import { getProgramme } from '../../programme/_queries';
import { TLiveSession } from '../_models';

export function useGetLiveSessions(query: TProgrammeQuery) {
  const { event } = useEventContext();
  return useQuery<TLiveSession[], TApiError>(
    ['live-sessions', query, event.id],
    () => getProgramme<TLiveSession>(query, event.id),
    {
      enabled: !!query.channelId,
    },
  );
}
