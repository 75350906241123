import { useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../_http';

function likeComment(postId: string, commentId: string): Promise<void> {
  return HttpClient.post(`community/posts/${postId}/comments/${commentId}/vote`);
}

export function useLikeComment(postId: string) {
  const queryClient = useQueryClient();

  return useMutation('likeComment', (commentId: string) => likeComment(postId, commentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPost']);
    },
  });
}
