import { FC, useEffect, useState } from 'react';

import { addMinutes, differenceInMilliseconds } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { About, Button, Documentation, ExternalResources, Icon, Player, Speaker, Spinner, Tabbar, Title } from '../../_shared';
import { ModalOpener } from '../../_shared/modal/ModalOpener';
import {
  BASE_TIME_STRING_FORMAT,
  DEFAULT_MONTH_STRING_FORMAT,
  DEFAULT_YEAR_STRING_FORMAT,
  formatDate,
  formatISOString,
  isAfterDate,
  TIMEZONE_FORMAT,
} from '../../_utils/dateHelpers';
import AuthModal from '../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../auth/_context';
import Chatroom from '../../chat/Chatroom';
import { NextSession } from '../../events/_components';
import { Slido } from '../../events/_components/slido/Slido';
import { RelatedHubs } from '../../hubs/_components';
import { TProgrammeQuery } from '../../programme/_models';
import { RelatedProjects } from '../../projects/_components';
import { SessionType, TLiveSession } from '../_models';
import { useAddToFavorites, useDeleteFromFavorites, useGetChannels, useGetLiveSessions } from '../_queries';

import './livesessions.scss';

const LiveSessions: FC = () => {
  const { isLoggedIn } = useAuthContext();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const channelId = searchParams.get('channelId');

  const [query, setQuery] = useState<TProgrammeQuery>({
    channelId,
    date: formatDate(new Date(), DEFAULT_YEAR_STRING_FORMAT),
    types: [SessionType.Live],
  });
  const [activeSession, setActiveSession] = useState<TLiveSession>();
  const [nextSession, setNextSession] = useState<TLiveSession>();

  const { data: channels, isLoading: channelsLoading } = useGetChannels();
  const { data: sessions, isLoading: sessionsLoading } = useGetLiveSessions(query);
  const { mutate: addToFavorites } = useAddToFavorites();
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites();

  function handleFavorites(id: string, isFavorite: boolean) {
    if (!isLoggedIn) return ModalOpener.instance.open({ render: () => <AuthModal /> });
    if (isFavorite) deleteFromFavorites({ sessionId: id });
    else addToFavorites({ sessionId: id });
  }

  function updateQuery(overrides: Partial<TProgrammeQuery>) {
    setQuery({ ...query, ...overrides });
  }

  function setSessions() {
    const passedSessions = sessions.filter(({ startTime }) => isAfterDate(addMinutes(new Date(), 5), new Date(startTime)));
    const index = sessions?.findIndex(({ id }) => id === passedSessions?.[passedSessions?.length - 1]?.id);
    setActiveSession(sessions?.[index]);
    setNextSession(sessions?.[index + 1]);
  }

  useEffect(() => {
    updateQuery({
      channelId: channels?.some(({ id }) => channelId === id) ? channelId : channels?.[0]?.id,
    });
  }, [channels]);

  useEffect(() => {
    if (sessions?.length) setSessions();
  }, [sessions]);

  useEffect(() => {
    if (nextSession) {
      const interval = setInterval(() => {
        setSessions();
        clearInterval(interval);
      }, differenceInMilliseconds(new Date(nextSession.startTime), addMinutes(new Date(), 5)));
    }
  }, [nextSession]);

  return (
    <div className="live-sessions ecl-container">
      <Title>{t('EVENT.NAVIGATION.LIVE_SESSIONS')}</Title>

      {channelsLoading ? (
        <Spinner />
      ) : (
        <Tabbar
          activeTabId={query?.channelId}
          tabChange={id => updateQuery({ channelId: id })}
          tabs={channels?.map(({ id, title }) => ({ id, label: title }))}
        />
      )}

      {sessionsLoading ? (
        <Spinner />
      ) : (
        <div className="detail-content">
          <main>
            <Player link={activeSession?.streamingUrl} />
            {activeSession && (
              <>
                <section className="live-sessions__active">
                  <h3>{t('EVENTS.LIVE')}</h3>
                  <p>{activeSession.title}</p>
                  <div className="live-sessions__active__details">
                    <div>
                      <div className="live-sessions__active__details__date">
                        <Icon className="icon" name="calendar" />
                        <span>{formatISOString(activeSession.startTime, DEFAULT_MONTH_STRING_FORMAT)}</span>
                      </div>
                      <div className="live-sessions__active__details__time">
                        <Icon className="icon" name="SvgClock" />
                        <span>{`${formatISOString(activeSession.startTime, BASE_TIME_STRING_FORMAT)} - ${formatISOString(
                          activeSession.endTime,
                          BASE_TIME_STRING_FORMAT,
                        )}`}</span>
                        <span>{formatISOString(activeSession.startTime, TIMEZONE_FORMAT)}</span>
                      </div>
                    </div>

                    <Button
                      icon={activeSession.isFavorite ? 'SvgFullHeart' : 'SvgEmptyHeart'}
                      onClick={() => handleFavorites(activeSession.id, activeSession.isFavorite)}
                      theme="secondary"
                    >
                      {t(activeSession.isFavorite ? 'SHARED.BUTTONS.REMOVE_FROM_FAVOURITES' : 'SHARED.BUTTONS.ADD_TO_FAVOURITES')}
                    </Button>
                  </div>
                </section>

                <section>
                  <h3>{t('EVENT.NAVIGATION.SPEAKERS')}</h3>
                  {activeSession?.speakers.map(speaker => (
                    <Speaker key={speaker.id} speaker={speaker} />
                  ))}
                </section>

                <About summary={activeSession?.summary} title={t('EVENT.LIVE_SESSIONS.ABOUT')} topics={activeSession?.topics} />
              </>
            )}

            <Documentation documents={activeSession?.documentation} />
            <ExternalResources links={activeSession?.links} />
          </main>
          <aside>
            <Chatroom chatroomId={activeSession?.chatroomId} title={t('SHARED.LIVE_CHAT')} />
            <Slido slidoUrl={activeSession?.slidoUrl} />
            <NextSession nextSession={nextSession} />
            <RelatedHubs pathPrefix="../" topics={activeSession?.topics} />
            <RelatedProjects pathPrefix="../" similarProjects topics={activeSession?.topics} />
          </aside>
        </div>
      )}
    </div>
  );
};
export default LiveSessions;
