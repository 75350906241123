import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ChannelRoom, Icon } from '../../../_shared';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import { DEFAULT_MONTH_STRING_FORMAT, formatISOString, TIME_STRING_FORMAT } from '../../../_utils/dateHelpers';
import AuthModal from '../../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../../auth/_context';
import { TLiveSession } from '../../../sessions/_models';
import { useAddToFavorites, useDeleteFromFavorites } from '../../../sessions/_queries';

import './nextSession.scss';

type TProps = {
  nextSession: TLiveSession;
};
const NextSession: FC<TProps> = ({ nextSession }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();
  const { mutate: addToFavorites } = useAddToFavorites();
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites();

  function handleFavorites(id: string, isFavorite: boolean) {
    if (!isLoggedIn) return ModalOpener.instance.open({ render: () => <AuthModal /> });
    if (isFavorite) deleteFromFavorites({ sessionId: id });
    else addToFavorites({ sessionId: id });
  }

  return (
    <section className="next-session">
      <h4>{t('EVENT.LIVE_SESSIONS.COMING_NEXT')}</h4>

      {nextSession ? (
        <div>
          <h4>{nextSession?.title}</h4>
          <ChannelRoom session={nextSession} />
          <div className="next-session__date">
            <Icon className="icon" name="calendar" />
            <span>{formatISOString(nextSession?.startTime, DEFAULT_MONTH_STRING_FORMAT)}</span>
          </div>
          <div className="next-session__time">
            <Icon className="icon" name="SvgClock" />
            <span>{formatISOString(nextSession?.startTime, TIME_STRING_FORMAT)}</span>
          </div>
          <Button
            icon={nextSession?.isFavorite ? 'SvgFullHeart' : 'SvgEmptyHeart'}
            onClick={() => handleFavorites(nextSession?.id, nextSession?.isFavorite)}
            theme="secondary"
          >
            {t(nextSession?.isFavorite ? 'SHARED.BUTTONS.REMOVE_FROM_FAVOURITES' : 'SHARED.BUTTONS.ADD_TO_FAVOURITES')}
          </Button>
        </div>
      ) : (
        <span className="next-session__empty">{t('EVENT.LIVE_SESSIONS.COMING_NEXT_EMPTY')}</span>
      )}
    </section>
  );
};

export default NextSession;
