import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Languages } from './languages';
import en from './locales/en.json';

use(initReactI18next).init({
  fallbackLng: Languages.English,
  lng: Languages.English,
  resources: { en: { translation: en } },
});

export default i18n;
