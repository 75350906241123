import { FC } from 'react';

import { TProject } from '../../_models';
import './item.scss';

type TProps = {
  project: TProject;
};

const Item: FC<TProps> = ({ project }) => (
  <div className="project-item-link">
    <img alt={project.title} src={project.thumbnail} />
    <h3>{project?.title}</h3>
  </div>
);

export default Item;
