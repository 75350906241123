import { useState, useEffect, MutableRefObject } from 'react';

export const useContainerDimensions = (ref: MutableRefObject<HTMLElement>): { height: number; width: number } => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const getDimensions = () => ({
    height: ref.current.offsetHeight,
    width: ref.current.offsetWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (ref.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return dimensions;
};
