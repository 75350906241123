import { useQuery } from 'react-query';

import { HttpClient } from '../_http';

type TPost = {
  commentCount: number;
  content: string;
  id: string;
  isFeatured: boolean;
  publishDate: string;
  thumbnail: string;
  title: string;
  topics: string[];
  votes: number;
};

function getPostsByAuthor(id: string): Promise<TPost[]> {
  return HttpClient.get<TPost[]>('community/posts-by-author', { userId: id });
}

export function useGetPostsByAuthor(id: string) {
  return useQuery('getPostsByAuthor', () => getPostsByAuthor(id), { enabled: !!id });
}
