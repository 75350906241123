import { THttpMetadataPagingResponse } from '../../_http';

export type TPostsQuery = {
  isFavorite?: boolean;
  page?: number;
  search?: string;
  sortBy?: 'topic.votes' | 'topic.title' | 'timestamp';
  tags?: string[];
  timestamp?: string;
  timestampFilter?: 'newer' | 'older';
};

export type TPostResponseMetadata = Omit<THttpMetadataPagingResponse, 'skip'> & {
  page: number;
  totalPages: number;
};

export function getSortByLabel(sortBy: 'topic.votes' | 'topic.title' | 'timestamp'): string {
  switch (sortBy) {
    case 'timestamp':
      return 'Chronological order';
    case 'topic.title':
      return 'Alphabetical order';
    case 'topic.votes':
      return 'Popularity';
  }
}
