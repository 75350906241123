import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Title } from '../../_shared';
import Chatroom from '../../chat/Chatroom';
import { IntroCommunity } from '../_components';
import './about.scss';

const About: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const keyPoints = [
    {
      description: t('COMMUNITY.ABOUT.EXPLORE_INFO'),
      onClick: () => navigate('/community/posts'),
      title: t('COMMUNITY.ABOUT.EXPLORE'),
    },
    {
      description: t('COMMUNITY.ABOUT.MEET_THE_COMMUNITY_INFO'),
      onClick: () => navigate('/community/network'),
      title: t('COMMUNITY.ABOUT.MEET_THE_COMMUNITY'),
    },
    {
      description: t('COMMUNITY.ABOUT.START_CHATTING_INFO'),
      onClick: () => navigate('/community/conversations'),
      title: t('COMMUNITY.ABOUT.START_CHATTING'),
    },
  ];

  return (
    <>
      <IntroCommunity />

      <div className="ecl-container">
        <div className="about-the-community__key-points">
          {keyPoints.map(point => (
            <button className="about-the-community__key-points__item" key={point.title} onClick={point.onClick}>
              <h4>{point.title}</h4>
              <p>{point.description}</p>
            </button>
          ))}
        </div>
      </div>

      <div className="ask-the-community">
        <div className="ecl-container">
          <Title>{t('COMMUNITY.ABOUT.ASK_THE_COMMUNITY')}</Title>
          <Chatroom chatroomId={process.env.REACT_APP_COMMUNITY_CHAT_ID} />
        </div>
      </div>
    </>
  );
};

export default About;
