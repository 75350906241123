import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../_http';
import { TTopic, TTopicsResponse } from '../_models';

function getTopics(): Promise<TTopicsResponse> {
  return HttpClient.get<TTopicsResponse>('topics');
}

export function useGetTopics() {
  return useQuery<TTopicsResponse, TApiError, TTopic[]>('topics', () => getTopics(), { select: data => data.topics });
}
