import { ElementType, FC, ReactNode } from 'react';

import './title.scss';

type TProps = {
  children: string | ReactNode;
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const Title: FC<TProps> = ({ children, heading = 'h2' }) => {
  const Component: ElementType = heading;
  return <Component className="title">{children}</Component>;
};

export default Title;
