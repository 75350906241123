import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { THub } from '../_models';

function getHubs(id: string): Promise<THub[]> {
  return HttpClient.get<THub[]>('hubs', {}, { 'X-Event-Id': id });
}

export function useGetHubs() {
  const { event } = useEventContext();
  return useQuery<THub[], TApiError>(['hubs', event.id], () => getHubs(event.id));
}
