import { FC } from 'react';

import classNames from 'classnames';

import './tabbar.scss';

type TProps = {
  activeTabId: string;
  tabChange: (id: string) => void;
  tabs: { id: string; label: string }[];
};

const Tabbar: FC<TProps> = ({ tabChange, tabs = [], activeTabId }) => {
  return (
    <div className="tabbar" role="tablist">
      {tabs.map(({ id, label }) => (
        <button
          aria-controls={label}
          aria-selected={id === activeTabId}
          className={classNames({ 'active-tab': id === activeTabId })}
          key={id}
          onClick={() => tabChange(id)}
          role="tab"
        >
          {label}
        </button>
      ))}
    </div>
  );
};
export default Tabbar;
