import { FC } from 'react';

import './picture.scss';

type TProps = {
  alt?: string;
  label: string;
  url: string;
};

const Picture: FC<TProps> = ({ label, url, alt = '' }) => (
  <figure className="picture">
    <img alt={alt} className="image" crossOrigin="" src={url} />
    <figcaption>{label}</figcaption>
  </figure>
);
export default Picture;
