import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TChannel } from '../_models';

function getChannels(id: string): Promise<TChannel[]> {
  return HttpClient.get<TChannel[]>('channels', {}, { 'X-Event-Id': id });
}

export function useGetChannels() {
  const { event } = useEventContext();
  return useQuery<TChannel[], TApiError>(['channels', event.id], () => getChannels(event.id));
}
