import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '../../../_shared';
import { DEFAULT_MONTH_STRING_FORMAT, formatISOString } from '../../../_utils/dateHelpers';
import { TAuthor } from '../../_models';
import './created.scss';

type TProps = {
  author: TAuthor;
  date: string;
};

const Created: FC<TProps> = ({ author, date }) => {
  const { t } = useTranslation();

  return (
    <ul className="ecl-card__info-container created">
      <li className="ecl-card__info-item">
        <span className="ecl-card__info-label">
          {t('COMMUNITY.POSTS.PUBLISHED', { date: formatISOString(date, DEFAULT_MONTH_STRING_FORMAT) })}
        </span>
        <span className="ecl-card__info-label created__user">
          {author?.image ? (
            <img
              alt={t('COMMUNITY.POSTS.PROFILE_IMAGE_ALT', { user: `${author.firstName} ${author.lastName}` })}
              className="created__user__image"
              src={author.image}
            />
          ) : (
            <Icon className="created__user__image" name="SvgUser" />
          )}
          {`${author.firstName} ${author.lastName}`}
        </span>
      </li>
    </ul>
  );
};
export default Created;
