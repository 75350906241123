import { useMutation } from 'react-query';

import { HttpClient } from '../../_http';
import { TUser } from '../../_models';
import { useEventContext } from '../_context/EventContext';

function inviteUsersToEvent(eventId: string, users: TUser[]): Promise<void> {
  return HttpClient.post(`events/${eventId}/invite`, { users: users.map(user => user.id) });
}

export function useInviteUsersToEvent() {
  const { event } = useEventContext();

  return useMutation('inviteUsersToEvent', (users: TUser[]) => inviteUsersToEvent(event.id, users));
}
