import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TFaq } from '../_models/FAQ';

function getFAQ(): Promise<TFaq[]> {
  return HttpClient.get<TFaq[]>('faq');
}

export function useGetFAQ() {
  return useQuery<TFaq[], TApiError>('faq', () => getFAQ());
}
