import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { TUser } from '../../../_models';
import { Button, Checkbox, InputField, Modal, SearchField, Speaker, Spinner } from '../../../_shared';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import { TUsersQuery } from '../../../network/_models';
import { useGetUsers } from '../../../network/_queries';
import { useInviteUsersToEvent } from '../../_queries';
import './inviteModal.scss';

const InviteModal: FC = () => {
  const [query, setQuery] = useState<TUsersQuery>({ skip: 0, take: 5 });
  const [selectedUsers, setSelectedUsers] = useState<TUser[]>([]);
  const [externalEmails, setExternalEmails] = useState<string[]>([]);

  const { t } = useTranslation();
  const { data: users, isLoading } = useGetUsers(query);
  const { mutate: inviteUsersToEvent } = useInviteUsersToEvent();

  function sendInvites() {
    if (externalEmails.length) location.href = `mailto:${externalEmails.map(email => email)}`;
    if (selectedUsers.length) inviteUsersToEvent(selectedUsers);
    ModalOpener.instance.close();
  }

  return (
    <Modal className="invite-modal" title={t('EVENT.ENGAGE.INVITE_MODAL.TITLE')}>
      <div className="invite-modal__wrapper">
        <div>
          <SearchField
            onSearch={search => setQuery(prevState => ({ ...prevState, search }))}
            placeholder={t('EVENT.ENGAGE.INVITE_MODAL.SEARCH')}
          />
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="invite-modal__wrapper__users">
              {users?.data.map(user => (
                <div key={user.id}>
                  <Speaker speaker={user} />
                  <Checkbox
                    checked={selectedUsers.some(({ id }) => id === user.id)}
                    name="SelectUser"
                    onChange={checked =>
                      setSelectedUsers(prevState =>
                        checked ? [...prevState, user] : prevState.filter(({ id }) => user.id !== id),
                      )
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="invite-modal__wrapper__selected">
          <p>{t('EVENT.ENGAGE.INVITE_MODAL.SELECTED_USERS', { count: selectedUsers.length })}</p>
          {selectedUsers.map(user => (
            <Speaker key={user.id} speaker={user} />
          ))}
        </div>
      </div>

      <div className="invite-modal__external">
        <p>{t('EVENT.ENGAGE.INVITE_MODAL.INVITE_EXTERNAL')}</p>
        <InputField
          name="emails"
          onChange={(value: string) => setExternalEmails(value.length ? value.split(',') : [])}
          placeholder={t('EVENT.ENGAGE.INVITE_MODAL.EXTERNAL_EMAILS')}
        />
      </div>

      <div className="invite-modal__actions">
        <Button onClick={() => ModalOpener.instance.close()} theme="secondary">
          {t('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button disabled={!selectedUsers.length && !externalEmails.length} onClick={sendInvites}>
          {t('EVENT.ENGAGE.INVITE_MODAL.SEND_INVITES')}
        </Button>
      </div>
    </Modal>
  );
};
export default InviteModal;
