import { FC } from 'react';

type TProps = {
  onChange: (value: string) => void;
  value: string;
};

const Datepicker: FC<TProps> = ({ onChange, value }) => {
  return (
    <input
      className="ecl-text-input"
      onChange={event => onChange(event.target.value)}
      placeholder="DD-MM-YYYY"
      style={{ width: '110%' }}
      type="date"
      value={value ?? ''}
    />
  );
};
export default Datepicker;
