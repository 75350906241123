import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TUser } from '../../_models';
import { useEventContext } from '../../events/_context/EventContext';
import { TParticipantsQuery } from '../_models';

function getParticipants(query: TParticipantsQuery, id: string): Promise<TUser[]> {
  return HttpClient.get<TUser[]>(`participants?${stringify(query)}`, {}, { 'X-Event-Id': id });
}

export function useGetParticipants(query: TParticipantsQuery) {
  const { event } = useEventContext();
  return useQuery<TUser[], TApiError>(['participants', query, event.id], () => getParticipants(query, event.id));
}
