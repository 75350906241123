import { FC } from 'react';

import { Link } from 'react-router-dom';

import { FavoriteButton } from '../../../_shared';
import { ModalOpener } from '../../../_shared/modal/ModalOpener';
import AuthModal from '../../../auth/_components/authModal/AuthModal';
import { useAuthContext } from '../../../auth/_context';
import { TProject } from '../../_models';
import { useAddToFavorites, useDeleteFromFavorites } from '../../_queries';

import './project.scss';

type TProps = {
  pathPrefix?: string;
  project: TProject;
};

const Project: FC<TProps> = ({ project, pathPrefix = '' }) => {
  const { isLoggedIn } = useAuthContext();
  const { mutate: addToFavorites } = useAddToFavorites();
  const { mutate: deleteFromFavorites } = useDeleteFromFavorites();

  function handleFavorites() {
    if (!isLoggedIn) ModalOpener.instance.open({ render: () => <AuthModal /> });
    else if (project.isFavorite) deleteFromFavorites({ projectId: project.id });
    else addToFavorites({ projectId: project.id });
  }

  return (
    <article className="project-item">
      <Link to={`${pathPrefix}${project?.id}`}>
        <img alt="" className="project-item__image" src={project?.thumbnail} />
        <h5 className="project-item__title">{project?.title}</h5>
        <p className="project-item__description">{project?.subtitle}</p>
      </Link>
      <FavoriteButton isFavorite={project.isFavorite} onClick={handleFavorites} />
    </article>
  );
};

export default Project;
