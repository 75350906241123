import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { HttpClient, TApiError } from '../../_http';
import { useAuthContext } from '../_context';

function logout(): Promise<void> {
  return HttpClient.post('auth/logout');
}

export function useLogout() {
  const navigate = useNavigate();
  const { clearProfile } = useAuthContext();

  return useMutation<void, TApiError>('logout', logout, {
    onError: () => navigate('/home'),
    onSuccess: () => {
      clearProfile();
      navigate('/home');
    },
  });
}
