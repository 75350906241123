import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { sortEventsMostRecently, sortUpcoming } from '../../../_utils/dateHelpers';
import { useEventsContext } from '../../_context/EventsContext';
import List from '../list/List';

const ListedEvents: FC = () => {
  const { t } = useTranslation();
  const { upcomingEvents, pastEvents, currentEvents, isLoading } = useEventsContext();

  return (
    <>
      {!!currentEvents?.length && <List events={currentEvents} isLoading={isLoading} title={t('EVENTS.CURRENT_EVENTS')} />}
      <List events={sortUpcoming(upcomingEvents)} isLoading={isLoading} title={t('EVENTS.UPCOMING_EVENTS')} />
      <List events={sortEventsMostRecently(pastEvents)} isLoading={isLoading} title={t('EVENTS.PAST_EVENTS')} />
    </>
  );
};
export default ListedEvents;
