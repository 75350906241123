import { useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../_http';

type TCreatePost = {
  content: string;
  file: File;
  tags: string[];
  title: string;
};

async function createPost(body: TCreatePost): Promise<void> {
  const response = await HttpClient.post<{ id: string }>(`community/posts`, body);
  if (response?.id && body.file) {
    const data = new FormData();
    data.append('file', body.file);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await HttpClient.post(`community/posts/${response.id}/image`, data as any, {
      'content-type': 'multipart/form-data',
    });
  }
}

export function useCreatePost(options: { onSuccess: () => void }) {
  const queryClient = useQueryClient();

  return useMutation('createPost', createPost, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPosts']);
      options.onSuccess();
    },
  });
}
