import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Picture } from '../../../_shared';
import { TOrganiser } from '../../_models';

import './organiserInfo.scss';

type TProps = {
  organiser: TOrganiser;
};

const OrganiserInfo: FC<TProps> = ({ organiser }) => {
  const { t } = useTranslation();

  return (
    <section className="organiser-info">
      <div className="ecl-container">
        <h2>{t('EVENT.OVERVIEW.ABOUT_ORGANISER')}</h2>
        <div>
          <Picture alt={organiser.image.name} label={organiser.name} url={organiser.image.url} />
          <div className="organiser-info__content">
            <p>{organiser?.description}</p>
            <Button href={organiser?.website} icon="external" iconPosition="after" iconSize="xs">
              {t('EVENT.OVERVIEW.GO_TO_ORGANISER')}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrganiserInfo;
