import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Markdown, Picture, Spinner, Title } from '../../../_shared';
import Gallery from '../../../ec/gallery/Gallery';
import { useGetExtraPage } from '../../_queries';
import './extraPage.scss';

const ExtraPage: FC = () => {
  const { t } = useTranslation();
  const { data: extraPage, isLoading } = useGetExtraPage();

  if (isLoading) return <Spinner />;
  return (
    <main className="extra-page">
      <Helmet>
        <title>{extraPage?.pageTitle}</title>
      </Helmet>
      <section className="ecl-container">
        <Title>{extraPage?.pageTitle}</Title>

        <div className="extra-page__about">
          <div>
            <h3>{extraPage?.aboutTitle}</h3>
            <Markdown value={extraPage?.about?.json} />
          </div>
          <Picture alt={extraPage?.image?.name} label={extraPage?.image?.name} url={extraPage?.image?.url} />
        </div>
      </section>
      <div className="extra-page__gallery">
        <div className="ecl-container">
          <h3>{t('GALLERY.TITLE')}</h3>
          <Gallery gallery={extraPage?.gallery} />
        </div>
      </div>
      <section className="ecl-container extra-page__section">
        <h3>{extraPage?.sectionTitle}</h3>
        <Markdown value={extraPage?.sectionText?.json} />
      </section>
    </main>
  );
};

export default ExtraPage;
