import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../../../_hooks';
import { ShowMore, Spinner } from '../../../_shared';
import { useEventsContext } from '../../_context/EventsContext';
import { TEvent } from '../../_models';
import Item from '../item/Item';

import './myEvents.scss';

const LIMIT = 4;

type TCategory = {
  allVisible?: boolean;
  events: TEvent[];
  title: string;
  toggleAllVisible?: () => void;
};

const MyEvents: FC = () => {
  const { t } = useTranslation();
  const { myEvents, isLoading } = useEventsContext();

  const categories: TCategory[] = [
    { events: myEvents.filter(event => event.attending), title: t('EVENTS.ATTENDING') },
    { events: myEvents.filter(event => event.interested), title: t('EVENTS.INTERESTED') },
  ].map(category => {
    const [allVisible, toggleAllVisible] = useToggle(false);
    return {
      ...category,
      allVisible,
      toggleAllVisible,
    };
  });

  return (
    <div className="my-events">
      {categories.map(category => (
        <section className="ecl-container" key={category.title}>
          <div className="list__title">
            <h2>{category.title}</h2>
            <span>{t('SHARED.RESULTS', { count: category.events.length })}</span>
          </div>
          {isLoading ? (
            <Spinner />
          ) : !category.events || !category.events?.length ? (
            <span className="my-events__empty">{t('EVENTS.EMPTY_EVENTS')}</span>
          ) : (
            <div className="my-events__wrapper">
              {category.events.slice(0, category.allVisible ? category.events.length : LIMIT).map(event => (
                <Item event={event} key={event.id} />
              ))}
            </div>
          )}
          {category.events.length >= LIMIT && (
            <ShowMore onClick={category.toggleAllVisible} open={category.allVisible}>
              {category.allVisible ? t('SHARED.BUTTONS.SHOW_LESS') : t('EVENTS.SHOW_ALL_MY_EVENTS')}
            </ShowMore>
          )}
        </section>
      ))}
    </div>
  );
};

export default MyEvents;
