import { useQuery } from 'react-query';

import { HttpClient, TApiError, THttpMetadataPagingResponse } from '../../_http';
import { useAuthContext } from '../../auth/_context';
import { TNotification } from '../_models';

type THttpPagedResponseCount = THttpMetadataPagingResponse & {
  totalUnseenCount: number;
};

type THttpPagedResponse<T> = {
  data: T;
  meta: THttpPagedResponseCount;
};

function getNotifications(take: number): Promise<THttpPagedResponse<TNotification[]>> {
  return HttpClient.get<THttpPagedResponse<TNotification[]>>(`notifications?take=${take}`);
}

export function useGetNotifications(take: number) {
  const { isLoggedIn } = useAuthContext();

  return useQuery<THttpPagedResponse<TNotification[]>, TApiError>(['notifications', take], () => getNotifications(take), {
    enabled: isLoggedIn,
    refetchInterval: 30000,
  });
}
