import { Document } from '@contentful/rich-text-types';
import { t } from 'i18next';

import { TImage } from '../../_models';

export type Tab =
  | 'EXTRA'
  | 'OVERVIEW'
  | 'LIVESESSION'
  | 'PROGRAMME'
  | 'SPEAKERS'
  | 'PARTICIPANTS'
  | 'PROJECTS'
  | 'HUBS'
  | 'WORKSHOPS'
  | 'MESSAGES'
  | 'EXHIBITION';

export type TEvent = {
  address: string;
  attendInfo: { json: Document };
  attendeesCount: number;
  attending: boolean;
  city: string;
  description: { json: Document };
  endDate: string;
  exhibitionUrl: string;
  extraPage: TExtraPage;
  format: EventFormat;
  headerImage: TImage;
  hiddenTabs: Tab[];
  id: string;
  image: TImage;
  interested: boolean;
  isExternal: boolean;
  isFeatured: boolean;
  location: TLocation;
  organiser: TOrganiser;
  registrationUrl: string;
  shortDescription: string;
  startDate: string;
  title: string;
  topics: string[];
};

export type TEventsQuery = {
  search: string;
  topics: string[];
};

export type TEventsResponse = {
  currentEvents: TEvent[];
  myEvents: TEvent[];
  pastEvents: TEvent[];
  upcomingEvents: TEvent[];
};

export type TOrganiser = {
  description: string;
  image: TImage;
  name: string;
  website: string;
};

export type TLocation = {
  lat: number;
  lon: number;
};

export type TExtraPage = {
  id: string;
  title: string;
};

export enum EventFormat {
  Hybrid = 'Hybrid',
  InPerson = 'In person',
  Online = 'Online',
}

export function getFormatLabel(format: EventFormat): string {
  switch (format) {
    case EventFormat.InPerson:
      return t('EVENT.OVERVIEW.FORMAT_INPERSON');
    case EventFormat.Hybrid:
      return t('EVENT.OVERVIEW.FORMAT_HYBRID');
    case EventFormat.Online:
      return t('EVENT.OVERVIEW.FORMAT_ONLINE');
  }
}

export function getFormatDesc(format: EventFormat): string {
  switch (format) {
    case EventFormat.InPerson:
      return t('EVENT.OVERVIEW.FORMAT_INPERSON_DESC');
    case EventFormat.Hybrid:
      return t('EVENT.OVERVIEW.FORMAT_HYBRID_DESC');
    case EventFormat.Online:
      return t('EVENT.OVERVIEW.FORMAT_ONLINE_DESC');
  }
}
