import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { HttpClient, HttpStatus, TApiError } from '../_http';

type TLoginParams = {
  code: string;
  verifier: string;
};

function login(params: TLoginParams): Promise<void> {
  return HttpClient.post<void>('auth/login', params);
}

export function useLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TLoginParams>('login', login, {
    onError: error => {
      toast.error(error.statusCode === HttpStatus.Unauthorized ? t('AUTH.NOT_AUTHORIZED') : t('AUTH.NOT_AUTHENTICATED'));
      navigate('/');
    },
    onSuccess: () => {
      navigate(searchParams.get('state').split(',')[1]);
      queryClient.invalidateQueries('authenticate');
    },
  });
}
