import { FC } from 'react';

import { Link } from 'react-router-dom';

import EUFlag from '../../../_assets/images/eu-flag.jpg';
import { FavoriteButton } from '../../../_shared';
import { TPost } from '../../_models';
import { useAddToFavorite, useRemoveToFavorite } from '../../_queries';
import Created from '../created/Created';
import Numbers from '../numbers/Numbers';
import Topics from '../topics/Topics';
import './card.scss';

type TProps = {
  post: TPost;
};

const Card: FC<TProps> = ({ post }) => {
  const { mutate: addToFavorite } = useAddToFavorite();
  const { mutate: removeToFavorite } = useRemoveToFavorite();

  function handleFavorites() {
    if (post.isFavorite) removeToFavorite(post.id);
    else addToFavorite(post.id);
  }

  return (
    <article className="post">
      <Link to={`${post.id}`}>
        <img alt={post.title} className="post__image" crossOrigin="" src={post?.thumbnail || EUFlag} />
      </Link>
      <div className="ecl-card__body">
        <Link to={`${post.id}`}>
          <h1 className="ecl-card__title ecl-link ecl-link--standalone">{post.title}</h1>
        </Link>
        <Created author={post.user} date={post.publishDate} />
        <p className="post__content" dangerouslySetInnerHTML={{ __html: post.content }}></p>
        <Topics topics={post.topics} />
        <Numbers commentCount={post.commentCount} votes={post.votes} />
      </div>
      <FavoriteButton isFavorite={post.isFavorite} onClick={handleFavorites} />
    </article>
  );
};

export default Card;
