import { FC } from 'react';

import classnames from 'classnames';

import './spinner.scss';

type TProps = {
  overlay?: boolean;
  size?: 'small ' | 'medium' | 'large';
  theme?: 'primary' | 'negative';
};

const Spinner: FC<TProps> = ({ overlay = false, size = 'medium', theme = 'primary' }) => {
  const spinner = (
    <div className={`ecl-spinner ecl-spinner--${theme} ecl-spinner--${size} ecl-spinner--visible`}>
      <svg className="ecl-spinner__loader" viewBox="25 25 50 50">
        <circle
          className="ecl-spinner__circle"
          cx="50"
          cy="50"
          fill="none"
          r="20"
          strokeMiterlimit="10"
          strokeWidth="4px"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  );

  return <div className={classnames({ 'spinner-grid': !overlay, 'spinner-overlay': overlay })}>{spinner}</div>;
};

export default Spinner;
