import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../../auth/_context';

import './slido.scss';

type TProps = {
  slidoUrl: string;
};

export const Slido: FC<TProps> = ({ slidoUrl }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();

  if (!slidoUrl || !isLoggedIn) return null;
  return (
    <div className="slido">
      <h4>{t('EVENT.LIVE_SESSIONS.SLIDO.TITLE')}</h4>

      <div className="slido__box">
        <iframe frameBorder="0" height="100%" src={slidoUrl} title="Slido" width="100%" />
      </div>
    </div>
  );
};
