import { FC } from 'react';

import { Link } from 'react-router-dom';

import EUFlag from '../../../_assets/images/eu-flag.jpg';
import { FavoriteButton } from '../../../_shared';
import CardLabel, { CardLabelType } from '../../../ec/card/components/CardLabel';
import { TPost } from '../../_models';
import { useAddToFavorite, useRemoveToFavorite } from '../../_queries';
import Created from '../created/Created';
import Numbers from '../numbers/Numbers';
import Topics from '../topics/Topics';
import './featured.scss';

type TProps = {
  post: TPost;
};

const Featured: FC<TProps> = ({ post }) => {
  const { mutate: addToFavorite } = useAddToFavorite();
  const { mutate: removeToFavorite } = useRemoveToFavorite();

  function handleFavorites() {
    if (post.isFavorite) removeToFavorite(post.id);
    else addToFavorite(post.id);
  }

  return (
    <article className="ecl-card featured_post">
      <img alt="" className="ecl-card__image" crossOrigin="" src={post?.thumbnail || EUFlag} />
      <div className="ecl-card__body">
        <div className="ecl-card__body__content">
          {post.isFeatured && <CardLabel type={CardLabelType.Featured} />}
          <h1 className="ecl-card__title ecl-link ecl-link--standalone">
            <Link to={`/community/posts/${post.id}`}>{post.title}</Link>
          </h1>
          <Created author={post.user} date={post.publishDate} />
          <p className="featured_post__content" dangerouslySetInnerHTML={{ __html: post.content }}></p>
        </div>
        <div className="ecl-card__body__extra-info">
          <Topics topics={post.topics} />
          <Numbers commentCount={post.commentCount} votes={post.votes} />
        </div>
      </div>
      <FavoriteButton isFavorite={post.isFavorite} onClick={handleFavorites} />
    </article>
  );
};
export default Featured;
