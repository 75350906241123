import { format, isValid, isAfter, isBefore, differenceInDays } from 'date-fns';
import { enGB } from 'date-fns/locale';

import { TEvent } from './../events/_models/event';
import { TComment } from './../posts/_models/comment';

const DEFAULT_DATE_STRING_FORMAT = 'dd/MM/yyyy HH:mm';
export const DATE_MONTH_FORMAT = 'dd LLLL yyyy';
export const BASE_TIME_STRING_FORMAT = 'HH:mm';
export const TIME_STRING_FORMAT = 'HH:mm (OOOO)';
export const DEFAULT_MONTH_STRING_FORMAT = 'dd MMMM yyyy';
export const DEFAULT_YEAR_STRING_FORMAT = 'yyyy-MM-dd';
export const TIMEZONE_FORMAT = '(OOOO)';

export function formatDate(date: Date, formatString = DEFAULT_DATE_STRING_FORMAT): string {
  if (!isValid(date)) return null;
  return format(date, formatString, { locale: enGB });
}

export function formatISOString(isoString?: string, formatString = DEFAULT_DATE_STRING_FORMAT): string {
  if (!isoString) return null;
  return formatDate(new Date(isoString), formatString);
}

export function isAfterDate(date: Date, minDate: Date): boolean {
  return isAfter(date, minDate);
}

export function isBeforeDate(date: Date, maxDate: Date): boolean {
  return isBefore(date, maxDate);
}

export function getDaysDifference(minDate: string, maxDate: string): number {
  return differenceInDays(new Date(maxDate), new Date(minDate));
}

export function sortUpcoming(events: TEvent[]): TEvent[] {
  return events.sort((a, b) => (isBefore(new Date(a.startDate), new Date(b.startDate)) ? 0 : 1));
}

export function sortEventsMostRecently(events: TEvent[]): TEvent[] {
  return events.sort((a, b) => (isBefore(new Date(a.startDate), new Date(b.startDate)) ? 0 : -1));
}

export function sortCommentsMostRecently(comments: TComment[]): TComment[] {
  return comments.sort((a, b) => (isBefore(new Date(a.postDate), new Date(b.postDate)) ? 0 : -1));
}
