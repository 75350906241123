import { ChangeEvent, FC } from 'react';

import classnames from 'classnames';

import { useInputError } from '../../../_hooks';
import InputWrapper, { TInputWrapperProps } from '../InputWrapper';

import './textArea.scss';

type Props = TInputWrapperProps & {
  characterLimit?: number;
  normalize?: (value: string) => string;
  onChange: (value: string, name: string) => void;
  placeholder?: string;
  rows?: number;
  value?: string;
};

const TextArea: FC<Props> = ({
  characterLimit,
  normalize = (value: string) => value,
  onChange,
  placeholder,
  rows = 5,
  value,
  ...wrapperProps
}) => {
  const { disabled, validation, name } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);
  const hasReachedCharacterLimit = (val?: string) => characterLimit && (val || value || '').length >= characterLimit;

  return (
    <InputWrapper {...wrapperProps} className={classnames('textarea-wrapper', wrapperProps.className)} showError={showError}>
      <textarea
        className={classnames('textarea', { error: showError })}
        disabled={disabled}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          let normalizedValue = normalize(event.target.value);
          if (hasReachedCharacterLimit(normalizedValue)) normalizedValue = normalizedValue.slice(0, characterLimit);
          onChange(normalizedValue, name);
          setDirty();
        }}
        placeholder={placeholder}
        rows={rows}
        value={value}
      />
      {characterLimit && (
        <div className={classnames('character-limit', { reached: hasReachedCharacterLimit(value) })}>
          <span>{`${characterLimit - value.length}/${characterLimit}`}</span>
        </div>
      )}
    </InputWrapper>
  );
};

export default TextArea;
