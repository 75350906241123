import { FC } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getLabelType } from '../../_utils/eventHelpers';
import { useAuthContext } from '../../auth/_context';
import { Config } from '../../config';
import { CardLabelType } from '../../ec/card/components/CardLabel';
import Button from '../button/Button';
import './attendButton.scss';

type TProps = {
  endDate: string;
  isUserAttending: boolean;
  registrationUrl?: string;
  startDate: string;
};

const AttendButton: FC<TProps> = ({ startDate, endDate, isUserAttending, registrationUrl }) => {
  const { t } = useTranslation();
  const { isLoggedIn, loginRedirectUrl } = useAuthContext();

  const isPastEvent = getLabelType(startDate, endDate) === CardLabelType.Rewatch;
  const buttonProps = { href: isLoggedIn ? registrationUrl : Config.registerUrl };

  function getButtonText() {
    if (isPastEvent && isLoggedIn) {
      if (isUserAttending) return t('EVENT.OVERVIEW.ATTENDED');
      else return t('EVENT.OVERVIEW.NOT_ATTENDED');
    } else {
      if (!isLoggedIn) return t('SHARED.REGISTER');
      else if (isUserAttending) return t('EVENT.OVERVIEW.ATTENDING');
      else return t('EVENT.OVERVIEW.JOIN_NOW');
    }
  }

  return (
    <div className="attend-button">
      {!isLoggedIn && (
        <Button href={loginRedirectUrl} theme="primary">
          {t('SHARED.LOG_IN')}
        </Button>
      )}

      <Button
        className={classnames({
          'is-attending': isUserAttending,
          'is-not-attending': !isUserAttending,
        })}
        disabled={isPastEvent}
        icon={isUserAttending ? 'SvgCheck' : 'SvgChevron'}
        iconSize={1.2}
        theme="cta"
        {...buttonProps}
      >
        {getButtonText()}
      </Button>
    </div>
  );
};

export default AttendButton;
