import { TFile } from '.';

export type TGalleryItem = {
  contentType: ContentType;
  description: string;
  id: string;
  media?: TFile;
  thumbnail?: TFile;
  title: string;
  url: string;
};

export enum ContentType {
  Image = 'image',
  Link = 'link',
  Video = 'video',
  Youtube = 'youtube',
}
