import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../../../_hooks';
import { ShowMore, Spinner } from '../../../_shared';
import { getLabelType } from '../../../_utils/eventHelpers';
import Card from '../../../ec/card/Card';
import { TEvent } from '../../_models';

import './list.scss';

type TProps = {
  events: TEvent[];
  isLoading?: boolean;
  title: string;
};

const List: FC<TProps> = ({ events = [], title, isLoading = false }) => {
  const [areAllShown, toggleAreAllShown] = useToggle(false);
  const [slicedEvents, setSlicedEvents] = useState<TEvent[]>(areAllShown ? events : events.slice(0, 6));
  const { t } = useTranslation();

  useEffect(() => {
    setSlicedEvents(areAllShown ? events : events.slice(0, 6));
  }, [areAllShown, events]);

  return (
    <section className="list">
      <div className="list__title">
        <h2>{title}</h2>
        <span>{t('SHARED.RESULTS', { count: events.length })}</span>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="list__events">
            {slicedEvents.map(event => (
              <Card
                date={event?.startDate}
                image={event?.image?.url}
                imageAlt={event?.image?.name}
                key={event?.id}
                labelType={getLabelType(event?.startDate, event?.endDate)}
                link={`${event?.id}/overview`}
                tags={event?.topics}
                title={event?.title}
              />
            ))}
          </div>

          {!!events.length && (
            <div className="list__show-more">
              <span>{t('SHARED.SEEN', { count: slicedEvents.length, total: events.length })}</span>
              {(events.length > slicedEvents.length || areAllShown) && (
                <>
                  <span className="list__show-more__divider">-</span>
                  <ShowMore onClick={toggleAreAllShown} open={areAllShown}>
                    {`${t(areAllShown ? 'SHARED.SHOW_LESS' : 'SHARED.SHOW_MORE')} ${title.toLowerCase()}`}
                  </ShowMore>
                </>
              )}
            </div>
          )}
        </>
      )}
    </section>
  );
};
export default List;
