import { FC } from 'react';

import { Link } from 'react-router-dom';

import hubThumbnail from '../../../_assets/images/hub-thumbnail.png';
import { THub } from '../../_models';

import './item.scss';

type TProps = {
  hub: THub;
  pathPrefix?: string;
};

const Item: FC<TProps> = ({ hub, pathPrefix = '' }) => {
  return (
    <Link className="hub-item" to={`${pathPrefix}${hub.id}`}>
      <img alt={hub.image?.name || ''} src={hub.image?.url || hubThumbnail} />
      <h4>{hub.title}</h4>
    </Link>
  );
};

export default Item;
