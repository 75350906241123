import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { Spinner } from '../../_shared';
import { TNotification } from '../_models';
import { useGetNotifications } from '../_queries';

type TNotificationsContext = {
  countUnSeenNotifications: number;
  notifications: TNotification[];
  showAll: () => void;
};

export const NotificationsContext = createContext<TNotificationsContext>({
  countUnSeenNotifications: 0,
  notifications: [],
  showAll: () => {},
});

export const useNotificationsContext = () => useContext(NotificationsContext);

type TProps = {
  children: ReactNode;
};

export const NotificationsContextProvider: FC<TProps> = ({ children }) => {
  const [take, setTake] = useState<number>(10);
  const { data: notifications, isLoading } = useGetNotifications(take);

  if (isLoading) return <Spinner />;
  return (
    <NotificationsContext.Provider
      value={{
        countUnSeenNotifications: notifications?.meta.totalUnseenCount,
        notifications: notifications?.data,
        showAll: () => setTake(notifications.meta.totalCount),
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
