import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TExternalHighlight } from '../_models';

function getExternalHighlights(): Promise<TExternalHighlight[]> {
  return HttpClient.get<TExternalHighlight[]>('external-highlights');
}

export function useGetExternalHighlights() {
  return useQuery<TExternalHighlight[], TApiError>('externalHighlights', () => getExternalHighlights());
}
