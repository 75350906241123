import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { useEventContext } from '../../events/_context/EventContext';
import { TProject } from '../_models';

type TRelatedProjectsResponse = { data: TProject[] };

function getRelatedProjects(id: string, topics: string[]): Promise<TRelatedProjectsResponse> {
  return HttpClient.get<TRelatedProjectsResponse>(`projects/related?${stringify({ topics })}`, {}, { 'X-Event-Id': id });
}

export function useGetRelatedProjects(topics: string[]) {
  const { event } = useEventContext();
  return useQuery<TRelatedProjectsResponse, TApiError, TProject[]>(
    ['relatedProjects', event.id, topics],
    () => getRelatedProjects(event.id, topics),
    {
      enabled: !!topics && !!topics.length,
      select: ({ data }) => {
        return data;
      },
    },
  );
}
