import { createContext, FC, ReactNode, useContext } from 'react';

import { TTopic } from '../../_models';
import { useGetTopics } from '../../_queries';
import { TEvent } from '../_models';
import { useGetEvent } from '../_queries';

type TEventContext = {
  event: TEvent;
  isLoading: boolean;
  topics: TTopic[];
};

const EventContext = createContext<TEventContext>({
  event: undefined,
  isLoading: false,
  topics: [],
});

export const useEventContext = () => useContext(EventContext);

type TProps = {
  children: ReactNode;
};

export const EventContextProvider: FC<TProps> = ({ children }) => {
  const { isLoading, data: event } = useGetEvent();
  const { data: topics } = useGetTopics();

  return (
    <EventContext.Provider
      value={{
        event,
        isLoading,
        topics: topics ?? [],
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
