import { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useResponsive } from '../_hooks';
import { Button, Spinner, Title } from '../_shared';
import SwiperCarousel from '../ec/carousel/SwiperCarousel';

import { useGetExternalHighlights } from './_queries';
import './externalHighlights.scss';

const ExternalHighlights = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { isLoading, data } = useGetExternalHighlights();
  const { Responsive } = useResponsive();

  function getSlidesPerView(): number {
    if (Responsive.isBiggerThan('phone')) return 2;
    return 1;
  }

  return (
    <section className="external-highlights">
      <div className="external-highlights__content ecl-container" ref={ref}>
        <Title>{t('HOME.EXTERNAL_HIGHLIGHTS')}</Title>

        {isLoading ? (
          <Spinner />
        ) : (
          <div className="external-highlights__content__carousel">
            <SwiperCarousel
              emptyLabel={t('HOME.EXTERNAL_HIGHLIGHTS_EMPTY')}
              slides={(data || []).map(highlight => (
                <article className="external-highlight" key={highlight.id}>
                  <img alt="" className="external-highlight__image" src={highlight.image} />
                  <div className="external-highlight__body">
                    <Button
                      className="external-highlight__title"
                      href={highlight.url}
                      icon="external"
                      iconSize="xs"
                      theme="plain-link"
                    >
                      {highlight.title}
                    </Button>
                    {highlight.summary && <div className="external-highlight__description">{highlight.summary}</div>}
                  </div>
                </article>
              ))}
              slidesPerView={getSlidesPerView()}
              spaceBetween={32}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default ExternalHighlights;
