import { useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../_http';

function unLikeComment(postId: string, commentId: string): Promise<void> {
  return HttpClient.delete(`community/posts/${postId}/comments/${commentId}/vote`);
}

export function useUnLikeComment(postId: string) {
  const queryClient = useQueryClient();

  return useMutation('unLikeComment', (commentId: string) => unLikeComment(postId, commentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPost']);
    },
  });
}
