import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, JoinPlatform, Title } from '../_shared';
import { useAuthContext } from '../auth/_context';

import { useChatInbox } from './_hooks';
import ConversationActions from './actions/ConversationActions';

import './conversations.scss';

const Conversations: FC = () => {
  const { t } = useTranslation();
  const { iframeContainerRef, currentConversation, chatInbox } = useChatInbox();
  const { isLoggedIn } = useAuthContext();

  return (
    <>
      <div className="ecl-container conversations__back">
        <Button onClick={() => history.back()}>{t('SHARED.BUTTONS.BACK')}</Button>
      </div>

      {isLoggedIn ? (
        <div className="ecl-container conversations">
          <Title>{t('COMMUNITY.CONVERSATIONS.TITLE')}</Title>
          <p>{t('COMMUNITY.CONVERSATIONS.INFO')}</p>
          <div className="ecl-container conversations-wrapper">
            <ConversationActions chatInbox={chatInbox} conversation={currentConversation} />
            <div className="chatbox-container" ref={iframeContainerRef} />
          </div>
        </div>
      ) : (
        <JoinPlatform />
      )}
    </>
  );
};
export default Conversations;
