import { useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../_http';

function likePost(id: string): Promise<void> {
  return HttpClient.post(`community/posts/${id}/vote`);
}

export function useLikePost(id: string) {
  const queryClient = useQueryClient();

  return useMutation('likePost', () => likePost(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPosts']);
      queryClient.invalidateQueries(['getPost']);
    },
  });
}
