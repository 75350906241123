import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { eventId: string };

function addToInterests({ eventId }: TParams): Promise<void> {
  return HttpClient.post<void>(`events/${eventId}/interested`);
}

export function useAddToInterests() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TParams>('addToInterests', addToInterests, {
    onSuccess: () => {
      queryClient.invalidateQueries('event');
      queryClient.invalidateQueries('events');
    },
  });
}
