import { Suspense } from 'react';

import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { Spinner } from './_shared';
import { Config } from './config';
import * as serviceWorker from './serviceWorker';
import './_translations/i18n';
import './index.scss';

// Setup sentry error logging
const environmentsWithErrorLogging = ['production', 'staging'];
const needsErrorLogging = Config.sentryDsn && environmentsWithErrorLogging.includes(Config.environment);

if (needsErrorLogging) {
  Sentry.init({
    dsn: Config.sentryDsn,
    environment: Config.environment,
  });
}

const queryClient = new QueryClient();
queryClient.setDefaultOptions(Config.reactQuery(queryClient));

createRoot(document.getElementById('root')).render(
  <Suspense fallback={<Spinner overlay />}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Suspense>,
);

serviceWorker.unregister();
