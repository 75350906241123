import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../_http';
import { TEvent } from '../events/_models';

type TUserEventsResponse = TEvent[];

function getUserEvents(userId: string): Promise<TUserEventsResponse> {
  return HttpClient.get<TUserEventsResponse>(`users/${userId}/events`);
}

export function useGetUserEvents(userId: string) {
  return useQuery<TUserEventsResponse, TApiError>(`users/${userId}/events`, () => getUserEvents(userId));
}
