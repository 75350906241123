import { isFuture, isPast } from 'date-fns';

import { CardLabelType } from '../ec/card/components/CardLabel';
import { TChannel, TLiveSession, TSession } from '../sessions/_models';

import {
  BASE_TIME_STRING_FORMAT,
  DEFAULT_YEAR_STRING_FORMAT,
  formatDate,
  formatISOString,
  isAfterDate,
  isBeforeDate,
} from './dateHelpers';

export const getLabelType = (startDate: string, endDate: string): CardLabelType => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isFuture(start)) {
    return CardLabelType.ComingSoon;
  } else if (isPast(start) && isFuture(end)) {
    return CardLabelType.Live;
  } else if (isPast(start)) {
    return CardLabelType.Rewatch;
  }
  return null;
};

export const getCurrentProgrammeDay = (startDate: string, endDate: string): string => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isBeforeDate(new Date(), start)) return formatDate(start, DEFAULT_YEAR_STRING_FORMAT);
  if (isAfterDate(new Date(), end)) return formatDate(end, DEFAULT_YEAR_STRING_FORMAT);
  return formatDate(new Date(), DEFAULT_YEAR_STRING_FORMAT);
};

export const getTimeblocks = (sessions: TSession[], channels: TChannel[]): Record<string, TSession[]> => {
  const getChannel = (session: TLiveSession): TChannel => channels.find(channel => channel.id === session.channelId);

  let timeblocks: Record<string, TSession[]> = {};

  sessions.forEach(session => {
    const key = formatISOString(session.startTime, BASE_TIME_STRING_FORMAT);

    if (Object.keys(timeblocks).includes(key)) {
      timeblocks[key].push(session);
    } else {
      timeblocks = { ...timeblocks, [key]: [session] };
    }
  });

  Object.keys(timeblocks).forEach(key => {
    timeblocks[key] = timeblocks[key].sort((sessionA, sessionB) => {
      const channelA = getChannel(sessionA as TLiveSession);
      const channelB = getChannel(sessionB as TLiveSession);
      return channelA.title.toLocaleLowerCase().localeCompare(channelB.title.toLocaleLowerCase());
    });
  });

  return timeblocks;
};
