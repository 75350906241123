import { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import Detail from './detail/Detail';
import Overview from './overview/Overview';

const ProjectsRoutes: FC = () => (
  <Routes>
    <Route element={<Overview />} path="/" />
    <Route element={<Detail />} path=":projectId" />
    <Route element={<Overview />} path="?topic=:topic" />
  </Routes>
);

export default ProjectsRoutes;
