import { stringify } from 'query-string';
import { useQuery } from 'react-query';

import { HttpClient, TApiError, THttpPagedResponse } from '../../_http';
import { TUser } from '../../_models';
import { useAuthContext } from '../../auth/_context';
import { TUsersQuery } from '../_models';

function getUsers(query: TUsersQuery): Promise<THttpPagedResponse<Record<string, TUser[]>>> {
  return HttpClient.get<THttpPagedResponse<Record<string, TUser[]>>>(`users?${stringify(query)}`);
}

export function useGetUsers(query: TUsersQuery) {
  const { isLoggedIn } = useAuthContext();

  return useQuery<THttpPagedResponse<Record<string, TUser[]>>, TApiError, THttpPagedResponse<TUser[]>>(
    ['users', query],
    () => getUsers(query),
    {
      enabled: isLoggedIn,
      select: users => {
        return { data: [].concat(...Object.values(users.data)), meta: users.meta };
      },
    },
  );
}
