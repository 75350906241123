import { useMutation, useQueryClient } from 'react-query';

import { HttpClient, TApiError } from '../../_http';

type TParams = { eventId: string };

function deleteFromInterests({ eventId }: TParams): Promise<void> {
  return HttpClient.delete<void>(`events/${eventId}/uninterested`);
}

export function useDeleteFromInterests() {
  const queryClient = useQueryClient();

  return useMutation<void, TApiError, TParams>('deleteFromInterests', deleteFromInterests, {
    onSuccess: () => {
      queryClient.invalidateQueries('event');
      queryClient.invalidateQueries('events');
    },
  });
}
