import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Icon } from '../../../_shared';
import { DATE_MONTH_FORMAT, formatISOString, TIME_STRING_FORMAT } from '../../../_utils/dateHelpers';
import { TLiveSession, TWorkshopSession } from '../../_models';
import { useGetChannels } from '../../_queries';

import './item.scss';

type TProps = {
  session: TLiveSession | TWorkshopSession;
};

const Item: FC<TProps> = ({ session }) => {
  const { t } = useTranslation();
  const { data: channels } = useGetChannels();
  const channelName = 'channelId' in session ? (channels || []).find(channel => channel.id === session.channelId)?.title : null;

  return (
    <article className="session-item">
      <Link to={`../../programme`}>
        <h5 className="session-item__title">{session?.title}</h5>
      </Link>
      {!!channelName && (
        <div className="session-item__channel">
          <Icon name="global" size={1.1} />
          <span>{channelName}</span>
        </div>
      )}
      <div className="session-item__timing">
        <Icon name="calendar" size="xs" />
        <span>{formatISOString(session.startTime, DATE_MONTH_FORMAT)}</span>
      </div>
      <div className="session-item__timing">
        <Icon name="SvgClock" size="xs" />
        <span>{formatISOString(session.startTime, TIME_STRING_FORMAT)}</span>
      </div>
      <Button icon="SvgEmptyHeart" iconSize={0.8} onClick={() => {}} theme="secondary">
        {t('SHARED.BUTTONS.ADD_TO_FAVOURITES')}
      </Button>
    </article>
  );
};

export default Item;
