import { FC, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useResponsive, useToggle } from '../../../_hooks';
import { TTopic } from '../../../_models';
import { Button, ShowMore, Spinner } from '../../../_shared';
import SwiperCarousel from '../../../ec/carousel/SwiperCarousel';

import './topicCarousel.scss';

type TProps = {
  activeTopic: TTopic;
  href: string;
  isLoading: boolean;
  topics: TTopic[];
};

const TopicCarousel: FC<TProps> = ({ topics, isLoading, href, activeTopic }) => {
  const [isVisible, toggleVisibility] = useToggle(true);
  const { t } = useTranslation();
  const ref = useRef(null);
  const { Responsive } = useResponsive();

  function getSlidesPerView(): number {
    if (Responsive.isBiggerThan('monitor')) return 5;
    if (Responsive.isBiggerThan('tablet')) return 3;
    return 1;
  }

  if (activeTopic)
    return (
      <>
        <div className="ecl-container">
          <Button
            className="back-button"
            href={href}
            icon="corner-arrow"
            iconPosition="before"
            iconSize="xs"
            iconTransformation="rotate-270"
            theme="ghost"
          >
            {t('SHARED.BUTTONS.BACK')}
          </Button>
        </div>
        <section className="topic-banner">
          <div className="ecl-container">
            <h2>{t('PROJECTS.OVERVIEW.TOPIC', { topic: activeTopic?.title })}</h2>
            <p className="topic__description">{activeTopic?.description}</p>
          </div>
        </section>
      </>
    );
  return (
    <section className="ecl-container topic__carousel-wrapper" ref={ref}>
      <h3>{t('PROJECTS.TOPICS.TITLE')}</h3>
      <span>{t('PROJECTS.TOPICS.SUBTITLE')}</span>
      <ShowMore onClick={toggleVisibility} open={isVisible}>
        {isVisible ? t('SHARED.BUTTONS.HIDE') : t('SHARED.BUTTONS.SHOW')}
      </ShowMore>
      {isLoading ? (
        <Spinner />
      ) : (
        isVisible && (
          <div className="topic__carousel">
            <SwiperCarousel
              emptyLabel={t('PROJECTS.TOPICS.EMPTY')}
              navigationTheme="minimal"
              slides={(topics || []).map(topic => (
                <Link className="topic-item" key={topic.title} to={`?topic=${topic.title}`}>
                  <span className="topic-item__title">{topic.title}</span>
                  <p>{topic.description}</p>
                </Link>
              ))}
              slidesPerView={getSlidesPerView()}
              spaceBetween={16}
            />
          </div>
        )
      )}
    </section>
  );
};

export default TopicCarousel;
